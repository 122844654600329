import React, { useState } from 'react';
import NumPad from '../../components/NumPad';
import { withApiService } from '../../components/HOC';
import { useHistory, withRouter } from 'react-router-dom';
import BackLink from '../../components/BackLink';
import { setUser } from '../../actions';
import { compose } from '../../utils';
import { connect } from 'react-redux';
import LogoBig from '../../components/LogoBig';

const ChangePin = ({ apiService, user }) => {
  const [errorMessage, setErrorMessage] = useState('');
  let history = useHistory();

  const handleNumPadSubmit = (pin) => {
    apiService.login({
      username: user.username,
      pincode: pin
    })
      .then(() => {
        history.push('/set-pin');
      })
      .catch(error => {
        setErrorMessage(error.message);
      });
  };

  const handleClearError = () => {
    setErrorMessage('');
  };

  return (
    <div className="login-page bg-solid">
      <BackLink path="/user"/>
      <div className="w-100">
        <LogoBig/>
        <div style={ { minHeight: '20px', marginBottom: '16px' } }>
          {
            errorMessage ?
              <p className="text-danger text-center">
                { errorMessage }
              </p>
              :
              <p className="text-center">
                { 'Tast inn PIN-kode for å bekrefte' }
              </p>
          }
        </div>
        <NumPad onSubmit={ handleNumPadSubmit } onClear={ handleClearError }/>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

const mapDispatchToProps = {
  setUser
};

export default compose(
  withRouter,
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePin);
