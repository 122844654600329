import React from 'react';
import { connect } from 'react-redux';
import { compose } from '../../utils';
import { withApiService } from '../../components/HOC';
import { resetUser, resetStore } from '../../actions';
import { Link, useHistory } from 'react-router-dom';
import './User.scss';
import BackLink from '../../components/BackLink';
import phone from '../../images/icons/phone.svg';
import email from '../../images/icons/email.svg';
import pin from '../../images/icons/pin.svg';
import LogoBig from '../../components/LogoBig';

const User = ({ user, apiService, resetUser, resetStore, selectType }) => {
  let history = useHistory();

  const handleLogout = () => {
    apiService.logout().then(() => {
      // reset user in redux store
      resetUser();
      // reset redux store
      resetStore();
      // reset redirect url in localstorage
      localStorage.removeItem('befaring_redirect');
      // go to login page
      history.push('/login');
    });
  };

  return (
    <div className="bg-solid text-center w-100 position-relative">
      <BackLink/>
      <div style={ { display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' } }>
        <LogoBig/>
        <div>
          { user ?
            <div className="user-info">
              <div style={ { width: 140 } }>
                <img src={ 'https://partners.no/shared' + user.urlstandardbilde } alt=""/>
              </div>
              <div className="user-info-content">
                <div style={ { fontSize: 24 } }>{ user.navn }</div>
                <a href={ 'tel:' + user.mobiltelefon.replace(/\s+/g, '') }
                   style={ { display: 'flex', alignItems: 'flex-end', marginTop: 10 } }>
                  <img src={ phone } alt=""/>
                  <span style={ { marginLeft: 4 } }>{ user.mobiltelefon }</span>
                </a>
                <a href={ 'mailto:' + user.email } style={ { display: 'flex', alignItems: 'flex-end', marginTop: 10 } }>
                  <img src={ email } alt=""/>
                  <span style={ { marginLeft: 4 } }>{ user.email }</span>
                </a>
                { user.brokers && user.brokers.length ? <>
                    <div style={ { fontSize: 16, marginTop: 20 } }>{ user.brokers[0].department.address }</div>

                    <a
                      href={ 'https://www.google.com/maps/search/?api=1&query=' + user.brokers[0].department.address }
                      target="_blank"
                      rel="noreferrer"
                      style={ { display: 'flex', alignItems: 'flex-end', marginTop: 10, textDecoration: 'underline' } }
                    >
                      <img src={ pin } alt=""/>
                      <span style={ { marginLeft: 4 } }>Åpne i Google maps</span>
                    </a>

                    <a href={ 'tel:' + user.brokers[0].department.phone.replace(/\s+/g, '') }
                       style={ { display: 'flex', alignItems: 'flex-end', marginTop: 10 } }>
                      <img src={ phone } alt=""/>
                      <span style={ { marginLeft: 4 } }>{ user.brokers[0].department.phone }</span>
                    </a>
                  </>
                  : null
                }
              </div>
            </div>
            : null
          }
          <div style={ { marginTop: 60, display: 'flex', justifyContent: 'center' } }>
            <button className="button button-default ml-2" onClick={ () => handleLogout() }>
              Logg ut
            </button>
          </div>
        </div>
        <div className="user-footer">
          { user && user.hasPincode ?
            <Link to="/change-pin" className="user-footer-link">Endre PIN-kode</Link>
            : <Link to="/set-pin" className="user-footer-link">Sette opp PIN-kode</Link>
          }
          { /* eslint-disable-next-line */ }
          <a href="#" className="user-footer-link"
             onClick={ () => {
               window.location.reload();
             } }
          >
            Last inn på nytt App
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, selectType }) => {
  return { user, selectType };
};

const mapDispatchToProps = {
  resetUser,
  resetStore
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(User);
