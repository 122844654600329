const propertiesRequested = () => {
  return {
    type: 'FETCH_PROPERTIES_REQUEST'
  };
};

const propertiesLoaded = (newProperties) => {
  return {
    type: 'FETCH_PROPERTIES_SUCCESS',
    payload: newProperties
  };
};

const propertiesError = (error) => {
  return {
    type: 'FETCH_PROPERTIES_FAILURE',
    payload: error
  };
};

const officePropertiesRequested = () => {
  return {
    type: 'FETCH_OFFICE_PROPERTIES_REQUEST'
  };
};

const officePropertiesLoaded = (newProperties) => {
  return {
    type: 'FETCH_OFFICE_PROPERTIES_SUCCESS',
    payload: newProperties
  };
};

const officePropertiesError = (error) => {
  return {
    type: 'FETCH_OFFICE_PROPERTIES_FAILURE',
    payload: error
  };
};

const searchPropertiesRequested = () => {
  return {
    type: 'FETCH_SEARCH_PROPERTIES_REQUEST'
  };
};

const searchPropertiesLoaded = (newProperties) => {
  return {
    type: 'FETCH_SEARCH_PROPERTIES_SUCCESS',
    payload: newProperties
  };
};

const searchPropertiesError = (error) => {
  return {
    type: 'FETCH_SEARCH_PROPERTIES_FAILURE',
    payload: error
  };
};

const setCurrentProperty = (id) => {
  return {
    type: 'SET_CURRENT_PROPERTY',
    payload: id
  };
};

const propertyDetailsRequested = () => {
  return {
    type: 'FETCH_PROPERTY_DETAILS_REQUEST'
  };
};

const propertyDetailsLoaded = (newPropertyDetails) => {
  return {
    type: 'FETCH_PROPERTY_DETAILS_SUCCESS',
    payload: newPropertyDetails
  };
};

const propertyDetailsError = (error) => {
  return {
    type: 'FETCH_PROPERTY_DETAILS_FAILURE',
    payload: error
  };
};

const setPropertyCategories = (newCategories) => {
  return {
    type: 'SET_PROPERTY_CATEGORIES',
    payload: newCategories
  };
};

const setPropertyMarkers = (newMarkers) => {
  return {
    type: 'SET_PROPERTY_MARKERS',
    payload: newMarkers
  };
};

const setDetailsMapCenter = (center) => {
  return {
    type: 'SET_DETAILS_MAP_CENTER',
    payload: center
  };
};

const setDetailsMapZoom = (zoom) => {
  return {
    type: 'SET_DETAILS_MAP_ZOOM',
    payload: zoom
  };
};

const setStatisticsMapCenter = (center) => {
  return {
    type: 'SET_STATISTICS_MAP_CENTER',
    payload: center
  };
};

const setStatisticsMapZoom = (zoom) => {
  return {
    type: 'SET_STATISTICS_MAP_ZOOM',
    payload: zoom
  };
};

const calendarRequested = () => {
  return {
    type: 'FETCH_CALENDAR_REQUEST'
  };
};

const calendarLoaded = (newCalendar) => {
  return {
    type: 'FETCH_CALENDAR_SUCCESS',
    payload: newCalendar
  };
};

const calendarError = (error) => {
  return {
    type: 'FETCH_CALENDAR_FAILURE',
    payload: error
  };
};

const selectedPropertyRequested = () => {
  return {
    type: 'FETCH_SELECTED_PROPERTY_REQUEST'
  };
};

const selectedPropertyLoaded = (newSelectedProperty) => {
  return {
    type: 'FETCH_SELECTED_PROPERTY_SUCCESS',
    payload: newSelectedProperty
  };
};

const selectedPropertyError = (error) => {
  return {
    type: 'FETCH_SELECTED_PROPERTY_FAILURE',
    payload: error
  };
};

const setClientsParams = (newParams) => {
  return {
    type: 'SET_CLIENTS_PARAMS',
    payload: newParams
  };
};

const clientsRequested = () => {
  return {
    type: 'FETCH_CLIENTS_REQUEST'
  };
};

const clientsLoaded = (newClients) => {
  return {
    type: 'FETCH_CLIENTS_SUCCESS',
    payload: newClients
  };
};

const clientsError = (error) => {
  return {
    type: 'FETCH_CLIENTS_FAILURE',
    payload: error
  };
};

const setEmailText = (newText) => {
  return {
    type: 'SET_EMAIL_TEXT',
    payload: newText
  };
};

const setOfficePropertiesQuery = (newQuery) => {
  return {
    type: 'SET_OFFICE_PROPERTIES_QUERY',
    payload: newQuery
  };
};

const setSearchPropertiesQuery = (newQuery) => {
  return {
    type: 'SET_SEARCH_PROPERTIES_QUERY',
    payload: newQuery
  };
};

const setMapPlacesQuery = (newQuery) => {
  return {
    type: 'SET_MAP_PLACES_QUERY',
    payload: newQuery
  };
};

const setUser = (newUser) => {
  return {
    type: 'SET_USER',
    payload: newUser
  };
};

const setUsers = (newUsers) => {
  return {
    type: 'SET_USERS',
    payload: newUsers
  };
};

const resetUser = () => {
  return {
    type: 'RESET_USER'
  };
};

const resetStore = () => {
  return {
    type: 'RESET_STORE'
  };
};

const setCalendarRecipients = (newRecipients) => {
  return {
    type: 'SET_CALENDAR_RECIPIENTS',
    payload: newRecipients
  };
};

const setCalendarDocuments = (newDocuments) => {
  return {
    type: 'SET_CALENDAR_DOCUMENTS',
    payload: newDocuments
  };
};

const setCustomRecipient = (newCustomRecipient) => {
  return {
    type: 'SET_CUSTOM_RECIPIENT',
    payload: newCustomRecipient
  };
};

const setCustomRecipientSelected = (selected) => {
  return {
    type: 'SET_CUSTOM_RECIPIENT_SELECTED',
    payload: selected
  };
};

const resetProperties = () => {
  return {
    type: 'RESET_PROPERTIES'
  };
};

const resetOfficeProperties = () => {
  return {
    type: 'RESET_OFFICE_PROPERTIES'
  };
};

const resetSearchProperties = () => {
  return {
    type: 'RESET_SEARCH_PROPERTIES'
  };
};

const setSelectType = (selectType) => {
  return {
    type: 'SET_SELECT_TYPE',
    payload: selectType
  };
};

const setVisningForm = (formData) => {
  return {
    type: 'SET_VISNING_FORM',
    payload: formData
  };
};

const resetVisningForm = () => {
  return {
    type: 'RESET_VISNING_FORM'
  };
};

const setAssignmentAgreementSelected = (selected) => {
  return {
    type: 'SET_ASSIGNMENT_AGREEMENT_SELECTED',
    payload: selected
  };
};

const fetchProperties = (apiService, dispatch) => (type) => {
  dispatch(propertiesRequested());
  apiService.getProperties(type)
    .then((data) => dispatch(propertiesLoaded(data)))
    .catch((err) => dispatch(propertiesError(err)));
}

const fetchOfficeProperties = (apiService, dispatch) => (type) => {
  dispatch(officePropertiesRequested());
  apiService.getOfficeProperties(type)
    .then((data) => dispatch(officePropertiesLoaded(data)))
    .catch((err) => dispatch(officePropertiesError(err)));
}

const fetchSearchProperties = (apiService, dispatch) => (query, type, page) => {
  dispatch(searchPropertiesRequested());
  apiService.getSearchProperties(query, type, page)
    .then((data) => dispatch(searchPropertiesLoaded(data)))
    .catch((err) => dispatch(searchPropertiesError(err)));
}

const fetchPropertyDetails = (apiService, dispatch) => (id) => {
  dispatch(propertyDetailsRequested());
  apiService.getPropertyDetails(id)
    .then((data) => dispatch(propertyDetailsLoaded(data)))
    .catch((err) => dispatch(propertyDetailsError(err)));
}

const fetchCalendar = (apiService, dispatch) => (id) => {
  dispatch(calendarRequested());
  apiService.getCalendar(id)
    .then((data) => dispatch(calendarLoaded(data)))
    .catch((err) => dispatch(calendarError(err)));
}

const fetchSelectedProperty = (apiService, dispatch) => (id) => {
  dispatch(selectedPropertyRequested());
  apiService.getSelectedProperty(id)
    .then((data) => dispatch(selectedPropertyLoaded(data)))
    .catch((err) => dispatch(selectedPropertyError(err)));
}

const fetchClients = (apiService, dispatch) => (params, page) => {
  dispatch(clientsRequested());
  apiService.getClients(params, page)
    .then((data) => dispatch(clientsLoaded(data)))
    .catch((err) => dispatch(clientsError(err)));
}

export {
  fetchProperties,
  fetchOfficeProperties,
  setOfficePropertiesQuery,
  fetchSearchProperties,
  setSearchPropertiesQuery,
  fetchPropertyDetails,
  setCurrentProperty,
  setPropertyCategories,
  fetchCalendar,
  fetchSelectedProperty,
  setPropertyMarkers,
  setDetailsMapCenter,
  setDetailsMapZoom,
  setStatisticsMapCenter,
  setStatisticsMapZoom,
  setClientsParams,
  fetchClients,
  setEmailText,
  setMapPlacesQuery,
  setUser,
  setUsers,
  resetUser,
  resetStore,
  setCalendarRecipients,
  setCalendarDocuments,
  setCustomRecipient,
  setCustomRecipientSelected,
  resetProperties,
  resetOfficeProperties,
  resetSearchProperties,
  setSelectType,
  setVisningForm,
  resetVisningForm,
  setAssignmentAgreementSelected
};
