import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchCalendar,
  setCalendarRecipients,
  setCalendarDocuments,
  setEmailText,
  setCustomRecipient,
  setCustomRecipientSelected,
  setAssignmentAgreementSelected
} from '../../../actions';
import { compose } from '../../../utils';
import { withApiService } from '../../HOC';

import Modal from '../../../components/Modal';
import Sprite from '../../App/images/sprites/sprite.svg';

import { dayjs } from '../../../utils';
import { templateBuilder } from '../../../utils/templateBuilder';
import ExternalLinkIcon from '../../App/images/icons/external-link-alt-solid.svg';

dayjs.locale('nb');

class EmailModal extends Component {
  toggleProperty = (arr, email, propName) => {
    const idx = arr.findIndex((el) => el.email === email);

    const oldItem = arr[idx];
    const newItem = { ...oldItem, [propName]: !oldItem[propName] };

    return [
      ...arr.slice(0, idx),
      newItem,
      ...arr.slice(idx + 1)
    ];
  };

  toggleDocument = (arr, name, propName) => {
    const idx = arr.findIndex((el) => el.name === name);

    const oldItem = arr[idx];
    const newItem = { ...oldItem, [propName]: !oldItem[propName] };

    return [
      ...arr.slice(0, idx),
      newItem,
      ...arr.slice(idx + 1)
    ];
  };

  handleSelectRecipient = (email) => {
    const { calendar, setCalendarRecipients } = this.props;

    setCalendarRecipients(this.toggleProperty(calendar.recipients, email, 'selected'));
  };

  handleSelectDocument = (name) => {
    const { calendar, setCalendarDocuments } = this.props;

    setCalendarDocuments(this.toggleDocument(calendar.documents, name, 'selected'));
  };

  handleSelectAssignmentAgreement = (selected) => {
    const { setAssignmentAgreementSelected } = this.props;

    setAssignmentAgreementSelected(selected);
  };

  handleChangeText = (event) => {
    this.props.setEmailText(event.target.value);
  };

  handleChangeCustomRecipient = (event) => {
    this.props.setCustomRecipient(event.target.value);
    if (event.target.value && !this.props.customRecipientSelected) {
      this.props.setCustomRecipientSelected(true);
    } else if (!event.target.value && this.props.customRecipientSelected) {
      this.props.setCustomRecipientSelected(false);
    }
  };

  handleClickCustomRecipient = (event) => {
    if (event.target.value && !this.props.customRecipientSelected) {
      this.props.setCustomRecipientSelected(true);
    }
  };

  toggleCustomRecipient = () => {
    this.props.setCustomRecipientSelected(!this.props.customRecipientSelected);
  };

  removeEvent = (date, selectedEvent) => {
    const { apiService, fetchCalendar, calendar } = this.props;

    const calendarItem = calendar.items.find(item => item.date === date);
    const newCalendarItem = {
      ...calendarItem,
      events: calendarItem.events.filter(event => event !== selectedEvent)
    };

    apiService.addToCalendar(calendar.id, newCalendarItem).then(() => {
      fetchCalendar(calendar.id);
    });
  };

  handleSendButton = () => {
    const { onSend, calendar, emailText, propertyDetails, assignmentAgreementSelected } = this.props;
    const today = dayjs(new Date()).format('YYYY-MM-DD');

    const selectedDocuments = calendar.documents.filter(document => document.selected);

    const calendarItems = calendar && calendar.items && calendar.items.length ?
      calendar.items.filter(item => !dayjs(item.date).isBefore(today) && ((item.events && item.events.length) || item.comment)) : null;

    const template = templateBuilder({
      broker: propertyDetails ? propertyDetails.broker : undefined,
      department: propertyDetails && propertyDetails.broker ? propertyDetails.broker.department : undefined,
      images: propertyDetails ? propertyDetails.images.filter(image => image.urlstorthumbnail) : undefined,
      address: propertyDetails ? propertyDetails.adresse + ', ' + propertyDetails.postnummer + ' ' + propertyDetails.poststed : undefined,
      seller: calendar.recipients ? calendar.recipients.find(recipient => recipient.type === 'selger') : undefined,
      documents: selectedDocuments && selectedDocuments.length ? selectedDocuments : null,
      assignmentAgreement: assignmentAgreementSelected && propertyDetails.oppdragsavtale ? propertyDetails.oppdragsavtale.url : null,
      emailText,
      calendarItems
    });

    onSend(template);
  };

  render() {
    const {
      assignmentAgreementSelected,
      propertyDetails,
      onClose,
      onPreview,
      calendar,
      emailText,
      customRecipient,
      customRecipientSelected
    } = this.props;
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    const calendarItems = calendar && calendar.items && calendar.items.length ?
      calendar.items.filter(item => !dayjs(item.date).isBefore(today) && ((item.events && item.events.length) || item.comment)) : null;

    return (
      <Modal onClose={ onClose } className="modal-fullscreen">
        <div className="modal__wrapper modal__wrapper_p30">
          <p className="modal__title modal__title_small">
            Send email
          </p>
          <textarea className="modal__text-content" value={ emailText } onChange={ this.handleChangeText }/>
          {
            calendarItems ?
              <div className="modal__event-list">
                { calendarItems.map(item => (
                  <div className="modal__event-item" key={ item.date }>
                    <p className="modal__event-title text-capitalize">
                      { dayjs(item.date).format('D MMMM YYYY') }
                    </p>
                    <ul className="c-selected__list c-selected__list_width400">
                      { item.events.map((event, i) => (
                        <li className="c-selected__item c-selected__item_color" key={ i }>
                          { event.includes('Annet: ') ? event.slice(7) : event }
                          <span className="remove-select remove-select__svg-color is-clickable"
                                onClick={ () => {
                                  this.removeEvent(item.date, event);
                                } }>
                            <svg width="10" height="10">
                              <use href={ Sprite + '#modal-close' }/>
                            </svg>
                          </span>
                        </li>
                      )) }
                    </ul>
                    { item.comment ? <div className="event-comment">{ item.comment }</div> : null }
                  </div>
                )) }
              </div> : null
          }
        </div>
        <div className="modal__inner">
          <div className="modal__section">
            <h3 style={ { fontSize: 20, marginBottom: 20 } }>Dokumenter</h3>
            { (calendar.documents && calendar.documents.length) || (propertyDetails && propertyDetails.oppdragsavtale) ?
              <ul className="modal__section-list">
                { calendar.documents.map(document => {
                  return (
                    <li key={ document.name } className="modal__section-item">
                      <div className="checkbox-row">
                        <input className="checkbox-row__item checkbox-row__item_black-border"
                               type="checkbox"
                               checked={ document.selected }
                               onChange={ () => this.handleSelectDocument(document.name) }
                               id={ document.name }/>
                        <label className="checkbox-row__label checkbox-row__label_fw400"
                               htmlFor={ document.name }>{ document.name }</label>
                        <a
                          href={ document.url }
                          target="_blank"
                          rel="noreferrer"
                          data-dokument={ document.name }
                        >
                          <img width={ 26 } height={ 26 } src={ ExternalLinkIcon } alt=""/>
                        </a>
                      </div>
                    </li>
                  );
                }) }

                { propertyDetails && propertyDetails.oppdragsavtale ? (
                  <li className="modal__section-item">
                    <div className="checkbox-row">
                      <input className="checkbox-row__item checkbox-row__item_black-border"
                             type="checkbox"
                             checked={ assignmentAgreementSelected }
                             onChange={ () => this.handleSelectAssignmentAgreement(!assignmentAgreementSelected) }
                             id="assignment-agreement-checkbox"/>
                      <label className="checkbox-row__label checkbox-row__label_fw400"
                             htmlFor="assignment-agreement-checkbox">Oppdragsavtale</label>
                      <a
                        href={ propertyDetails.oppdragsavtale.url }
                        target="_blank"
                        rel="noreferrer"
                        data-dokument="Oppdragsavtale"
                      >
                        <img width={ 26 } height={ 26 } src={ ExternalLinkIcon } alt=""/>
                      </a>
                    </div>
                  </li>
                ) : null }

              </ul> : (<p className="text-muted">Ingenting å velge</p>)
            }
            <h3 style={ { fontSize: 20, margin: '30px 0 20px' } }>Mottakere</h3>
            <ul className="modal__section-list">
              { calendar.recipients.map(recipient => {
                return (
                  <li key={ recipient.email } className="modal__section-item">
                    <div className="checkbox-row">
                      <input className="checkbox-row__item checkbox-row__item_black-border"
                             type="checkbox"
                             checked={ recipient.selected }
                             onChange={ () => this.handleSelectRecipient(recipient.email) }
                             id={ recipient.email }/>
                      <label className="checkbox-row__label checkbox-row__label_fw400"
                             htmlFor={ recipient.email }>{ recipient.email }</label>
                    </div>
                  </li>
                );
              }) }

              <li className="modal__section-item">
                <div className="checkbox-row">
                  <input className="checkbox-row__item checkbox-row__item_black-border"
                         checked={ customRecipientSelected }
                         onChange={ this.toggleCustomRecipient }
                         type="checkbox"
                         id="custom-recipient"/>
                  <input className={ `modal__custom-input ${ customRecipientSelected ? 'is-checked' : '' }` }
                         type="text"
                         placeholder="Send email til noen andre"
                         value={ customRecipient }
                         onClick={ this.handleClickCustomRecipient }
                         onChange={ this.handleChangeCustomRecipient }/>
                </div>
              </li>
            </ul>
          </div>
          <div className="modal__box-btns">
            <button
              className="button hover-dark button-default button-default_padding"
              style={ { width: '60%' } }
              onClick={ onPreview }>
              Forhåndsvisning
            </button>
            <button
              className="button hover-dark button-default button-default_padding button-default_ml20"
              style={ { width: '40%' } }
              onClick={ this.handleSendButton }
              disabled={ !calendar.recipients.some(r => r.selected) && (!customRecipientSelected || !customRecipient) }
            >
              Send
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  {
    assignmentAgreementSelected,
    calendar,
    propertyDetails,
    customRecipient,
    customRecipientSelected,
    emailText
  }
) => {
  return {
    assignmentAgreementSelected,
    calendar,
    propertyDetails,
    customRecipient,
    customRecipientSelected,
    emailText
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchCalendar: fetchCalendar(apiService, dispatch),
    setEmailText: (text) => {
      dispatch(setEmailText(text));
    },
    setCalendarRecipients: (newRecipients) => {
      dispatch(setCalendarRecipients(newRecipients));
    },
    setCalendarDocuments: (newDocuments) => {
      dispatch(setCalendarDocuments(newDocuments));
    },
    setCustomRecipient: (newCustomRecipient) => {
      dispatch(setCustomRecipient(newCustomRecipient));
    },
    setCustomRecipientSelected: (selected) => {
      dispatch(setCustomRecipientSelected(selected));
    },
    setAssignmentAgreementSelected: (selected) => {
      dispatch(setAssignmentAgreementSelected(selected));
    }
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(EmailModal);
