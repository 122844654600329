import React, { useState } from 'react';
import NumPad from '../../components/NumPad';
import { withApiService } from '../../components/HOC';
import { useHistory } from 'react-router-dom';
import BackLink from '../../components/BackLink';
import BackButton from '../../components/BackButton';
import LogoBig from '../../components/LogoBig';

const SetPin = ({ apiService }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [pin, setPin] = useState('');
  let history = useHistory();

  const handleNumPadSubmit = (data) => {
    if (!pin) {
      setPin(data);
    } else {
      if (pin === data) {
        apiService.setPin(pin).then(() => {
          setSuccessMessage('PIN-kode er satt!');
          setTimeout(() => {
            const redirect = localStorage.getItem('befaring_redirect');
            history.push(redirect ? redirect : '/');
          }, 1000);
        });
      } else {
        setErrorMessage('PIN-koder samsvarer ikke');
        setTimeout(() => {
          setErrorMessage('');
        }, 1000);
      }
    }
  };

  const handleBackButtonClick = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setPin('');
  };

  const handleClearError = () => {
    setErrorMessage('');
  };

  return (
    <div className="login-page bg-solid">
      { pin ? <BackButton onClick={ handleBackButtonClick }/> : <BackLink/> }
      <div className="w-100">
        <LogoBig/>
        <div style={ { minHeight: '20px', marginBottom: '16px' } }>
          {
            errorMessage ?
              <p className="text-danger text-center">
                { errorMessage }
              </p>
              :
              successMessage ?
                <p className="text-success text-center">
                  { successMessage }
                </p>
                :
                <p className="text-center">
                  { !pin ? 'Tast inn ny PIN-kode' : 'Tast inn PIN-kode på nytt' }
                </p>
          }
        </div>
        <NumPad onSubmit={ handleNumPadSubmit } onClear={ handleClearError }/>
      </div>
    </div>
  );
};

export default withApiService()(SetPin);
