import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const authData = JSON.parse(localStorage.getItem('befaring_auth'));
const accessToken = authData ? authData['access_token'] : null;
axios.defaults.headers = accessToken ? { Authorization: `Bearer ${ accessToken }` } : {};

axios.interceptors.response.use(
  function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      if (window.location.pathname !== '/change-pin' && window.location.pathname !== '/login') {
        // clear localstorage and axios default headers
        localStorage.removeItem('befaring_auth');
        axios.defaults.headers = {};
        window.location.replace('/login');
      }
    }
    return Promise.reject(error);
  }
);

const errorHandler = (error) => {
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    return error.response.data;
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else {
    // Something happened in setting up the request and triggered an Error
    return error;
  }
};

export default class ApiService {
  getProperties(type = '') {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/eiendommer?my=true&pageSize=-1${ type ? '&type=' + type : '' }`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            resolve(response.data.eiendommer);
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getOfficeProperties(type = '') {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/eiendommer?pageSize=-1${ type ? '&type=' + type : '' }`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            resolve({
              ...response.data.meta,
              list: response.data.eiendommer
            });
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getSearchProperties(query, type = '', page = 1) {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/eiendommer?pageSize=20${ type ? '&type=' + type : '' }&text=${ query }&page=${ page }`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            resolve({
              ...response.data.meta,
              list: response.data.eiendommer
            });
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getCalendar(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/${ id }/events`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            const recipients = response.data.recipients ?
              response.data.recipients.map(recipient => {
                return {
                  ...recipient,
                  selected: false
                };
              }) : [];

            const documents = response.data.documents ?
              response.data.documents.map(document => {
                return {
                  ...document,
                  selected: false
                };
              }) : [];

            resolve({
              id: id,
              items: response.data.calendar,
              recipients: recipients,
              documents: documents
            });
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  addToCalendar(id, data) {
    return new Promise((resolve, reject) => {
      axios.post(`/befaring/oppdrag/${ id }/events`, data)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getPropertyDetails(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/detaljer/${ id }`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            const eiendom = response.data.eiendom;
            const currentPlaceMarker = {
              id: eiendom.id,
              current: true,
              selected: true,
              address: eiendom.adresse + ', ' + eiendom.postnummer + ', ' + eiendom.poststed,
              lat: +eiendom.lat,
              lng: +eiendom.lng
            };
            const markers = response.data.markers && response.data.markers.length ?
              response.data.markers.map(marker => {
                marker.current = marker.id === id;
                marker.selected = marker.id === id;
                marker.lat = +marker.lat;
                marker.lng = +marker.lng;
                return marker;
              })
              : [];
            const uniqueMarkers = markers.filter((marker, index, self) =>
                index === self.findIndex((m) => (
                  m.lat === marker.lat && m.lng === marker.lng
                ))
            );
            if (!uniqueMarkers.find(marker => marker.id === id)) {
              uniqueMarkers.push(currentPlaceMarker);
            }
            const neighbourhoods = eiendom && eiendom.propertyNeighbourhoods ? eiendom.propertyNeighbourhoods : [];
            const categories = [];
            neighbourhoods.forEach((item, i) => {
              const categoryItem = categories.find(category => category.name === item.type);
              let duration = null;
              if (item.duration) {
                if (item.duration.walk && item.duration.drive) {
                  if (item.duration.walk > 25) {
                    duration = {
                      type: 'drive',
                      time: item.duration.drive + ' min',
                      timeForSort: 100 + item.duration.drive
                    };
                  } else {
                    duration = {
                      type: 'walk',
                      time: item.duration.walk + ' min',
                      timeForSort: item.duration.walk
                    };
                  }
                } else if (item.duration.drive) {
                  duration = {
                    type: 'drive',
                    time: item.duration.drive + ' min',
                    timeForSort: 100 + item.duration.drive
                  };
                } else if (item.duration.walk) {
                  duration = {
                    type: 'walk',
                    time: item.duration.walk + ' min',
                    timeForSort: item.duration.walk
                  };
                }
              }
              const currentItem = {
                id: +(i + 1 + '0' + (i + 1)),
                name: item.name,
                selected: false,
                duration: duration,
                lat: item.coordinates.lat,
                lng: item.coordinates.long
              };

              if (!categoryItem) {
                categories.push({
                  id: i + 1,
                  name: item.type,
                  checked: true,
                  opened: false,
                  items: [currentItem]
                });
              } else {
                categoryItem.items.push(currentItem);
              }
            });
            // console.log(categories);
            categories.forEach(category => {
              category.items.sort((a, b) => a.duration.timeForSort - b.duration.timeForSort);
            });

            const propertyDetails = {
              ...eiendom,
              markers: uniqueMarkers,
              categories: categories
            };
            // console.log(propertyDetails)
            resolve(propertyDetails);
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getSelectedProperty(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/detaljer/${ id }`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            resolve(response.data.eiendom);
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  login(data) {
    return new Promise((resolve, reject) => {
      axios.post('/login', data)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            localStorage.setItem('befaring_auth', JSON.stringify(response.data));
            axios.defaults.headers = { Authorization: `Bearer ${ response.data['access_token'] }` };
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  setPin(pin) {
    return axios.patch('/user/pincode', { pincode: pin });
  }

  logout() {
    return new Promise((resolve, reject) => {
      axios.post('/logout')
        .then(res => {
          localStorage.removeItem('befaring_auth');
          axios.defaults.headers = {};
          resolve(res);
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getClients(params, page = 1) {
    const filteredParams = {
      ...params,
      primaerrom: params.primaerrom.to === 300 ? { from: params.primaerrom.from } : params.primaerrom,
      salgssum: params.salgssum.to === 10000000 ? { from: params.salgssum.from } : params.salgssum
    };

    return new Promise((resolve, reject) => {
      axios.post(`befaring/oppdrag/popential-clients?pageSize=20&page=${ page }`, filteredParams)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            resolve({
              ...response.data.meta,
              list: response.data.list
            });
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  sendEmail(data) {
    return axios.post('/mail/send', data);
  }

  sendContact(data) {
    return axios.post('/contact', data);
  }

  searchClient(phone) {
    return new Promise((resolve, reject) => {
      axios.post('/client/search', { phone: phone })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  getEiendomsverdiSignedUrl() {
    return axios.get('/eiendomsverdi');
  }

  getLeads(propertyId) {
    return new Promise((resolve, reject) => {
      axios.get(`/befaring/oppdrag/${ propertyId }/visningspamelding`)
        .then(res => {
          const response = res.data;
          if (response && response.data) {
            resolve(response.data);
          } else {
            reject(new Error('No data received'));
          }
        })
        .catch(error => {
          reject(errorHandler(error));
        });
    });
  }

  unsubscribeLead(leadId) {
    return axios.post('/lead/meldav', { id: leadId });
  }

  updatePropertyParam(propertyId, param, value) {
    return axios.patch(`/eiendom/${ propertyId }`, { [param]: value ? +value : null });
  }
}
