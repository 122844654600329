import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { withApiService } from '../../components/HOC';
import { fetchPropertyDetails } from '../../actions';
import { compose } from '../../utils';

import Tabbar from '../../components/Tabbar';
import Detaljer from './Detaljer';
import Statistikk from './Statistikk';
import Nabolagsprofil from './Nabolagsprofil';
import Bilder from './Bilder';
import ApiError from '../../components/ApiError';

class Hjem extends Component {
  componentDidMount() {
    const { propertyDetails } = this.props;
    const id = this.props.match.params.id;
    if (id) {
      if (!propertyDetails || propertyDetails.id !== id) {
        this.props.fetchPropertyDetails(id);
      }
    }
  }

  render() {
    const id = this.props.match.params.id;

    const { propertyDetails, loading, error } = this.props;

    if (loading) {
      return (
        <div>Loading...</div>
      );
    }

    if (error) {
      return (
        <ApiError errorData={ error } />
      );
    }

    const tabs = [
      { label: 'Detaljer', path: `/${ id }/hjem/detaljer` },
      { label: 'Statistikk', path: `/${ id }/hjem/statistikk` }
    ];
    if (propertyDetails && propertyDetails.categories && propertyDetails.categories.length) {
      tabs.push({ label: 'Nabolagsprofil', path: `/${ id }/hjem/nabolagsprofil` });
    }
    if (propertyDetails && propertyDetails.images && propertyDetails.images.length) {
      tabs.push({ label: 'Bilder', path: `/${ id }/hjem/bilder` });
    }

    return (
      <div className="l-grid">
        <div className="l-grid__content">
          <Tabbar tabs={ tabs }/>
          {
            propertyDetails ?
              <Switch>
                <Route path="/:id/hjem/detaljer" component={ Detaljer }/>
                <Route path="/:id/hjem/statistikk" component={ Statistikk }/>
                <Route path="/:id/hjem/nabolagsprofil" component={ Nabolagsprofil }/>
                <Route path="/:id/hjem/bilder" component={ Bilder }/>

                <Redirect from="/:id/hjem" to="/:id/hjem/detaljer"/>
              </Switch>
              : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ propertyDetails, loading, error }) => {
  return { propertyDetails, loading, error };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchPropertyDetails: fetchPropertyDetails(apiService, dispatch)
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Hjem);
