import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Tabbar from '../../components/Tabbar';
import Omrade from './Omrade';
import Liste from './Liste';
import { fetchPropertyDetails } from '../../actions';
import { compose } from '../../utils';
import { withApiService } from '../../components/HOC';
import { connect } from 'react-redux';

import './MuligeKjopere.scss';
import ApiError from '../../components/ApiError';

class MuligeKjopere extends Component {
  componentDidMount() {
    const { propertyDetails } = this.props;
    const id = this.props.match.params.id;
    if (id) {
      if (!propertyDetails || propertyDetails.id !== id) {
        this.props.fetchPropertyDetails(id);
      }
    }
  }

  render() {
    const id = this.props.match.params.id;

    const { propertyDetails, loading, error } = this.props;

    if (loading) {
      return (
        <div>Loading...</div>
      );
    }

    if (error) {
      return (
        <ApiError errorData={ error } />
      );
    }

    return (
      <div className="l-grid">
        <div className="l-grid__content" style={ { position: 'relative' } }>
          <Tabbar tabs={
            [
              { label: 'Område', path: `/${ id }/mulige-kjopere/omrade` },
              { label: 'Liste', path: `/${ id }/mulige-kjopere/liste` }
            ]
          }/>
          { propertyDetails ?
            <Switch>
              <Route path="/:id/mulige-kjopere/omrade" component={ Omrade }/>
              <Route path="/:id/mulige-kjopere/liste" component={ Liste }/>

              <Redirect from="/:id/mulige-kjopere" to="/:id/mulige-kjopere/omrade"/>
            </Switch>
            : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ propertyDetails, loading, error }) => {
  return { propertyDetails, loading, error };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchPropertyDetails: fetchPropertyDetails(apiService, dispatch)
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(MuligeKjopere);
