import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Sidebar from '../Sidebar';

import Hjem from '../../pages/Hjem';
import OmOss from '../../pages/OmOss';
import MuligeKjopere from '../../pages/MuligeKjopere';
import Kalender from '../../pages/Kalender';
import Links from '../../pages/Links';

const Layout = () => {
  return (
    <Fragment>
      <Sidebar/>
      <Switch>
        <Route path="/:id/hjem" component={ Hjem }/>
        <Route path="/:id/om-oss" component={ OmOss }/>
        <Route path="/:id/lenker" component={ Links }/>
        <Route path="/:id/mulige-kjopere" component={ MuligeKjopere }/>
        <Route path="/:id/kalender" exact component={ Kalender }/>

        <Redirect from="/:id" to="/:id/hjem"/>
      </Switch>
    </Fragment>
  );
};

export default Layout;
