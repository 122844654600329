import React, { forwardRef } from 'react';
import { dayjs, formatter } from '../../utils';
import styled from 'styled-components';
import { slideDownAnimation, slideUpAnimation } from '../../animations';
import EditIcon from '../../images/icons/edit.svg';
import EditIconWhite from '../../images/icons/editWhite.svg';

const Item = forwardRef(({ className, item, editable, onEdit }, ref) => {
  const { label, value, param } = item;

  let smallValue;

  let renderedValue = isNaN(value) ? value : formatter.format(+value);
  if (param === 'prom' || param === 'bra' || param === 'tomt' || param === 'forventet_hovedrom') {
    renderedValue = renderedValue + ' m²';
  }
  if (param === 'akseptdato' || param === 'befaringsdato') {
    renderedValue = dayjs(value, 'DD.MM.YYYY').format('D. MMM YYYY');
  }
  if (param === 'markedsforingsdato' || param === 'oppdragsdato') {
    renderedValue = dayjs.unix(value).format('D. MMM YYYY');
  }
  if (param.includes('nummer') || param === 'byggeaar') {
    renderedValue = value;
  }
  if (param === 'salgstid') {
    renderedValue = value === 1 ? value + ' dag' : value + ' dager';
  }
  if (param === 'over_prisantydning') {
    const valuesArr = value.split(',- ');
    renderedValue = valuesArr[0];
    smallValue = valuesArr[1] ? valuesArr[1].replace('(', '').replace(' )', '') : null;
  }

  return (
    <li ref={ ref } className={ `${ className } c-tile__item` }>
      { editable ? ((param === 'forventet_hovedrom' || param === 'forventet_salgspris') ? (
        <img src={ EditIconWhite } alt="" className="c-tile-item-edit" onClick={ onEdit }/>
      ) : (param === 'prisantydning' || param === 'prom') ? (
        <img src={ EditIcon } alt="" className="c-tile-item-edit" onClick={ onEdit }/>
      ) : null) : null }

      <p className="c-tile__title">
        { label }
      </p>

      <p className={ `c-tile__content ${ param.includes('dato') ? 'text-capitalize' : '' }` }>
        { renderedValue } { smallValue ? <small>{ smallValue }</small> : null }
      </p>
    </li>
  );
});

const TileItem = styled(Item)`
  &.appear,
  &.enter {
    animation-name: ${ slideUpAnimation };
    animation-duration: ${ ({ duration }) => duration.enter }ms;
    animation-timing-function: ease-in-out;
    animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
  }

  animation-fill-mode: both;

  &.appear {
    animation-name: ${ slideUpAnimation };
    animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
  }

  &.exit {
    animation-name: ${ slideDownAnimation };
    animation-duration: ${ ({ duration }) => duration.enter }ms;
    animation-timing-function: ease-in-out;
  }
`;

export default TileItem;
