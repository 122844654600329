import React, { useEffect, useState } from 'react';
import AnnonsepakkeImage from '../../../images/pdf/produktark_annonsepakke.png';
import { CSSTransition } from 'react-transition-group';

const Boligannonsen = () => {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    setShowImage(true);
  }, []);

  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info">
          <div className="l-grid__section l-grid__section_overflow" style={ { marginTop: 0 } }>
            <CSSTransition
              in={ showImage }
              timeout={ 300 }
              classNames="list-0-transition"
              unmountOnExit
            >
              <img src={ AnnonsepakkeImage } alt="" style={ { maxWidth: 800, margin: '0 auto' } }/>
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boligannonsen;
