import React, { forwardRef, useRef, useState, useEffect } from 'react';
import './Liste.scss';
import Sprite from '../../../components/App/images/sprites/sprite.svg';
import { fetchClients, setClientsParams } from '../../../actions';
import { compose, formatter } from '../../../utils';
import { withApiService } from '../../../components/HOC';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from '../../../utils/dayjs';
import AnimatedList from '../../../components/AnimatedList';
import styled from 'styled-components';
import { slideDownAnimation, slideUpAnimation } from '../../../animations';
import { CSSTransition } from 'react-transition-group';

const TagList = ({ params, onReset }) => {
  return (
    <li className="c-selected__item">
      { params.map((param, idx) => (
        <span key={ idx }>{ param }{ idx < params.length - 1 ? <span>,&nbsp;</span> : null }</span>
      )) }
      <span className="remove-select is-clickable" onClick={ onReset }>
        <svg width="10" height="10"><use href={ Sprite + '#modal-close' }/></svg>
      </span>
    </li>
  );
};

const TagRange = ({ range, unit, onReset, formatted = false }) => {
  return (
    <li className="c-selected__item">
      <span>{ formatted ? formatter.format(range.from) : range.from }</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{ formatted ? formatter.format(range.to) : range.to }{ unit ? <span>&nbsp;{ unit }</span> : null }</span>
      <span className="remove-select is-clickable" onClick={ onReset }>
        <svg width="10" height="10"><use href={ Sprite + '#modal-close' }/></svg>
      </span>
    </li>
  );
};

const ClientItem = forwardRef(({ className, item }, ref) => {
  return (
    <li ref={ ref } className={ `${ className } c-list-result__item` }>
      <p className="c-list-result__name">
        { item.fullName }
      </p>
      <p className="c-list-result__date text-capitalize">
        { dayjs(item.registrationDate).format('D MMMM YYYY') }
      </p>
    </li>
  );
});

const StyledClientItem = styled(ClientItem)`
  &.appear,
  &.enter {
    animation-name: ${ slideUpAnimation };
    animation-duration: ${ ({ duration }) => duration.enter }ms;
    animation-timing-function: ease-in-out;
    animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
  }

  animation-fill-mode: both;

  &.appear {
    animation-name: ${ slideUpAnimation };
    animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
  }

  &.exit {
    animation-name: ${ slideDownAnimation };
    animation-duration: ${ ({ duration }) => duration.enter }ms;
    animation-timing-function: ease-in-out;
  }
`;

const Liste = ({ clients, clientsParams, setClientsParams, fetchClients, propertyDetails }) => {
  let history = useHistory();

  const [showTags, setShowTags] = useState(false);

  useEffect(() => {
    setShowTags(true);
  }, []);

  const [nextPageRequested, setNextPageRequested] = useState(false);

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const tagsRef = useRef(null);

  const onScroll = () => {
    const containerScrollTop = containerRef.current.scrollTop;
    const containerHeight = containerRef.current.clientHeight;
    const contentHeight = contentRef.current.clientHeight + tagsRef.current.clientHeight + 40; // 40 is top margin
    if (containerHeight + containerScrollTop === contentHeight) {
      // fetch next page if needed
      if (clients.pagesCount > clients.currentPage) {
        if (!clients.loading) {
          setNextPageRequested(true);
          fetchClients(clientsParams, clients.currentPage + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (clients.totalCount === 0) {
      const newParams = {
        ...clientsParams,
        center: {
          lat: +propertyDetails.lat,
          lng: +propertyDetails.lng
        }
      };
      updateClients(newParams);
    }
    // eslint-disable-next-line
  }, []);

  const updateClients = (newParams) => {
    setClientsParams(newParams);
    fetchClients(newParams);
  };

  const resetParam = (param) => {
    const newParams = { ...clientsParams };
    switch (param) {
      case 'avstand':
        history.push(`/${ propertyDetails.id }/mulige-kjopere/omrade`);
        break;
      case 'leadstype':
        newParams.leadstype = [];
        updateClients(newParams);
        break;
      case 'eiendomstype':
        newParams.eiendomstype = [];
        updateClients(newParams);
        break;
      case 'primaerrom':
        newParams.primaerrom = {
          from: 0,
          to: 300
        };
        updateClients(newParams);
        break;
      case 'registrert':
        newParams.registrert = {
          from: 0,
          to: 10
        };
        updateClients(newParams);
        break;
      case 'salgssum':
        newParams.salgssum = {
          from: 0,
          to: 10000000
        };
        updateClients(newParams);
        break;
      default:
        break;
    }
  };

  const { leadstype, eiendomstype, registrert, salgssum, primaerrom, avstand } = clientsParams;

  if (!clientsParams) {
    return null;
  }

  return (
    <div className="l-grid__content">
      <div className="clients-counter">
        <div>
          Mulige kjøpere:
        </div>
        <div className="clients-counter-total">
          { formatter.format(clients.totalCount) }
        </div>
      </div>
      <div className="l-grid__inner">
        <div className="l-grid__block">
          <div className="l-grid__info">
            <div ref={ containerRef } onScroll={ onScroll }
                 className="l-grid__section l-grid__section_overflow l-grid__section_mt0  l-grid__section_mr15pr15">
              <CSSTransition
                in={ showTags }
                timeout={ 300 }
                classNames="list-0-transition"
              >
                <div ref={ tagsRef } className="c-selected">
                  <ul className="c-selected__list">
                    {
                      leadstype.length ?
                        <TagList params={ leadstype }
                                 onReset={ () => resetParam('leadstype') }/>
                        : null
                    }

                    {
                      eiendomstype.length ?
                        <TagList params={ eiendomstype }
                                 onReset={ () => resetParam('eiendomstype') }/>
                        : null
                    }

                    {
                      registrert.from > 0 || registrert.to < 10 ?
                        <TagRange range={ registrert } unit="mnd"
                                  onReset={ () => resetParam('registrert') }/>
                        : null
                    }

                    {
                      salgssum.from > 0 || salgssum.to < 10000000 ?
                        <TagRange range={ salgssum } formatted={ true } unit=""
                                  onReset={ () => resetParam('salgssum') }/>
                        : null
                    }

                    {
                      primaerrom.from > 0 || primaerrom.to < 300 ?
                        <TagRange range={ primaerrom } unit="m²"
                                  onReset={ () => resetParam('primaerrom') }/>
                        : null
                    }

                    {
                      avstand !== 100 ? (
                          <li className="c-selected__item">
                            <span>{ avstand >= 1000 ? avstand / 1000 + ' km' : avstand + ' m' }</span>
                            <span className="remove-select is-clickable"
                                  onClick={ () => resetParam('avstand') }>
                              <svg width="10" height="10"><use href={ Sprite + '#modal-close' }/></svg>
                            </span>
                          </li>
                        )
                        : null
                    }
                  </ul>

                </div>
              </CSSTransition>
              {
                clients.totalCount ? (
                  <div ref={ contentRef } className="c-list-result">
                    <ul className="c-list-result__caption">
                      <li>
                        Navn
                      </li>
                      <li>
                        Registrert
                      </li>
                    </ul>
                    <ul className="c-list-result__items">
                      <AnimatedList
                        nextPageRequested={ nextPageRequested }
                        page={ clients.currentPage }
                        items={ clients.list }
                        Item={ StyledClientItem }/>
                    </ul>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ propertyDetails, clientsParams, clients }) => {
  return { propertyDetails, clientsParams, clients };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchClients: fetchClients(apiService, dispatch),
    setClientsParams: (params) => {
      dispatch(setClientsParams(params));
    }
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Liste);
