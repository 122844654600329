import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import { setPropertyCategories } from '../../../actions';

import './Nabolagsprofil.scss';
import IconsSprite from './images/icons-sprite.svg';
import MarkerIcon from '../../../components/MarkerIcon';
import { mapConfig } from '../../../utils';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { CSSTransition } from 'react-transition-group';

let mapInstance, googleMaps;

const Nabolagsprofil = ({ propertyDetails, setPropertyCategories }) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    setShowMap(true);
  }, []);

  const markersList = [].concat(
    ...propertyDetails.categories.map(category => {
      return category.items.map(item => {
        return item;
      });
    })
  );

  const refs = markersList.reduce((acc, item) => {
    acc[item.id] = React.createRef();
    return acc;
  }, {});

  const toggleProperty = (arr, id, propName) => {
    const idx = arr.findIndex((el) => el.id === id);

    // update object
    const oldItem = arr[idx];
    const newItem = { ...oldItem, [propName]: !oldItem[propName] };

    // construct new array
    return [
      ...arr.slice(0, idx),
      newItem,
      ...arr.slice(idx + 1)
    ];
  };

  const checkCategory = (id) => {
    const categories = propertyDetails.categories;

    const newCategories = toggleProperty(categories, id, 'checked');
    setPropertyCategories(newCategories);
  };

  const toggleCategory = (currentCategory) => {
    const categories = propertyDetails.categories;

    let newCategories = toggleProperty(categories, currentCategory.id, 'opened');
    if (!currentCategory.opened && !currentCategory.checked) {
      newCategories = toggleProperty(newCategories, currentCategory.id, 'checked');
    }
    setPropertyCategories(newCategories);
  };

  const toggleAll = () => {
    const categories = propertyDetails.categories;

    const allChecked = categories.every(category => category.checked);

    const newCategories = categories.map(category => {
      category.checked = !allChecked;
      return category;
    });

    setPropertyCategories(newCategories);
  };

  const selectItem = (selectedItem) => {
    const categories = propertyDetails.categories;

    const newCategories = categories.map(category => {
      let isCategoryOpened = category.opened;
      category.items = category.items.map(categoryItem => {
        const isItemSelected = categoryItem.id === selectedItem.id;
        categoryItem.selected = isItemSelected;
        if (isItemSelected) {
          isCategoryOpened = true;
        }
        return categoryItem;
      });
      category.opened = isCategoryOpened;
      return category;
    });

    setPropertyCategories(newCategories);

    fitItemToMap(selectedItem);
    scrollToItem(selectedItem);
  };

  const scrollToItem = (selectedItem) => {
    const currentNode = refs[selectedItem.id].current;
    setTimeout(() => {
      scrollIntoView(currentNode, { scrollMode: 'if-needed', behavior: 'smooth', block: 'center' });
    }, 200);
  };

  const fitItemToMap = (selectedItem) => {
    const distanceInPx = (marker1Position, marker2Position) => {
      const p1 = mapInstance.getProjection().fromLatLngToPoint(marker1Position);
      const p2 = mapInstance.getProjection().fromLatLngToPoint(marker2Position);

      const pixelSize = Math.pow(2, -mapInstance.getZoom());

      return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
    };

    const mapBounds = mapInstance.getBounds();

    const propertyPosition = new googleMaps.LatLng(+propertyDetails.lat, +propertyDetails.lng);
    const selectedItemPosition = new googleMaps.LatLng(+selectedItem.lat, +selectedItem.lng);

    const fitMapBounds = () => {
      const newBounds = new googleMaps.LatLngBounds();

      newBounds.extend(propertyPosition);
      newBounds.extend(selectedItemPosition);

      mapInstance.fitBounds(newBounds);

      const zoom = mapInstance.getZoom();
      if (zoom > 11) {
        mapInstance.setZoom(zoom - 1);
      }
    };

    if (!mapBounds.contains(selectedItemPosition) || !mapBounds.contains(propertyPosition)) {
      fitMapBounds();
    } else if (distanceInPx(propertyPosition, selectedItemPosition) < 150) {
      fitMapBounds();
    }
  };

  const onMapInit = ({ map, maps }) => {
    mapInstance = map;
    googleMaps = maps;

    let selectedItem;
    propertyDetails.categories.find(category => {
      const item = category.items.find(item => item.selected);
      if (item) {
        selectedItem = item;
        return true;
      } else {
        return false;
      }
    });
    if (selectedItem) {
      fitItemToMap(selectedItem);
    }
  };

  const icons = {
    'Steder i nærheten': 'flag',
    'Transport': 'transport',
    'Sport': 'sport',
    'Skoler': 'skole',
    'Barnehager': 'toy',
    'Varer/tjenester': 'products',
    'Green environment': 'grontomrade',
    'Higher education': 'universitet',
    'Avstand til byer': 'avstander-til-byer'
  };

  const markers = propertyDetails.categories.map(category => {
    if (category.checked) {
      return category.items.map(item => {
        if (item.lat && item.lng) {
          return (
            <MarkerIcon
              key={ item.id }
              label={ item.name }
              icon={ icons[category.name] }
              selected={ item.selected }
              lat={ item.lat }
              lng={ item.lng }
              onSelected={ () => selectItem(item) }
            />
          );
        }
        return null;
      });
    }
    return null;
  });

  if (!propertyDetails) {
    return (
      <div>Error!</div>
    );
  }

  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__sidebar">
          <div className="l-grid__section l-grid__section_overflow mt-0">
            <CSSTransition
              in={ showMap }
              timeout={ 200 }
              classNames="sidebar-item-0-transition"
            >
              <div className="block-parent-checkbox">
                <div className="checkbox-row">
                  <input className="checkbox-row__item" type="checkbox" id="Vis alt"
                         checked={ propertyDetails.categories.every(category => category.checked) }
                         onChange={ () => toggleAll() }/>
                  <label className="checkbox-row__label" htmlFor="Vis alt">Vis alt</label>
                </div>
              </div>
            </CSSTransition>
            <ul className="c-list-toggle">
              {
                propertyDetails.categories.map((category, idx) => (
                  <CSSTransition
                    in={ showMap }
                    key={ category.id }
                    timeout={ 100 * (idx + 1) + 200 }
                    classNames={ `sidebar-item-${ idx + 1 }-transition` }
                  >
                    <li className="c-list-toggle__item">
                      <div className="c-list-toggle__inner">
                        <div className="checkbox-row">
                          <input className="checkbox-row__item" type="checkbox" id={ 'category-' + category.id }
                                 checked={ category.checked } onChange={ () => checkCategory(category.id) }/>
                          <label className="checkbox-row__label" onClick={ () => toggleCategory(category) }>
                            { category.name }
                          </label>
                        </div>
                        <div className={ `c-list-toggle__box ${ category.opened ? 'is-clicked' : '' }` }
                             onClick={ () => toggleCategory(category) }>
                          <span className="c-list-toggle__icon">
                            <svg width="20" height="20">
                              <use href={ IconsSprite + '#icon-' + icons[category.name] }/>
                            </svg>
                          </span>
                          <span className="c-list-toggle__arrow">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.6318 6.15771L9.00026 12.7893L2.36868 6.15771" stroke="white"
                                    strokeWidth="2.5"
                                    strokeLinejoin="round"/>
                            </svg>
                          </span>
                        </div>
                      </div>

                      { category.items ?
                        <ul className={ `c-list-toggle__hidden ${ category.opened ? 'is-clicked' : '' }` }>
                          { category.items.map(item => (
                            <li className={ `c-list-toggle__hidden-row ${ item.selected ? 'is-clicked' : '' }` }
                                key={ item.id }
                                ref={ refs[item.id] }
                                onClick={ () => selectItem(item) }>
                              <p className="c-list-toggle__hidden-text">
                                { item.name }
                              </p>
                              { item.duration ?
                                <div className="c-list-toggle__hidden-box  ">
                                <span className="c-list-toggle__hidden-icon">
                                  {
                                    item.duration.type === 'walk' ?
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M6.71503 4.05505C7.8348 4.05505 8.74256 3.1473 8.74256 2.02753C8.74256 0.907755 7.8348 0 6.71503 0C5.59525 0 4.6875 0.907755 4.6875 2.02753C4.6875 3.1473 5.59525 4.05505 6.71503 4.05505Z"/>
                                        <path
                                          d="M11.7946 5.96001C11.7831 5.95621 11.7715 5.95283 11.7598 5.94989L10.2634 5.57581L8.37126 4.15654C8.28352 4.09073 8.17681 4.05518 8.06713 4.05518H5.4045C5.27008 4.05521 5.14118 4.10864 5.04614 4.20368L2.00486 7.24495C1.80697 7.4429 1.80697 7.76375 2.00486 7.96167L2.3394 8.29621C2.52203 8.47893 2.81285 8.49508 3.01457 8.33372L5.19467 6.58956V8.61709L4.68778 11.1515L3.46114 12.3781C3.29022 12.549 3.2637 12.8167 3.39779 13.0178L3.87427 13.7325C4.02936 13.9656 4.34403 14.0288 4.57706 13.8737C4.58935 13.8655 4.60126 13.8568 4.61279 13.8476L6.58151 12.2722C6.66826 12.2029 6.72986 12.1071 6.75689 11.9995L7.2222 10.1377L8.23595 11.6583L9.04696 13.6813C9.15091 13.9412 9.44589 14.0677 9.7058 13.9637C9.71889 13.9585 9.73178 13.9527 9.74441 13.9464L10.8519 13.3929C11.0922 13.2729 11.1971 12.9863 11.0912 12.7395L9.75655 9.63081L8.74281 8.11017L8.6617 6.58953L9.75655 7.09642L11.3279 7.48926C11.5848 7.55345 11.8478 7.40899 11.9316 7.15775L12.1171 6.6002C12.2048 6.33435 12.0604 6.04775 11.7946 5.96001Z"/>
                                      </svg>
                                      : null
                                  }

                                  {
                                    item.duration.type === 'drive' ?
                                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M12.9448 5.39543L11.9629 2.12256C11.739 1.37616 11.0652 0.875 10.2864 0.875H3.71362C2.93475 0.875 2.26098 1.37616 2.03711 2.12256L1.05524 5.39543C0.435254 5.66486 0 6.28185 0 7V10.5C0 10.9832 0.391785 11.375 0.875 11.375H0.875053L0.875 12.2499C0.875 12.7332 1.26673 13.125 1.75 13.125H2.62505C3.10827 13.125 3.50005 12.7332 3.50005 12.25L3.49968 11.375H10.5L10.4999 12.2499C10.4999 12.7332 10.8917 13.125 11.3749 13.125H12.25C12.7333 13.125 13.125 12.7332 13.125 12.25V11.3751L13.1247 11.375H13.125C13.6082 11.375 14 10.9832 14 10.5V7C14 6.28185 13.5647 5.66485 12.9448 5.39542L12.9448 5.39543ZM3.7136 2.625H10.2864L11.0739 5.25H2.92607L3.71359 2.625H3.7136ZM3.49997 9.1875C3.0167 9.1875 2.62497 8.79572 2.62497 8.3125C2.62497 7.82923 3.0167 7.4375 3.49997 7.4375C3.98324 7.4375 4.37497 7.82923 4.37497 8.3125C4.37497 8.79572 3.98324 9.1875 3.49997 9.1875ZM10.5 9.1875C10.0167 9.1875 9.62497 8.79572 9.62497 8.3125C9.62497 7.82923 10.0167 7.4375 10.5 7.4375C10.9832 7.4375 11.375 7.82923 11.375 8.3125C11.375 8.79572 10.9832 9.1875 10.5 9.1875Z"/>
                                      </svg>
                                      : null
                                  }
                                </span>
                                  <span className="c-list-toggle__hidden-distance">
                                  { item.duration.time }
                                </span>
                                </div>
                                : null
                              }
                            </li>
                          ))
                          }
                        </ul>
                        : null
                      }
                    </li>
                  </CSSTransition>
                ))
              }
            </ul>
          </div>
        </div>
        <div className="l-grid__map l-grid__map_long">
          <CSSTransition
            in={ showMap }
            timeout={ 800 }
            classNames="map-transition"
            unmountOnExit
          >
            <div className="map-wrapper">
              <GoogleMapReact
                options={ { styles: mapConfig.styles } }
                bootstrapURLKeys={ mapConfig.urlKeys }
                defaultCenter={ { lat: +propertyDetails.lat, lng: +propertyDetails.lng } }
                defaultZoom={ 15 }
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={ onMapInit }
              >
                <MarkerIcon
                  label={ propertyDetails.adresse + ', ' + propertyDetails.postnummer + ' ' + propertyDetails.poststed }
                  lat={ +propertyDetails.lat }
                  lng={ +propertyDetails.lng }
                  current
                  selected
                />
                { markers }
              </GoogleMapReact>
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ propertyDetails }) => {
  return { propertyDetails };
};

const mapDispatchToProps = {
  setPropertyCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Nabolagsprofil);
