import React, { useRef, createRef, useEffect, useState } from 'react';
import PropertyListItemTransitioned from '../PropertyListItemTransitioned';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

const PropertyListTransitioned = ({ properties, page, query, visning }) => {
  const itemsRef = useRef({});

  const [justMounted, setJustMounted] = useState(true);
  const [initialQuery] = useState(query);

  let history = useHistory();

  useEffect(() => {
    if (query !== initialQuery) {
      // console.log('query was changed to: "', query, '"')
      setJustMounted(false);
    }
  }, [query, initialQuery]);

  const handleClickProperty = (id) => {
    const path = visning ? `/visningsliste/${ id }` : `/${ id }/hjem/detaljer`;
    history.push(path);
  };

  const duration = { enter: 300, exit: 300 };
  const STAGGER = 100;

  return (
    <ul className="c-property__list">
      <TransitionGroup component={ null } appear>
        { properties.map((property, index) => {
          itemsRef.current[index] = createRef();

          const orderNumber = justMounted ? index : index - ((page - 1) * 20);
          const order = orderNumber < 0 ? 0 : orderNumber;

          return (
            <CSSTransition
              nodeRef={ itemsRef.current[index] }
              timeout={ { enter: duration.enter + order * STAGGER, exit: duration.exit } }
              key={ property.id }
            >
              { (state) => {
                // console.log('State', state);
                return (
                  <PropertyListItemTransitioned
                    ref={ itemsRef.current[index] }
                    state={ state }
                    order={ order }
                    property={ property }
                    duration={ duration }
                    stagger={ STAGGER }
                    handleClick={ () => handleClickProperty(property.id) }
                  />
                );
              } }
            </CSSTransition>
          );
        }) }
      </TransitionGroup>
    </ul>
  );
};

export default PropertyListTransitioned;
