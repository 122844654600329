import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Start.scss';
import Sprite from '../../components/App/images/sprites/sprite.svg';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import './Start.scss';

function Start({ user, selectType }) {
  const [showBlocks, setShowBlocks] = useState(false);

  useEffect(() => {
    setShowBlocks(true);
  }, []);

  return (
    <div className="l-grid bg-solid">
      <div className="l-grid__content">
        <div className="l-grid__inner l-grid__inner_pb0">
          <div className="l-grid__block">
            <div className="l-grid__info">
              <div className="block-start">
                <CSSTransition
                  in={ showBlocks }
                  timeout={ 500 }
                  classNames="block-left-transition"
                  unmountOnExit
                >
                  <Link to={ '/select/' + selectType }>
                    <div className="block-start__item block-start__item-befaring">
                      <div className="block-start__box">
                        <svg width="74" height="64">
                          <use href={ Sprite + '#home' }/>
                        </svg>
                        <h1 className="block-start__title">
                          Befaring
                        </h1>
                      </div>
                    </div>
                  </Link>
                </CSSTransition>
                <CSSTransition
                  in={ showBlocks }
                  timeout={ 500 }
                  classNames="block-right-transition"
                  unmountOnExit
                >
                  <Link to={ `/select/${ selectType }?type=visning` }>
                    <div className="block-start__item block-start__item-visningsliste">
                      <div className="block-start__box">
                        <svg width="64" height="64">
                          <use href={ Sprite + '#visninge' }/>
                        </svg>
                        <h1 className="block-start__title">
                          Visningsliste
                        </h1>
                      </div>
                    </div>
                  </Link>
                </CSSTransition>
              </div>
              <CSSTransition
                in={ showBlocks }
                timeout={ 800 }
                classNames="bottom-button-transition"
                unmountOnExit
              >
                <div className="user-link">
                  <Link to="/user">{ user ? user.navn : '' }</Link>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user, selectType }) => {
  return { user, selectType };
};

export default connect(mapStateToProps)(Start);
