import React from 'react';

import './Marker.scss';

function Marker({ current, selected, label, onSelected }) {
  if (current) {
    return (
      <div className={ `marker current ${ selected ? 'selected' : '' }` } onClick={ onSelected }>
        <div className="marker__info-box">
          <div className="marker__label">{ label }</div>
        </div>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="13" cy="13" r="12.5" stroke={ selected ? '#323232' : '#868686' }/>
          <circle cx="13" cy="13" r="9" fill={ selected ? '#323232' : '#868686' }/>
        </svg>
      </div>
    );
  }

  return (
    <div className={ `marker ${ selected ? 'selected' : '' }` } onClick={ onSelected }>
      { selected ?
        <div className="marker__info-box">
          <div className="marker__label">{ label }</div>
        </div> : null }
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        { selected ? <circle cx="17" cy="17" r="16.5" stroke="#323232"/> : null }
        <path
          d="M17 5C12.5887 5 9 8.38731 9 13C9 14.2579 9.48628 15.8728 10 17C10.0103 17.0241 9.98842 16.9763 10 17L16 29C16.2219 29.4492 16.5166 30 17 30C17.4835 30 17.7782 29.4493 18 29L24 17C24.0104 16.9789 23.9907 17.0215 24 17C24.5171 15.8695 25 14.2619 25 13C25.0002 8.38731 21.4113 5 17 5Z"
          fill={ selected ? '#1198D2' : '#98CCDB' }/>
      </svg>
    </div>
  );
}

export default Marker;
