import React, { useState } from 'react';
import Modal from '../../../components/Modal';

const SelectBankModal = ({ banks = [], onClose, onConfirm }) => {
  const [selectedBankId, setSelectedBankId] = useState('');
  const [selectedBrokerId, setSelectedBrokerId] = useState('');

  return (
    <Modal centered onClose={ onClose } width={ 480 }>
      <div className="modal__wrapper text-center">
        <h3>Velg bank</h3>

        <div className="mt-3">
          <select
            value={ selectedBankId }
            onChange={ (e) => {
              setSelectedBankId(e.target.value);
            } }
            className="input-select input-select-block"
            id="bank-select"
          >
            <option value="" disabled>--- Vennligst velg bank ---</option>
            { banks.map(bank => (
              <option key={ bank.id } value={ bank.id }>{ bank.name }</option>
            )) }
          </select>
        </div>

        <div className="mt-3">
          <select
            disabled={ !selectedBankId }
            value={ selectedBrokerId }
            onChange={ (e) => {
              setSelectedBrokerId(e.target.value);
            } }
            className="input-select input-select-block"
            id="broker-select"
          >
            <option value="" disabled>--- Vennligst velg megler ---</option>
            { selectedBankId && banks.find(bank => bank.id === selectedBankId).brokers.map(broker => (
              <option key={ broker.id } value={ broker.id }>{ broker.user ? broker.user.navn : '' }</option>
            )) }
          </select>
        </div>

        <div className="mt-3">
          <button
            disabled={ !selectedBankId || !selectedBrokerId }
            className="button hover-dark"
            onClick={ () => onConfirm({ bankId: selectedBankId, brokerId: selectedBrokerId }) }
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectBankModal;
