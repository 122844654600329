import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import Tabbar from '../../components/Tabbar';
import Tjenester from './Tjenester';
import Salgsprosessen from './Salgsprosessen';
import Reklame from './Reklame';
import Boligannonsen from './Boligannonsen';
import Spleis from './Spleis';
import Annonsestrategi from './Annonsestrategi';

const OmOss = ({ user }) => {
  let { id } = useParams();

  const tabs = [
    { label: 'Tjenester', path: `/${ id }/om-oss/tjenester` },
    { label: 'Salgsprosessen', path: `/${ id }/om-oss/salgsprosessen` },
    { label: 'Markedsføring', path: `/${ id }/om-oss/reklame` },
    { label: 'Annonsestrategi', path: `/${ id }/om-oss/annonsestrategi` }
  ];

  // if (user && user.partner_ids && user.partner_ids.includes(1)) {
  //   tabs.push(
  //     { label: 'Boligannonsen', path: `/${ id }/om-oss/boligannonsen` },
  //     { label: 'Spleis!', path: `/${ id }/om-oss/spleis` }
  //   );
  // }

  return (
    <div className="l-grid">
      <div className="l-grid__content">
        <Tabbar count={ 4 } tabs={ tabs }/>
        <Switch>
          <Route path="/:id/om-oss/tjenester" component={ Tjenester }/>
          <Route path="/:id/om-oss/salgsprosessen" component={ Salgsprosessen }/>
          <Route path="/:id/om-oss/reklame" component={ Reklame }/>
          <Route path="/:id/om-oss/annonsestrategi" component={ Annonsestrategi }/>

          <Route path="/:id/om-oss/boligannonsen" component={ Boligannonsen }/>
          <Route path="/:id/om-oss/spleis" component={ Spleis }/>

          <Redirect from="/:id/om-oss" to="/:id/om-oss/tjenester"/>
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(OmOss);
