import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './Detaljer.scss';
import Map from '../../../components/Map';
import TileItem from '../../../components/TileItem';
import { fetchSelectedProperty, setPropertyMarkers, setDetailsMapCenter, setDetailsMapZoom } from '../../../actions';
import { compose } from '../../../utils';
import { withApiService } from '../../../components/HOC';
import AnimatedList from '../../../components/AnimatedList';
import { CSSTransition } from 'react-transition-group';
import EditModal from '../../../components/EditModal';

const Detaljer = (
  {
    selectedProperty,
    propertyDetails,
    detailsMapCenter,
    detailsMapZoom,
    fetchSelectedProperty,
    setPropertyMarkers,
    setDetailsMapCenter,
    setDetailsMapZoom
  }
) => {
  const [showMap, setShowMap] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalConfig, setEditModalConfig] = useState({
    label: '',
    param: '',
    value: ''
  });

  // useEffect(() => {
  //   setShowMap(!!selectedProperty);
  // }, [selectedProperty]);

  useEffect(() => {
    setShowMap(true);
  }, []);

  const fields = [
    {
      label: 'Prisantydning',
      key: 'prisantydning'
    },
    {
      label: 'Prisantydning',
      key: 'forventet_salgspris'
    },
    {
      label: 'Totalpris',
      key: 'totalpris'
    },
    {
      label: 'Type',
      key: 'type_eiendomstyper'
    },
    {
      label: 'Eierform',
      key: 'type_eierformbygninger'
    },
    {
      label: 'P-rom',
      key: 'prom'
    },
    {
      label: 'P-rom',
      key: 'forventet_hovedrom'
    },
    {
      label: 'BRA',
      key: 'bra'
    },
    {
      label: 'Byggeår',
      key: 'byggeaar'
    },
    {
      label: 'Soverom',
      key: 'soverom'
    },
    {
      label: 'Tomt',
      key: 'tomt'
    },
    {
      label: 'Oppdragsnummer',
      key: 'oppdragsnummer'
    }
  ];

  const items = propertyDetails && selectedProperty ?
    fields.filter(field => {
      if (field.key === 'totalpris') {
        return +selectedProperty[field.key] >= 10000;
      } else if (field.key === 'prisantydning') {
        return !(+selectedProperty['forventet_salgspris'] > 0);
      } else if (field.key === 'prom') {
        return !(+selectedProperty['forventet_hovedrom'] > 0);
      } else if (field.key === 'forventet_salgspris') {
        if (selectedProperty.id === propertyDetails.id) {
          return (+selectedProperty[field.key] > 0);
        } else {
          return false
        }
      } else if (field.key === 'forventet_hovedrom') {
        if (selectedProperty.id === propertyDetails.id) {
          return (+selectedProperty[field.key] > 0);
        } else {
          return false
        }
      } else {
        return selectedProperty[field.key] && selectedProperty[field.key] !== '0';
      }
    })
      .map(field => {
        return {
          label: field.label,
          param: field.key,
          value: selectedProperty[field.key]
        };
      }) : [];

  const handleMarkerSelected = (id) => {
    if (id && selectedProperty && id !== selectedProperty.id) {
      const newMarkers = propertyDetails.markers.map(marker => {
        marker.selected = marker.id === id;
        return marker;
      });
      setPropertyMarkers(newMarkers);
      fetchSelectedProperty(id);
    }
  };

  const handleResetMap = () => {
    setDetailsMapCenter({ lat: +propertyDetails.lat, lng: +propertyDetails.lng });
    setDetailsMapZoom(13);
  };

  const handleItemEditClick = (item) => {
    if (item.param === 'prisantydning') {
      setEditModalConfig({
        label: 'Prisantydning',
        param: 'forventet_salgspris',
        value: ''
      });
    } else if (item.param === 'prom') {
      setEditModalConfig({
        label: 'P-rom',
        param: 'forventet_hovedrom',
        value: ''
      });
    } else {
      setEditModalConfig(item);
    }
    setShowEditModal(true);
  };

  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info">
          <p className="block-adress">
            { selectedProperty ?
              <span>{ selectedProperty.adresse }, { selectedProperty.postnummer } { selectedProperty.poststed }</span>
              : <span>&nbsp;</span> }
          </p>
          <div className="l-grid__section l-grid__section_overflow l-grid__section_indents">
            <ul className="c-tile">
              <AnimatedList
                items={ items }
                itemKey="param"
                editable={ selectedProperty && propertyDetails && (selectedProperty.id === propertyDetails.id) }
                Item={ TileItem }
                onItemEdit={ handleItemEditClick }
              />
            </ul>
          </div>
        </div>
        <div className="l-grid__map">
          <CSSTransition
            in={ showMap }
            timeout={ 800 }
            classNames="map-transition"
            unmountOnExit
          >
            <Map
              center={ detailsMapCenter }
              zoom={ detailsMapZoom }
              onReset={ handleResetMap }
              places={ propertyDetails.markers }
              onMarkerSelected={ handleMarkerSelected }
              onChangeCenter={ (center) => setDetailsMapCenter(center) }
              onChangeZoom={ (zoom) => setDetailsMapZoom(zoom) }
            />
          </CSSTransition>
        </div>
      </div>

      <CSSTransition
        in={ showEditModal }
        timeout={ 300 }
        classNames="modal-transition"
        unmountOnExit
      >
        <EditModal config={ editModalConfig } onClose={ () => setShowEditModal(false) }/>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = ({ selectedProperty, propertyDetails, detailsMapCenter, detailsMapZoom }) => {
  return { selectedProperty, propertyDetails, detailsMapCenter, detailsMapZoom };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchSelectedProperty: fetchSelectedProperty(apiService, dispatch),
    setPropertyMarkers: (newMarkers) => {
      dispatch(setPropertyMarkers(newMarkers));
    },
    setDetailsMapCenter: (center) => {
      dispatch(setDetailsMapCenter(center));
    },
    setDetailsMapZoom: (zoom) => {
      dispatch(setDetailsMapZoom(zoom));
    }
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Detaljer);
