import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withApiService } from '../../components/HOC';
import { compose } from '../../utils';

const styles = {
  items: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '100%'
  },
  item: {
    textAlign: 'center'
  },
  linkTitle: {
    fontWeight: 500,
    fontSize: 22,
    marginTop: 8
  }
};

const Links = ({ user, apiService }) => {
  let { id } = useParams();

  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    setShowItems(true);
  }, []);

  const handleEiendomsverdiClick = (e) => {
    e.preventDefault();
    apiService.getEiendomsverdiSignedUrl()
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.signed_url) {
          window.open(res.data.data.signed_url, '_blank').focus();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="l-grid">
      <div className="l-grid__content">
        <div style={ styles.items }>

          <CSSTransition
            in={ showItems }
            timeout={ 300 }
            classNames="list-0-transition"
            unmountOnExit
          >
            <a href="https://www.finn.no/innsikt/" target="_blank" rel="noreferrer" style={ styles.item }>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 30 30" fill="#ffffff">
                  <path
                    d="M23.6245 13.7771H20.7673C20.5168 13.7771 20.3228 13.7771 20.3228 13.7771V28C20.3228 28 20.5168 28 20.7673 28H23.6245C23.875 28 24.0781 28 24.0781 28V13.7771C24.0781 13.7771 23.875 13.7771 23.6245 13.7771ZM29.4169 10.4059L27.2598 7.38997C27.1737 7.26962 26.9054 6.89458 26.9054 6.89458C26.9186 6.89828 26.6371 7.26962 26.551 7.38997L24.3939 10.4059C24.2851 10.5581 23.8395 11.2289 23.8395 11.2289C23.8395 11.2289 24.574 11.2289 24.7483 11.2289H25.0232V28C25.0232 28 25.2264 27.9999 25.4769 27.9999H28.334C28.5845 27.9999 28.7876 27.9999 28.7876 27.9999V11.229H29.0625C29.2368 11.229 30 11.229 30 11.229C30 11.229 29.5257 10.5581 29.4169 10.4059ZM18.1811 12.2739C18.5079 12.5384 17.94 12.0822 17.94 12.0822L19.3547 11.0001L20.3224 10.0001L10.6456 2L1.45222 9.50005L2.90341 11.5001L3.57385 11.9051V23.202C3.57385 23.202 4.77034 23.202 6.15636 23.202H10.8945V28C10.8945 28 11.0976 27.9999 11.3482 27.9999H14.2054C14.4559 27.9999 14.659 28 14.659 28V23.202H15.4263C15.4858 23.202 15.545 23.1993 15.6041 23.1946V28C15.6041 28 15.8072 27.9999 16.0577 27.9999H18.9149C19.1654 27.9999 19.3686 28 19.3686 28V17.2957C19.3686 17.2957 19.1654 17.2957 18.9149 17.2957H17.94V12.0822L18.1811 12.2739ZM10.8946 20.8142V22.1886H9.24824V14.4006C9.24824 14.4006 9.47942 14.4006 9.76362 14.4006H11.8191C12.1033 14.4006 12.3346 14.4006 12.3346 14.4006V20.8142H11.3482C11.0977 20.8142 10.8946 20.8142 10.8946 20.8142ZM17.0327 17.2957H16.0578C15.8073 17.2957 15.6042 17.2957 15.6042 17.2957V22.1762C15.5452 22.1835 15.486 22.1886 15.4264 22.1886H14.6591V20.8142C14.6591 20.8142 14.456 20.8142 14.2054 20.8142H13.2418V13.3871C13.2418 13.3871 12.6036 13.3871 11.8191 13.3871H9.76362C8.97918 13.3871 8.34098 13.3871 8.34098 13.3871V22.1886H6.15641C5.27064 22.1886 4.60973 22.1886 4.60973 22.1886V11.0814L10.6456 6.28206L17.0327 11.3608V17.2957Z"
                  />
                  <path
                    d="M21.0494 10.2706L16.844 6.92748L10.6456 2L0 10.5001L2.22246 13.0001L10.6456 6.28206L18.8712 13.0001L21.0494 10.2706Z"
                  />
                </svg>
              </div>
              <div style={ styles.linkTitle }>Finn innsikt</div>
            </a>
          </CSSTransition>

          { user && user.company_id === 1 ? (
            <CSSTransition
              in={ showItems }
              timeout={ 400 }
              classNames="list-1-transition"
              unmountOnExit
            >
              <a target="_blank" rel="noreferrer" href={ `https://partners.no/befaring/pdf/${ id }` }
                 style={ styles.item }>
                <div>
                  <svg width="92" height="92" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.902 16.4455L26.8508 12.3214H25.652C25.5099 12.3215 25.3736 12.2651 25.2731 12.1646C25.1727 12.0641 25.1162 11.9278 25.1163 11.7857V0.535723C25.116 0.24 24.8763 0.000292969 24.5806 0H19.2234C18.9277 0.000292969 18.688 0.239941 18.6877 0.535723V11.7857C18.6877 11.9278 18.6313 12.0641 18.5308 12.1646C18.4303 12.2651 18.294 12.3215 18.152 12.3214H16.9531L21.902 16.4455Z"
                      fill="white"/>
                    <path
                      d="M16.0098 24.1075V29.2428L21.6808 23.5718H16.5455C16.2497 23.5721 16.0101 23.8117 16.0098 24.1075Z"
                      fill="white"/>
                    <path
                      d="M21.9023 17.6785C21.7771 17.6786 21.6557 17.6347 21.5594 17.5545L15.1308 12.1974C14.9575 12.053 14.8931 11.8155 14.9699 11.6034C15.0467 11.3912 15.2481 11.2499 15.4738 11.25H17.6166V4.28564H3.68807C3.39234 4.28594 3.15264 4.52559 3.15234 4.82137V29.4642C3.15264 29.7599 3.39228 29.9996 3.68807 29.9999H14.9381V24.1071C14.9391 23.2199 15.658 22.5009 16.5452 22.4999H22.4381V17.3939L22.2453 17.5545C22.149 17.6347 22.0277 17.6786 21.9023 17.6785Z"
                      fill="white"/>
                    <path
                      d="M7.08923 16H6.85945H5V20.6142H5.87569V19.1238H6.90702L7.1098 19.1201C7.74059 19.1201 8.15992 19.0185 8.36729 18.8161C8.57466 18.6129 8.67812 18.2027 8.67812 17.5847C8.67812 16.9631 8.56964 16.5429 8.35217 16.3259C8.13476 16.108 7.71359 16 7.08923 16ZM7.62849 18.2494C7.52135 18.341 7.26959 18.3868 6.87316 18.3868H5.87569V16.737H6.96108C7.3351 16.737 7.56672 16.7837 7.65549 16.8771C7.74476 16.9705 7.78914 17.2122 7.78914 17.6022C7.78914 17.9427 7.73557 18.1579 7.62849 18.2494Z"
                      fill="#323232"/>
                    <path
                      d="M11.505 16H9.23633V20.6142H11.532C12.2598 20.6142 12.7281 20.4503 12.935 20.1226C13.1419 19.7949 13.2463 19.0542 13.2463 17.9024C13.2463 17.1975 13.1163 16.704 12.859 16.422C12.6009 16.1401 12.1495 16 11.505 16ZM12.1724 19.5879C12.0497 19.7811 11.7787 19.8772 11.3594 19.8772H10.112V16.737H11.4135C11.8213 16.737 12.0809 16.8285 12.1912 17.0134C12.3015 17.1984 12.3569 17.6314 12.3569 18.3117C12.3569 18.9699 12.2956 19.3957 12.1724 19.5879Z"
                      fill="#323232"/>
                    <path d="M13.8857 20.6142H14.7609V18.7173H16.83V17.9802H14.7609V16.737H16.9418V16H13.8857V20.6142Z"
                          fill="#323232"/>
                  </svg>
                </div>
                <div style={ styles.linkTitle }>Generer PDF</div>
              </a>
            </CSSTransition>
          ) : null }

          { user && user.department_ids && user.department_ids.includes(3000010) ? (
            <CSSTransition
              in={ showItems }
              timeout={ 500 }
              classNames="list-2-transition"
              unmountOnExit
            >
              <a target="_blank" rel="noreferrer" href="https://apps.fastout.com/DroneviewBankSearch.aspx"
                 style={ styles.item }>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 1000 1000" fill="#ffffff">
                    <g>
                      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                        <path
                          d="M4598.8,5000.4c-375.2-40.2-827-172.3-1198.4-352.2c-390.5-189.5-719.8-430.7-1045.2-771.5c-836.6-874.9-1179.2-2052.2-964.8-3309.9C1643-907.2,2692.1-2628.3,4351.9-4288c430.7-430.7,509.2-492,647-492c135.9,0,216.3,61.3,627.9,472.9C7749.8-2186,8829.5-53.4,8653.4,1673.3c-118.7,1175.4-779.1,2222.6-1788,2831.3C6204.9,4902.8,5387.5,5082.8,4598.8,5000.4z M5515.8,3740.8c851.9-193.4,1539.1-815.5,1816.7-1644.4c193.3-578.1,168.5-1188.8-74.7-1920.1c-319.7-961-997.4-2013.9-2008.1-3126.2c-130.2-143.6-243.1-260.3-248.9-260.3c-17.2,0-308.2,315.9-539.9,583.9C3624.4-1657.7,3029-689,2740,176.3c-291,871-269.9,1590.8,63.2,2253.2c373.3,742.8,1095,1248.2,1927.8,1355.4C4882.1,3804,5356.9,3777.2,5515.8,3740.8z"/>
                        <path
                          d="M4730.9,2540.5c-448-95.7-834.7-486.3-928.5-936.1C3620.6,735.3,4388.2-32.4,5257.3,149.5c551.3,114.9,966.8,629.8,966.8,1196.5C6224.1,2123.2,5496.6,2705.1,4730.9,2540.5z"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div style={ styles.linkTitle }>FastOut</div>
              </a>
            </CSSTransition>
          ) : null }

          <CSSTransition
            in={ showItems }
            timeout={ 600 }
            classNames="list-3-transition"
            unmountOnExit
          >
            { /* eslint-disable-next-line */ }
            <a href="#" style={ styles.item } onClick={ handleEiendomsverdiClick }>
              <div>
                <svg width="92" height="92" viewBox="0 0 95 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.9 30.1C32.7 30 32.5 29.9 32.3 29.9C32.6 29.9 32.8 30 32.9 30.1Z" fill="white"/>
                  <path
                    d="M94.1 0.899994H37.4C34.1 7.09999 32.4 16.5 32.3 26C32.7 26.1 33 26.2 33.4 26.3C37.2 27.6 39.8 31.3 39.8 35.3C39.8 38.5 38.2 41.4 35.6 43.2C35.2 43.5 34.7 43.7 34.3 43.9C35.1 47.2 36.1 50.2 37.4 52.7H94.1C88.8 40.2 88.8 13.3 94.1 0.899994V0.899994ZM55.7 44.8C50.5 44.8 46.2 40.5 46.2 35.3C46.2 30 50.5 25.8 55.7 25.8C60.9 25.8 65.2 30.1 65.2 35.3C65.2 40.6 60.9 44.8 55.7 44.8Z"
                    fill="white"/>
                  <path
                    d="M6.19999 29.4C2.99999 29.4 0.299988 32 0.299988 35.3C0.299988 38.5 2.89999 41.2 6.19999 41.2C9.39999 41.2 12.1 38.6 12.1 35.3C12 32.1 9.39999 29.4 6.19999 29.4V29.4Z"
                    fill="white"/>
                  <path
                    d="M30.3 29.4C27.1 29.4 24.4 32 24.4 35.3C24.4 38.5 27 41.2 30.3 41.2C33.5 41.2 36.2 38.6 36.2 35.3C36.1 32.1 33.5 29.4 30.3 29.4V29.4Z"
                    fill="white"/>
                  <path
                    d="M55.7 29.4C52.5 29.4 49.8 32 49.8 35.3C49.8 38.5 52.4 41.2 55.7 41.2C58.9 41.2 61.6 38.6 61.6 35.3C61.6 32.1 58.9 29.4 55.7 29.4V29.4Z"
                    fill="white"/>
                </svg>
              </div>
              <div style={ styles.linkTitle }>Eiendomsverdi</div>
            </a>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default compose(
  withApiService(),
  connect(mapStateToProps)
)(Links);
