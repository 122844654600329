import React from 'react';
import Sprite from '../App/images/sprites/sprite.svg';
import './BackButton.scss';

const BackButton = ({ onClick }) => {
  return (
    <div className="back-button-absolute" onClick={ onClick }>
      <svg width="15" height="15">
        <use href={ Sprite + '#back' }/>
      </svg>
      Tilbake
    </div>
  );
};

export default BackButton;
