import React, { Component } from 'react';
import Modal from '../../../components/Modal';

import './EventModal.scss';
import { connect } from 'react-redux';
import { fetchCalendar } from '../../../actions';
import { compose } from '../../../utils';
import { withApiService } from '../../HOC';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';

dayjs.locale('nb');

class EventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [
        { id: 1, name: 'Stylistbefaring', checked: false },
        { id: 2, name: 'Avisannonsering', checked: false },
        { id: 3, name: 'Styling', checked: false },
        { id: 4, name: 'Fotografering', checked: false },
        { id: 5, name: 'Visning', checked: false },
        { id: 6, name: 'Kveldsfoto', checked: false },
        { id: 7, name: 'Takstmann', checked: false },
        { id: 8, name: 'Digital markedsføring', checked: false },
        { id: 9, name: 'Meglerbefaring før foto', checked: false },
        { id: 10, name: 'Publisering på finn.no og partners.no', checked: false },
        { id: 11, name: 'Meglerbefaring før visning', checked: false },
      ],
      annet: { value: '', checked: false },
      comment: ''
    };

    this.annetInput = React.createRef();
  }

  componentDidMount() {
    const { calendar, date } = this.props;
    const calendarItem = calendar && calendar.items ? calendar.items.find(item => item.date === date) : null;
    if (calendarItem) {
      this.setState(state => {
        const annet = { value: '', checked: false };
        const stateEvents = state.events;
        calendarItem.events.forEach(item => {
          const eventToCheck = state.events.find(event => event.name === item);
          if (eventToCheck) {
            eventToCheck.checked = true;
          }
          if (item.includes('Annet: ')) {
            annet.value = item.slice(7);
            annet.checked = true;
          }
        });
        return {
          events: stateEvents,
          annet: annet,
          comment: calendarItem.comment ? calendarItem.comment : ''
        };
      });
    }
  }

  toggleProperty(arr, id, propName) {
    const idx = arr.findIndex((el) => el.id === id);

    // update object
    const oldItem = arr[idx];
    const newItem = { ...oldItem, [propName]: !oldItem[propName] };

    // construct new array
    return [
      ...arr.slice(0, idx),
      newItem,
      ...arr.slice(idx + 1)
    ];
  }

  onToggleChecked = (id) => {
    this.setState(({ events }) => {
      return {
        events: this.toggleProperty(events, id, 'checked')
      };
    });
  };

  onToggleAnnet = (event) => {
    this.setState(({ annet }) => {
      return {
        annet: { value: annet.value, checked: event.target.checked }
      };
    });
    if (event.target.checked === true) {
      setTimeout(() => {
        this.annetInput.current.focus();
      }, 200);
    }
  };

  submitEvents = () => {
    const { apiService, fetchCalendar, calendar, onClose } = this.props;
    const events = this.state.events.filter(event => event.checked).map(event => event.name);
    if (this.state.annet.checked && this.state.annet.value.length) {
      events.push('Annet: ' + this.state.annet.value);
    }
    const newCalendarItem = {
      date: this.props.date,
      events: events,
      comment: this.state.comment
    };

    apiService.addToCalendar(calendar.id, newCalendarItem).then(() => {
      fetchCalendar(calendar.id);
      onClose();
    });
  };

  handleChangeAnnet = (event) => {
    this.setState(({ annet }) => {
      return {
        annet: { value: event.target.value, checked: annet.checked }
      };
    });
  };

  handleChangeComment = (event) => {
    this.setState({ comment: event.target.value });
  };

  render() {
    const { onClose, date } = this.props;
    const { events, annet } = this.state;

    return (
      <Modal onClose={ onClose }>

        <div className="modal__wrapper">
          <p className="modal__title text-capitalize">
            { dayjs(date).format('D MMMM YYYY') }
          </p>
          <div className="modal__box">
            { events.map(event => (
              <div key={ event.id }>
                <input
                  className="modal__input"
                  type="checkbox"
                  id={ 'event-' + event.id }
                  name={ event.name }
                  checked={ event.checked }
                  onChange={ () => this.onToggleChecked(event.id) }
                />
                <label className="modal__label" htmlFor={ 'event-' + event.id }>{ event.name }</label>
              </div>
            )) }
            <div>
              <input
                className="modal__input"
                type="checkbox"
                id={ 'event-annet' }
                name={ 'Annet' }
                checked={ annet.checked }
                onChange={ this.onToggleAnnet }
              />
              <label className="modal__label" htmlFor={ 'event-annet' }>Annet</label>
            </div>
          </div>

          { this.state.annet.checked ?
            (
              <div style={ { paddingTop: 15 } }>
                <input
                  style={ {
                    width: '100%',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    padding: 10
                  } }
                  ref={ this.annetInput }
                  type="text"
                  value={ annet.value }
                  onChange={ this.handleChangeAnnet }
                  placeholder="Annet"
                />
              </div>
            ) : null
          }

          <div className="modal__area-parent">
            <textarea value={ this.state.comment }
                      onChange={ this.handleChangeComment }
                      className="modal__area"
                      placeholder="Kommentarer"/>
          </div>

        </div>

        <div className="modal__inner">
          <div className="modal__box-btns">
            <button className="button hover-dark button-default" onClick={ () => this.submitEvents() }>
              Lagre
            </button>
          </div>
        </div>

      </Modal>
    );
  }
}

const mapStateToProps = ({ calendar }) => {
  return { calendar };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchCalendar: fetchCalendar(apiService, dispatch)
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(EventModal);
