import React from 'react';
import './PartnersPreloader.scss';

const PartnersPreloader = () => {
  return (
    <div className="partners-preloader animate">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40.7087 42.514L46.7335 36.7147H39.3266L37.6011 38.3916L30.1652 28.083L34.8633 22.7642L35.0444 22.5723C35.5879 21.9497 36.0377 21.2512 36.3794 20.4987H36.3887C36.742 19.6619 36.9323 18.7653 36.9494 17.8571C36.9494 12.8776 32.9025 9.26367 27.325 9.26367C21.7197 9.26367 17.6526 12.8776 17.6526 17.8571C17.6658 19.3194 18.1118 20.7451 18.9344 21.9542L19.0719 22.1233L19.0461 22.1368L22.9337 27.2921L19.0378 30.9904C14.3004 34.968 14.3397 38.9859 14.3527 40.3085C14.3527 40.3907 14.5895 48.543 25.8561 49.4476C30.1311 49.7864 34.3389 48.5658 37.2354 46.2666L38.3423 47.7351L38.3294 47.7526L39.6892 49.5868H46.3937L43.2711 45.9108L40.7087 42.514ZM27.3245 13.7037C30.4487 13.7037 31.5561 15.9407 31.5561 17.8576C31.5522 18.0781 31.5285 18.2978 31.4852 18.514C31.204 19.2039 30.7833 19.8283 30.2495 20.3482L27.2169 23.8039L24.2242 20.6487L23.7018 19.9018C23.2772 19.3055 23.0471 18.5927 23.0428 17.8607C23.0428 15.9423 24.1652 13.7052 27.3245 13.7052V13.7037ZM26.0061 44.6533C23.0464 44.4159 21.2506 43.563 20.2456 41.4464C20.1627 41.25 20.0911 41.0489 20.0309 40.8444C19.9805 40.6434 19.9448 40.4391 19.9239 40.233C19.9239 38.224 20.8078 36.8394 22.0533 35.4387L26.4685 31.5418L33.9287 41.9621C32.2699 43.8128 29.1183 44.9094 26.0061 44.6549V44.6533Z"
          fill="white"/>
      </svg>
      <span/>
      <span/>
      <span/>
      <span/>
    </div>
  );
};

export default PartnersPreloader;
