import React from 'react';
import Modal from '../Modal';

const InfoModal = ({ onClose, success, message, centered }) => {
  return (
    <Modal centered={ centered } onClose={ onClose } width={ 480 }>
      <div className="modal__wrapper text-center">
        <h3>{success ? 'Suksess' : 'Feil'}</h3>
        <p className="mt-3">{ message }</p>
        <div className="mt-3">
          <button className="button hover-dark" onClick={ onClose }>OK</button>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
