import React from 'react';
import './Modal.scss';

import Sprite from '../../components/App/images/sprites/sprite.svg';

const Modal = ({ onClose, width, className, children, centered }) => {
  return (
    <div className={ centered ? 'modal-backdrop modal-centered' : 'modal-backdrop' }>
      <div
        className={ className ? 'modal ' + className : 'modal' }
        style={ width ? { width: `${ width }px` } : null }
      >
        <div className="modal__main">
          <button className="modal__close modal__close_svg-color is-clickable" onClick={ onClose }>
            <svg width="19" height="21">
              <use href={ Sprite + '#modal-close' }/>
            </svg>
          </button>
          <div className="modal__content">{ children }</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
