import React from 'react';
import './InputLoader.scss';

const InputLoader = () => {
  return (
    <div className="input-loader">Loading...</div>
  );
};

export default InputLoader;
