import React, { Component } from 'react';
import { fetchSearchProperties, setSearchPropertiesQuery } from '../../../actions';
import { compose } from '../../../utils';
import { withRouter } from 'react-router-dom';
import { withApiService } from '../../../components/HOC';
import { connect } from 'react-redux';
import PropertyListTransitioned from '../../../components/PropertyListTransitioned';

class Sok extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.contentRef = React.createRef();
    this.state = {
      searchFocused: false
    };
  }

  onScroll = () => {
    const { searchProperties, fetchSearchProperties, location } = this.props;
    // const scrollY = window.scrollY; // Don't get confused by what's scrolling - It's not the window
    const containerScrollTop = this.containerRef.current.scrollTop;
    const containerHeight = this.containerRef.current.clientHeight;
    const contentHeight = this.contentRef.current.clientHeight + 45; // 45 is searchbar height
    // console.log(`onScroll, containerRef.scrollTop: ${ containerScrollTop }, containerRef.height: ${ containerHeight }, contentRef.height: ${ contentHeight }`);
    if (containerHeight + containerScrollTop >= contentHeight) {
      // fetch next page if needed
      if (searchProperties.pagesCount > searchProperties.currentPage) {
        const urlQuery = new URLSearchParams(location.search);
        fetchSearchProperties(searchProperties.query, urlQuery.get('type'), searchProperties.currentPage + 1);
      }
    }
  };

  handleChangeQuery = (event) => {
    const { setSearchPropertiesQuery, fetchSearchProperties, location } = this.props;
    const query = event.target.value;
    setSearchPropertiesQuery(query);
    if (query.length > 2) {
      const urlQuery = new URLSearchParams(location.search);
      fetchSearchProperties(query, urlQuery.get('type'));
    }
  };

  componentDidMount() {
    const { searchProperties, fetchSearchProperties, location } = this.props;
    if (searchProperties.query && searchProperties.query.length > 2) {
      const urlQuery = new URLSearchParams(location.search);
      fetchSearchProperties(searchProperties.query, urlQuery.get('type'));
    }
  }

  render() {
    const { searchProperties, location } = this.props;
    const query = new URLSearchParams(location.search);

    return (
      <div ref={ this.containerRef } onScroll={ this.onScroll }
           className="l-grid__section l-grid__section_overflow  l-grid__section_mt45">
        <div
          className={ `search-row search-row_icon search-row_mwidth search-row_mauto ${ searchProperties.query ? 'is-active' : '' }` }>
          <input type="search"
                 id="search-input"
                 value={ searchProperties.query }
                 onChange={ this.handleChangeQuery }
                 onFocus={ () => this.setState({ searchFocused: true }) }
                 onBlur={ () => this.setState({ searchFocused: false }) }
                 className={ `search-row__item ${ searchProperties.query ? 'is-active' : '' } ` }/>
          <label htmlFor="search-input"
                 className={ `search-row__label ${ this.state.searchFocused || searchProperties.query ? '' : 'search-row__label_top15' }` }>
            Søk etter adresse eller sted
          </label>
        </div>
        <div ref={ this.contentRef } className="c-property">
          <PropertyListTransitioned
            visning={ query.get('type') === 'visning' }
            properties={ searchProperties.list }
            page={ searchProperties.currentPage }
            query={ searchProperties.query }/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ searchProperties, loading, error }) => {
  return { searchProperties, loading, error };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchSearchProperties: fetchSearchProperties(apiService, dispatch),
    setSearchPropertiesQuery: (query) => {
      dispatch(setSearchPropertiesQuery(query));
    }
  };
};

export default compose(
  withRouter,
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Sok);
