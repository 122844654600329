import React from 'react';
import Modal from '../../../components/Modal';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import './PreviewEmailModal.scss';
import './PreviewEmail.scss';
import { templateBuilder } from '../../../utils/templateBuilder';

dayjs.locale('nb');

const PreviewEmailModal = (
  {
    onClose,
    onEmail,
    onSend,
    calendar,
    customRecipientSelected,
    customRecipient,
    emailText,
    propertyDetails,
    assignmentAgreementSelected
  }) => {
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  const selectedDocuments = calendar.documents.filter(document => document.selected);
  const calendarItems = calendar && calendar.items && calendar.items.length ?
    calendar.items.filter(item => !dayjs(item.date).isBefore(today) && ((item.events && item.events.length) || item.comment)) : null;
  const template = templateBuilder({
    broker: propertyDetails ? propertyDetails.broker : undefined,
    department: propertyDetails && propertyDetails.broker ? propertyDetails.broker.department : undefined,
    images: propertyDetails ? propertyDetails.images.filter(image => image.urlstorthumbnail) : undefined,
    address: propertyDetails ? propertyDetails.adresse + ', ' + propertyDetails.postnummer + ' ' + propertyDetails.poststed : undefined,
    seller: calendar.recipients ? calendar.recipients.find(recipient => recipient.type === 'selger') : undefined,
    documents: selectedDocuments && selectedDocuments.length ? selectedDocuments : null,
    assignmentAgreement: assignmentAgreementSelected && propertyDetails.oppdragsavtale ? propertyDetails.oppdragsavtale.url : null,
    emailText,
    calendarItems
  });

  const selectedRecipients = calendar.recipients.filter(recipient => recipient.selected);

  return (
    <Modal onClose={ onClose } className="modal-fullscreen">
      <div className="modal__wrapper modal__wrapper_p30">
        <div className="preview-email" dangerouslySetInnerHTML={ { __html: template } }/>
      </div>
      <div className="modal__inner">
        <div className="modal__section">
          { selectedDocuments.length || assignmentAgreementSelected ? <>
              <h3 style={ { fontSize: 20, marginBottom: 20 } }>Dokumenter</h3>
              <ul className="modal__section-list" style={ { marginBottom: 30 } }>
                {
                  selectedDocuments.map(document => {
                    return (
                      <li key={ document.name } className="modal__section-item">{ document.name }</li>
                    );
                  })
                }
                { assignmentAgreementSelected ? (
                  <li className="modal__section-item">Oppdragsavtale</li>
                ) : null }
              </ul>
            </>
            : null
          }

          { selectedRecipients.length || (customRecipientSelected && customRecipient) ? <>
            <h3 style={ { fontSize: 20, marginBottom: 20 } }>Mottakere</h3>
            <ul className="modal__section-list">
              {
                selectedRecipients.map(recipient => {
                  return (
                    <li key={ recipient.email } className="modal__section-item">{ recipient.email }</li>
                  );
                })
              }
              { customRecipientSelected ? customRecipient : null }
            </ul>
          </> : null
          }
        </div>
        <div className="modal__box-btns">
          <button
            className="button hover-dark button-default button-default_padding"
            style={ { width: '50%' } }
            onClick={ onEmail }>
            Tilbake
          </button>
          <button
            className="button hover-dark button-default button-default_padding button-default_ml20"
            style={ { width: '50%' } }
            onClick={ () => onSend(template) }
            disabled={ !calendar.recipients.some(r => r.selected) && (!customRecipientSelected || !customRecipient) }
          >
            Send epost
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (
  {
    assignmentAgreementSelected,
    calendar,
    user,
    emailText,
    propertyDetails,
    customRecipient,
    customRecipientSelected
  }
) => {
  return {
    assignmentAgreementSelected,
    calendar,
    user,
    emailText,
    propertyDetails,
    customRecipient,
    customRecipientSelected
  };
};

export default connect(mapStateToProps)(PreviewEmailModal);
