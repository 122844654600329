import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { compose, formatter, mapConfig } from '../../../utils';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';

import Sprite from '../../../components/App/images/sprites/sprite.svg';

import './Omrade.scss';
import { withApiService } from '../../../components/HOC';
import { connect } from 'react-redux';
import { setClientsParams, fetchClients, setMapPlacesQuery } from '../../../actions';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

let circle, mapInstance, googleMaps;

class Omrade extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.sliderRefs = {
      salgssum: React.createRef(),
      registrert: React.createRef(),
      primaerrom: React.createRef()
    };
    this.state = { showMap: false };
  }

  componentDidMount() {
    const { clients, clientsParams, propertyDetails, setMapPlacesQuery } = this.props;

    this.setState({
      showMap: true
    });

    if (clients.totalCount === 0) {
      setMapPlacesQuery(propertyDetails.adresse + ', ' + propertyDetails.postnummer + ' ' + propertyDetails.poststed);

      const newParams = {
        ...clientsParams,
        center: {
          lat: +propertyDetails.lat,
          lng: +propertyDetails.lng
        }
      };
      this.updateClients(newParams);
    }
  }

  updateClients = (newParams) => {
    const { setClientsParams, fetchClients } = this.props;
    setClientsParams(newParams);
    fetchClients(newParams);
  };

  handleChangeSlider = (param, values) => {
    const { clientsParams } = this.props;
    const newParams = { ...clientsParams };
    if (param === 'avstand') {
      const valueString = values[0];
      const radius = valueString.includes('km') ?
        parseInt(valueString, 10) * 1000 : parseInt(valueString, 10);
      newParams.avstand = radius;
      if (mapInstance && circle) {
        circle.setRadius(radius);
        mapInstance.fitBounds(circle.getBounds());
        const decreasedZoom = mapInstance.getZoom() - 1;
        mapInstance.setZoom(decreasedZoom);
      }
    } else {
      const fromString = values[0].replace(/\s/g, '');
      const toString = values[1] === '10M+' ? '10000000' : values[1].replace(/\s/g, '');
      newParams[param] = {
        from: parseInt(fromString, 10),
        to: parseInt(toString, 10)
      };
    }
    this.updateClients(newParams);
  };

  sliders = {
    salgssum: {
      tooltips: null,
      origins: null
    },
    registrert: {
      tooltips: null,
      origins: null
    },
    primaerrom: {
      tooltips: null,
      origins: null
    }
  };

  mergeTooltips = (sliderName, threshold, values, positions) => {
    if (!this.sliders[sliderName].tooltips || !this.sliders[sliderName].origins) {
      this.sliders[sliderName].tooltips = this.sliderRefs[sliderName].current.noUiSlider.getTooltips();
      this.sliders[sliderName].origins = this.sliderRefs[sliderName].current.noUiSlider.getOrigins();
    }

    if (this.sliders[sliderName].tooltips && this.sliders[sliderName].origins) {
      const separator = ' - ';
      const tooltips = this.sliders[sliderName].tooltips;
      const origins = this.sliders[sliderName].origins;

      // Move tooltips into the origin element. The default stylesheet handles this.
      tooltips.forEach(function (tooltip, index) {
        if (tooltip) {
          origins[index].appendChild(tooltip);
        }
      });

      let pools = [[]];
      let poolPositions = [[]];
      let poolValues = [[]];
      let atPool = 0;

      // Assign the first tooltip to the first pool, if the tooltip is configured
      if (tooltips[0]) {
        pools[0][0] = 0;
        poolPositions[0][0] = positions[0];
        poolValues[0][0] = values[0];
      }

      for (let i = 1; i < positions.length; i++) {
        if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
          atPool++;
          pools[atPool] = [];
          poolValues[atPool] = [];
          poolPositions[atPool] = [];
        }

        if (tooltips[i]) {
          pools[atPool].push(i);
          poolValues[atPool].push(values[i]);
          poolPositions[atPool].push(positions[i]);
        }
      }

      pools.forEach(function (pool, poolIndex) {
        let handlesInPool = pool.length;

        for (let j = 0; j < handlesInPool; j++) {
          let handleNumber = pool[j];

          if (j === handlesInPool - 1) {
            let offset = 0;

            poolPositions[poolIndex].forEach(function (value) {
              offset += 1000 - 10 * value;
            });

            let direction = 'right';
            let last = handlesInPool - 1;
            let lastOffset = 1000 - 10 * poolPositions[poolIndex][last];
            offset = (offset / handlesInPool) - lastOffset;

            // Center this tooltip over the affected handles
            tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator);
            tooltips[handleNumber].style.display = 'block';
            tooltips[handleNumber].style[direction] = offset + '%';
          } else {
            // Hide this tooltip
            tooltips[handleNumber].style.display = 'none';
          }
        }
      });
    }
  };

  toggleCheckbox = (param, checkbox) => {
    const { clientsParams } = this.props;
    const newParams = { ...clientsParams };
    const newParamsItem = [...newParams[param]];

    const checkboxIndex = newParamsItem.indexOf(checkbox);
    if (checkboxIndex > -1) {
      newParamsItem.splice(checkboxIndex, 1);
    } else {
      newParamsItem.push(checkbox);
    }

    newParams[param] = newParamsItem;
    this.updateClients(newParams);
  };

  onCircleDragged = ({ latLng }) => {
    const { clientsParams } = this.props;
    const newParams = {
      ...clientsParams,
      center: {
        lat: latLng.lat(),
        lng: latLng.lng()
      }
    };
    this.updateClients(newParams);
  };

  mapApiLoaded = ({ map, maps }) => {
    googleMaps = maps;
    mapInstance = map;

    // init places search box
    this.searchBox = new googleMaps.places.Autocomplete(this.inputRef.current, {
      componentRestrictions: { country: 'no' }
    });
    this.searchBox.addListener('place_changed', this.onPlaceChanged);

    // init Draggable Circle
    circle = new maps.Circle({
      strokeColor: '#000000',
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: '#323232',
      fillOpacity: 0.3,
      map: map,
      center: this.props.clientsParams.center,
      radius: this.props.clientsParams.avstand,
      draggable: true
    });
    mapInstance.fitBounds(circle.getBounds());
    const decreasedZoom = mapInstance.getZoom() - 1;
    mapInstance.setZoom(decreasedZoom);
    googleMaps.event.addListener(circle, 'dragend', this.onCircleDragged);
  };

  componentWillUnmount() {
    // https://developers.google.com/maps/documentation/javascript/events#removing
    googleMaps.event.clearInstanceListeners(this.searchBox);
    googleMaps.event.clearInstanceListeners(circle);
  }

  onPlaceChanged = () => {
    const place = this.searchBox.getPlace();
    if (place && place.geometry) {
      // console.log(place);
      const name = place.name;
      const address = place['formatted_address'];
      const query = address.includes(name) ? address : name + ', ' + address;
      this.props.setMapPlacesQuery(query);

      const center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      circle.setCenter(center);
      mapInstance.fitBounds(circle.getBounds());
      const decreasedZoom = mapInstance.getZoom() - 1;
      mapInstance.setZoom(decreasedZoom);
      const { clientsParams } = this.props;
      const newParams = {
        ...clientsParams,
        center: center
      };
      this.updateClients(newParams);
    }
  };

  onMapDragged = ({ center }) => {
    circle.setCenter(center);
    const { clientsParams } = this.props;
    const newParams = {
      ...clientsParams,
      center: {
        lat: center.lat(),
        lng: center.lng()
      }
    };
    this.updateClients(newParams);
  };

  handleChangeQuery = (event) => {
    this.props.setMapPlacesQuery(event.target.value);
  };

  render() {
    const { clientsParams, clients, propertyDetails, mapPlacesQuery } = this.props;

    const createMapOptions = function () {
      return {
        styles: mapConfig.styles
      };
    };

    if (!clientsParams) {
      return null;
    }

    return (
      <div className="l-grid__content">
        <Link to={ `/${ propertyDetails.id }/mulige-kjopere/liste` } className="clients-counter">
          <div>
            Mulige kjøpere:
          </div>
          <div className="clients-counter-total">
            { formatter.format(clients.totalCount) }
          </div>
        </Link>
        <div className="l-grid__inner">
          <div className="l-grid__block">
            <div className="l-grid__map l-grid__map_flex-column l-grid__map_ml0 l-grid__map_w55">
              <CSSTransition
                in={ this.state.showMap }
                timeout={ 300 }
                classNames="list-0-transition"
              >
                <div className="block-row-item">
                  <div className="search-row">
                    <input ref={ this.inputRef }
                           value={ mapPlacesQuery }
                           onChange={ this.handleChangeQuery }
                           type="search" className="search-row__item"
                           id="search-input"
                           placeholder=""/>
                    <label htmlFor="search-input" className="search-row__label">Søk etter adresse eller sted</label>
                  </div>
                </div>
              </CSSTransition>
              <CSSTransition
                in={ this.state.showMap }
                timeout={ 800 }
                classNames="map-transition"
              >
                <div className="map-wrapper">
                  <GoogleMapReact
                    options={ createMapOptions }
                    bootstrapURLKeys={ mapConfig.urlKeys }
                    defaultCenter={ { lat: +propertyDetails.lat, lng: +propertyDetails.lng } }
                    center={ clientsParams.center }
                    zoom={ 11 }
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={ this.mapApiLoaded }
                    onDragEnd={ this.onMapDragged }
                  />
                </div>
              </CSSTransition>
              <CSSTransition
                in={ this.state.showMap }
                timeout={ 900 }
                classNames="list-6-transition"
              >
                <div className="block-row-item">
                  <div className="c-filter__range">
                    <Nouislider
                      range={
                        {
                          min: 100,
                          '6,25%': 200,
                          '12,5%': 300,
                          '18,75%': 400,
                          '25%': 500,
                          '31,25%': 1000,
                          '37,5%': 2000,
                          '43,75%': 3000,
                          '50%': 5000,
                          '56,25%': 10000,
                          '62,5%': 15000,
                          '68,75%': 20000,
                          '75%': 30000,
                          '81,25%': 40000,
                          '87,5%': 50000,
                          '93,75%': 100000,
                          max: 200000,
                        }
                      }
                      start={ [clientsParams.avstand] }
                      tooltips={ [true] }
                      connect
                      snap
                      format={
                        {
                          to: (value) => value < 1000 ? value + ' m' : value / 1000 + ' km',
                          from: (value) => value.replace(',-', '')
                        }
                      }
                      onChange={ (values) => this.handleChangeSlider('avstand', values) }
                    />
                    <span className="c-filter__desc">Avstand</span>
                  </div>
                </div>
              </CSSTransition>
            </div>
            <div className="l-grid__info">
              <div className="l-grid__section l-grid__section_overflow l-grid__section_mt0">
                <div className="c-filter">
                  <CSSTransition
                    in={ this.state.showMap }
                    timeout={ 400 }
                    classNames="list-1-transition"
                  >
                    <div className="c-filter__row">
                      <p className="c-filter__name">
                        Leadstype
                      </p>
                      <div className="c-filter__box">
                        {
                          ['Budvarsel', 'Salgsoppgave', 'Salgssum', 'Salgssum_landing', 'Salgssum_venter', 'Visningliste', 'Boligvarsling'].map(checkbox => {
                            return (
                              <div className="checkbox-row checkbox-row_w-auto checkbox-row_mr6" key={ checkbox }>
                                <input type="checkbox" id={ checkbox }
                                       className="checkbox-row__item checkbox-row__item_hidden" name={ checkbox }
                                       checked={ clientsParams.leadstype.includes(checkbox) }
                                       onChange={ () => this.toggleCheckbox('leadstype', checkbox) }/>
                                <label htmlFor={ checkbox } className="checkbox-row__label checkbox-row__label_whole">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  { checkbox.replace('_', ' ') }
                                </label>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={ this.state.showMap }
                    timeout={ 500 }
                    classNames="list-2-transition"
                  >
                    <div className="c-filter__row">
                      <p className="c-filter__name">
                        Eiendomstype
                      </p>
                      <div className="c-filter__box">
                        {
                          ['Leilighet', 'Enebolig', 'Tomannsbolig', 'Rekkehus', 'Hytte', 'Tomt', 'Andre'].map(checkbox => {
                            return (
                              <div className="checkbox-row checkbox-row_w-auto checkbox-row_mr6" key={ checkbox }>
                                <input type="checkbox" id={ checkbox }
                                       className="checkbox-row__item checkbox-row__item_hidden" name={ checkbox }
                                       checked={ clientsParams.eiendomstype.includes(checkbox) }
                                       onChange={ () => this.toggleCheckbox('eiendomstype', checkbox) }/>
                                <label htmlFor={ checkbox } className="checkbox-row__label checkbox-row__label_whole">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  { checkbox }
                                </label>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={ this.state.showMap }
                    timeout={ 600 }
                    classNames="list-3-transition"
                  >
                    <div className="c-filter__row c-filter__row_mb35">
                      <p className="c-filter__name">
                        Registrert
                      </p>
                      <div className="c-filter__range c-filter__range_short">
                        <Nouislider
                          className="noUI-merged-tooltips noUI-smooth-steps"
                          instanceRef={ this.sliderRefs.registrert }
                          range={ { min: 0, max: 12 } }
                          start={ [clientsParams.registrert.from, clientsParams.registrert.to] }
                          tooltips={ [true, true] }
                          step={ 1 }
                          connect
                          format={
                            {
                              to: (value) => {
                                if (value === 0) {
                                  return '0';
                                } else {
                                  return value.toFixed(0) + ' mnd';
                                }
                              },
                              from: (value) => value.replace(',-', '')
                            }
                          }
                          onChange={ (values) => this.handleChangeSlider('registrert', values) }
                          onUpdate={ (values, handle, unencoded, tap, positions) => {
                            this.mergeTooltips('registrert', 15, values, positions);
                          } }
                        />
                      </div>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={ this.state.showMap }
                    timeout={ 700 }
                    classNames="list-4-transition"
                  >
                    <div className="c-filter__row c-filter__range_short c-filter__row_mb35">
                      <p className="c-filter__name">
                        Salgssum
                      </p>
                      <div className="c-filter__range">
                        <Nouislider
                          className="noUI-merged-tooltips"
                          instanceRef={ this.sliderRefs.salgssum }
                          range={ { min: 0, max: 10000000 } }
                          start={ [clientsParams.salgssum.from, clientsParams.salgssum.to] }
                          tooltips={ [true, true] }
                          step={ 100000 }
                          connect
                          format={
                            {
                              to: (value) => value === 10000000 ? '10M+' : formatter.format(value),
                              from: (value) => value.replace(',-', '')
                            }
                          }
                          onChange={ (values) => this.handleChangeSlider('salgssum', values) }
                          onUpdate={ (values, handle, unencoded, tap, positions) => {
                            this.mergeTooltips('salgssum', 22, values, positions);
                          } }
                        />
                      </div>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={ this.state.showMap }
                    timeout={ 800 }
                    classNames="list-5-transition"
                  >
                    <div className="c-filter__row c-filter__range_short c-filter__row_mb35">
                      <p className="c-filter__name">
                        Primærrom
                      </p>
                      <div className="c-filter__range">
                        <Nouislider
                          className="noUI-merged-tooltips"
                          instanceRef={ this.sliderRefs.primaerrom }
                          range={ { min: 0, max: 300 } }
                          start={ [clientsParams.primaerrom.from, clientsParams.primaerrom.to] }
                          tooltips={ [true, true] }
                          step={ 10 }
                          connect
                          format={
                            {
                              to: (value) => {
                                if (value === 300) {
                                  return value + '+ m²';
                                } else if (value === 0) {
                                  return '0';
                                } else {
                                  return value.toFixed(0) + ' m²';
                                }
                              },
                              from: (value) => value.replace(',-', '')
                            }
                          }
                          onChange={ (values) => this.handleChangeSlider('primaerrom', values) }
                          onUpdate={ (values, handle, unencoded, tap, positions) => {
                            this.mergeTooltips('primaerrom', 15, values, positions);
                          } }
                        />
                      </div>
                    </div>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// document.addEventListener('DOMContentLoaded', () => {
//   let search = document.getElementById('search-input');
//   if (search) {
//     search.addEventListener('blur', function () {
//       if (this.value !== '') {
//         this.classList.add('is-active');
//       } else {
//         this.classList.remove('is-active');
//         this.closest('.search-row').classList.remove('is-active');
//         this.nextElementSibling.classList.add('search-row__label_top15');
//       }
//     });
//     search.addEventListener('focus', function () {
//       this.closest('.search-row').classList.add('is-active');
//       this.nextElementSibling.classList.remove('search-row__label_top15');
//     });
//   }
// });

const mapStateToProps = ({ propertyDetails, clientsParams, clients, mapPlacesQuery }) => {
  return { propertyDetails, clientsParams, clients, mapPlacesQuery };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchClients: fetchClients(apiService, dispatch),
    setClientsParams: (params) => {
      dispatch(setClientsParams(params));
    },
    setMapPlacesQuery: (query) => {
      dispatch(setMapPlacesQuery(query));
    }
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Omrade);
