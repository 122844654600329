import React, { useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import CustomMarker from '../Marker';
import { mapConfig } from '../../utils';
import './Map.scss';

const Marker = ({ children }) => children;

function Map({ center, zoom, onReset, places, onMarkerSelected, onChangeCenter, onChangeZoom }) {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);

  const points = places.map(place => ({
    type: 'Feature',
    properties: {
      cluster: false,
      placeId: place.id,
      placeAddress: place.address,
      placeCurrent: place.current,
      placeSelected: place.selected
    },
    geometry: {
      type: 'Point',
      coordinates: [
        place.lng,
        place.lat
      ]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 50, maxZoom: 17 }
  });

  const currentPlace = places.find(item => item.current);
  // const selectedPlace = places.find(item => item.selected);

  return (
    // Important! Always set the container height explicitly
    <div className="map-wrapper">
      <div className="reset-map-button" onClick={ () => onReset() }>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle className="svg-circle-stoke" cx="13" cy="13" r="12.5"/>
          <circle className="svg-circle" cx="13" cy="13" r="9"/>
        </svg>
      </div>
      <GoogleMapReact
        options={ { styles: mapConfig.styles } }
        bootstrapURLKeys={ mapConfig.urlKeys }
        center={ center }
        zoom={ zoom }
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={ ({ map }) => {
          mapRef.current = map;
        } }
        onChange={ ({ zoom, bounds, center }) => {
          onChangeCenter(center);
          onChangeZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        } }
      >
        { clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={ `cluster-${ cluster.id }` }
                lat={ latitude }
                lng={ longitude }
              >
                <div
                  className="cluster-marker"
                  style={ {
                    width: `${ 24 + (pointCount / points.length) * 20 }px`,
                    height: `${ 24 + (pointCount / points.length) * 20 }px`
                  } }
                  onClick={ () => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  } }
                >
                  { pointCount }
                </div>
              </Marker>
            );
          }

          if (cluster.properties.placeId === currentPlace.id) {
            return null;
          }

          return (
            <Marker
              key={ `place-${ cluster.properties.placeId }` }
              lat={ latitude }
              lng={ longitude }
            >
              <CustomMarker
                current={ cluster.properties.placeCurrent }
                selected={ cluster.properties.placeSelected }
                label={ cluster.properties.placeAddress }
                onSelected={ () => onMarkerSelected(cluster.properties.placeId) }
              />
            </Marker>
          );
        }) }

        <CustomMarker
          current={ true }
          selected={ currentPlace.selected }
          label={ currentPlace.address }
          lat={ currentPlace.lat }
          lng={ currentPlace.lng }
          onSelected={ () => onMarkerSelected(currentPlace.id) }
        />
      </GoogleMapReact>
    </div>
  );
}

export default Map;
