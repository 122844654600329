import React, { Component } from 'react';

import './Kalender.scss';
import Calendar from '../../components/Calendar';
import EmailModal from '../../components/Calendar/EmailModal';

import { fetchCalendar, fetchPropertyDetails } from '../../actions';
import { compose } from '../../utils';
import { withApiService } from '../../components/HOC';
import { connect } from 'react-redux';
import PreviewEmailModal from '../../components/Calendar/PreviewEmailModal';
import ApiError from '../../components/ApiError';
import InfoModal from '../../components/InfoModal';
import { templateBodyStart, templateBodyEnd } from '../../utils/templateBuilder';
import { CSSTransition } from 'react-transition-group';

class Kalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailModal: false,
      showPreviewModal: false,
      showInfoModal: false,
      showCalendar: false,
      infoModalSuccess: true,
      infoModalMessage: ''
    };
  }

  componentDidMount() {
    const { calendar, propertyDetails } = this.props;
    const id = this.props.match.params.id;

    this.setState({ showCalendar: true });

    if (id) {
      if (!calendar || calendar.id !== id) {
        this.props.fetchCalendar(id);
      }
      if (!propertyDetails || propertyDetails.id !== id) {
        this.props.fetchPropertyDetails(id);
      }
    }
  }

  handleOpenEmailModal = () => {
    this.setState({ showEmailModal: true, showPreviewModal: false, showInfoModal: false });
  };

  handleCloseEmailModal = () => {
    this.setState({ showEmailModal: false });
  };

  handleOpenPreviewModal = () => {
    this.setState({ showEmailModal: false, showPreviewModal: true, showInfoModal: false });
  };

  handleClosePreviewModal = () => {
    this.setState({ showPreviewModal: false });
  };

  handleOpenInfoModal = (success, message) => {
    this.setState({
      infoModalSuccess: success,
      infoModalMessage: message,
      showInfoModal: true
    });
  };

  handleCloseInfoModal = () => {
    this.setState({ showEmailModal: false, showPreviewModal: false, showInfoModal: false, infoModalMessage: '' });
  };

  handleSendEmail = (template) => {
    const { apiService, calendar, customRecipient, customRecipientSelected, propertyDetails } = this.props;

    const body = templateBodyStart + template + templateBodyEnd;

    const recipients = calendar.recipients.filter(recipient => recipient.selected).map(recipient => recipient.email);
    if (customRecipientSelected && customRecipient) {
      recipients.push(customRecipient);
    }

    const data = {
      subject: propertyDetails.adresse + ', ' + propertyDetails.postnummer + ' ' + propertyDetails.poststed,
      body: body,
      recipients: recipients
    };

    //console.log(data);
    apiService.sendEmail(data)
      .then(() => {
        // show email modal
        this.handleOpenInfoModal(true, 'E-posten ble sendt!');
      })
      .catch((err) => {
        // show error modal
        this.handleOpenInfoModal(false, 'Noe gikk galt');
      });
  };

  render() {
    const {
      showCalendar,
      showEmailModal,
      showPreviewModal,
      showInfoModal,
      infoModalSuccess,
      infoModalMessage
    } = this.state;

    const { calendar, error } = this.props;

    // if (loading) {
    //   return (
    //     <div>Loading...</div>
    //   );
    // }
    //
    if (error) {
      return (
        <ApiError errorData={ error }/>
      );
    }

    return (
      <div className="l-grid">
        <div className="l-grid__content l-grid__content_pb0">
          <div className="l-grid__inner l-grid__inner_mt0 l-grid__inner_pb0">
            <div className="l-grid__block">
              <div className="l-grid__info">
                <div
                  className="l-grid__section l-grid__section_overflow l-grid__section_mt20 l-grid__section_mr-15ml7pr18">
                  <CSSTransition
                    in={ showCalendar }
                    timeout={ 800 }
                    classNames="top-button-transition"
                    unmountOnExit
                  >
                    <button className="button button-default button-default_mb35 button-default_mlauto"
                            onClick={ this.handleOpenEmailModal }>Send epost
                    </button>
                  </CSSTransition>
                  {
                    <CSSTransition
                      in={ showEmailModal }
                      timeout={ 300 }
                      classNames="modal-transition"
                      unmountOnExit
                    >
                      <EmailModal
                        onClose={ this.handleCloseEmailModal }
                        onPreview={ this.handleOpenPreviewModal }
                        onSend={ this.handleSendEmail }
                      />
                    </CSSTransition>
                  }

                  {
                    <CSSTransition
                      in={ showPreviewModal }
                      timeout={ 300 }
                      classNames="modal-transition"
                      unmountOnExit
                    >
                      <PreviewEmailModal
                        onClose={ this.handleClosePreviewModal }
                        onEmail={ this.handleOpenEmailModal }
                        onSend={ this.handleSendEmail }
                      />
                    </CSSTransition>
                  }

                  {
                    <CSSTransition
                      in={ showInfoModal }
                      timeout={ 300 }
                      classNames="modal-transition"
                      unmountOnExit
                    >

                      <InfoModal
                        onClose={ this.handleCloseInfoModal }
                        success={ infoModalSuccess }
                        message={ infoModalMessage }
                      />
                    </CSSTransition>
                  }

                  <CSSTransition
                    in={ showCalendar }
                    timeout={ 500 }
                    classNames="block-transition"
                    unmountOnExit
                  >
                    <Calendar calendar={ calendar ? calendar.items : [] }/>
                  </CSSTransition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps =
  ({ calendar, customRecipient, customRecipientSelected, emailText, propertyDetails, loading, error }) => {
    return { calendar, customRecipient, customRecipientSelected, emailText, propertyDetails, loading, error };
  };

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchCalendar: fetchCalendar(apiService, dispatch),
    fetchPropertyDetails: fetchPropertyDetails(apiService, dispatch)
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Kalender);
