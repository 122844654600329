import React, { forwardRef } from 'react';
import Sprite from '../App/images/sprites/sprite.svg';
import styled from 'styled-components';
import { slideDownAnimation, slideUpAnimation } from '../../animations';

const Item = forwardRef(({ className, property, handleClick }, ref) => {
  return (
    <li ref={ ref } className={ `${ className } c-property__item is-clickable` } onClick={ handleClick }>
      <div className="c-property__inner">
        <p className="c-property__title">
          { property.adresse }, { property.postnummer } { property.poststed }
        </p>
        <p className="c-property__link">
          <svg width="15" height="15">
            <use href={ Sprite + '#back' }/>
          </svg>
        </p>
      </div>
    </li>
  );
});

const PropertyListItemTransitioned = styled(Item)`
  &.appear,
  &.enter {
    animation-name: ${ slideUpAnimation };
    animation-duration: ${ ({ duration }) => duration.enter }ms;
    animation-timing-function: ease-in-out;
    animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
  }

  animation-fill-mode: both;

  &.appear {
    animation-name: ${ slideUpAnimation };
    animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
  }

  &.exit {
    animation-name: ${ slideDownAnimation };
    animation-duration: ${ ({ duration }) => duration.enter }ms;
    animation-timing-function: ease-in-out;
  }
`;

export default PropertyListItemTransitioned;
