import React, { Component, Fragment } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import nb from '@fullcalendar/core/locales/nb';
import EventModal from './EventModal';

import './Calendar.scss';
import iconComment from './images/icon-comment.svg';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import holidays from './holidays';

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedDate: null
    };
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  showEventModal = (dateString) => {
    const today = dayjs(new Date()).format('YYYY-MM-DD');
    if (!dayjs(dateString).isBefore(today)) {
      this.setState({ selectedDate: dateString });
      this.handleOpenModal();
    }
  };

  handleDateClick = (info) => {
    this.showEventModal(info.dateStr);
  };

  handleEventClick = (info) => {
    this.showEventModal(info.event.startStr);
  };

  handleMoreClick = (info) => {
    const dateString = dayjs(info.date).format('YYYY-MM-DD');
    this.showEventModal(dateString);
    return 'month';
  };

  calendarRef = React.createRef();

  render() {
    const { showModal, selectedDate } = this.state;
    const { calendar } = this.props;

    let events = [].concat(
      ...calendar.map(item => {
        return item.events.map(event => {
          const title = event.includes('Annet: ') ? event.slice(7) : event;
          return { title: title, date: item.date };
        });
      })
    );

    return (
      <Fragment>
        {
          <CSSTransition
            in={ showModal }
            timeout={ 300 }
            classNames="modal-transition"
            unmountOnExit
          >
            <EventModal onClose={ this.handleCloseModal } date={ selectedDate }/>
          </CSSTransition>
        }
        <FullCalendar
          ref={ this.calendarRef }
          plugins={ [dayGridPlugin, timeGridPlugin, interactionPlugin] }
          headerToolbar={ {
            left: 'prev title next',
            center: 'dayGridMonth,timeGridWeek',
            right: ''
          } }
          initialView="dayGridMonth"
          views={
            {
              dayGrid: {
                dayMaxEvents: true,
                dayHeaderFormat: { weekday: 'long' }
              },
              timeGrid: {
                dayMaxEvents: false,
                dayHeaderFormat: { weekday: 'long', day: 'numeric' }
              }
            }
          }
          locale={ nb }
          weekNumbers
          weekNumberFormat={ { week: 'numeric' } }
          eventSources={ [holidays, events] }
          dateClick={ this.handleDateClick }
          eventClick={ this.handleEventClick }
          moreLinkClick={ this.handleMoreClick }
          dayCellContent={
            (arg) => {
              const day = calendar.find(item => item.date === dayjs(arg.date).format('YYYY-MM-DD'));
              const hasComment = day && day.comment;
              return (
                <>
                  { arg.dayNumberText }
                  { hasComment ? <img className="icon-comment" src={ iconComment } alt="comment"/> : null }
                </>
              );
            }
          }
        />
      </Fragment>
    );
  }
};
