import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Start from '../../pages/Start';
import Select from '../../pages/Select';
import User from '../../pages/User';
import VisningslisteLayout from '../../pages/Visningsliste/VisningslisteLayout';
import Login from '../../pages/Login';
import SetPin from '../../pages/SetPin';
import ChangePin from '../../pages/ChangePin';
import Layout from '../Layout';

const Routes = ({ isAuthenticated }) => {
  let location = useLocation();

  useEffect(
    () => {
      if (location.pathname !== '/login' && location.pathname !== '/change-pin') {
        localStorage.setItem('befaring_redirect', location.pathname);
      }
    },
    [location]
  );

  return (
    <>
      { !isAuthenticated ? <Redirect to="/login"/> : null }
      <Switch>
        <Route path="/" exact component={ Start }/>
        <Route path="/select" component={ Select }/>
        <Route path="/user" component={ User }/>
        <Route path="/visningsliste/:id" component={ VisningslisteLayout }/>
        <Route path="/login" component={ Login }/>
        <Route path="/set-pin" component={ SetPin }/>
        <Route path="/change-pin" component={ ChangePin }/>
        <Route path="/:id" component={ Layout }/>

        <Route render={ () => <div><h1>404</h1><h2>Page not found</h2></div> }/>
      </Switch>
    </>
  );
};

export default Routes;
