import React, { useEffect, useState } from 'react';
import SwiperCore, { Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';

// Import component styles
import './Slider.scss';
import { CSSTransition } from 'react-transition-group';

// install Swiper's Thumbs component
SwiperCore.use([Pagination, Thumbs]);

const Slider = (props) => {
  // store thumbs swiper instance
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    setShowGallery(true);
  }, []);

  const { images } = props;

  const slides = images.map(item => {
    return (
      <SwiperSlide key={ `slide-${ item.id }` }>
        <div className="swiper-slide-content">
          <div className="swiper-slide-desc"><p>{ item.overskrift }</p></div>
          <img src={ item.urloriginalbilde } alt={ `Slide ${ item.id }` }/>
        </div>
      </SwiperSlide>
    );
  });

  const thumbs = images.map(item => {
    return (
      <SwiperSlide key={ `thumb-${ item.id }` }>
        <img src={ item.urloriginalbilde } alt={ `Thumbnail ${ item.id }` }/>
      </SwiperSlide>
    );
  });

  return (
    <CSSTransition
      in={ showGallery }
      timeout={ 500 }
      classNames="block-transition"
      unmountOnExit
    >
      <div style={ { height: '100%' } }>
        {/* Main Swiper -> pass thumbs swiper instance */ }
        <Swiper
          id="main"
          thumbs={ { swiper: thumbsSwiper } }
          pagination={ { type: 'fraction' } }
          className="swiper-container-main"
        >
          { slides }
        </Swiper>

        {/* Thumbs Swiper -> store swiper instance */ }
        {/* It is also required to set watchSlidesVisibility and watchSlidesProgress props */ }
        <Swiper
          id="thumbs"
          slidesPerView={ 10 }
          onSwiper={ setThumbsSwiper }
          freeMode
          watchSlidesVisibility
          watchSlidesProgress
          spaceBetween={ 7 }
        >
          { thumbs }
        </Swiper>
      </div>
    </CSSTransition>
  );
};

export default Slider;
