import dayjs from '../utils/dayjs';
import ReactDOMServer from 'react-dom/server';

const DocumentsBlock = ({ documents, assignmentAgreement }) => {
  if ((documents && documents.length) || assignmentAgreement) {
    return (
      <tr>
        <td
          className="padding"
          style={ { paddingLeft: 30, fontSize: 12, fontWeight: 500, paddingBottom: 30, paddingRight: 30 } }
        >
          <h4 style={ { fontSize: 16, marginTop: 0, marginBottom: 16 } }>Dokumenter:</h4>
          <ul style={ { paddingLeft: 16 } }>
            { (documents && documents.length) ? (
              documents.map((document) => (
                <li key={ document.name } style={ { listStyleType: 'disc' } }>
                  <a
                    href={ document.url }
                    target="_blank"
                    rel="noreferrer"
                    data-dokument={ document.name }
                    style={ { textTransform: 'uppercase', textDecoration: 'underline', color: '#000' } }
                  >
                    { document.name === 'Befaring PDF' ? 'MARKEDSFØRING OG SALG' : document.name }
                  </a>
                </li>
              ))
            ) : null }

            { assignmentAgreement ? (
              <li style={ { listStyleType: 'disc' } }>
                <a
                  href={ assignmentAgreement }
                  target="_blank"
                  rel="noreferrer"
                  data-dokument="Oppdragsavtale"
                  style={ { textTransform: 'uppercase', textDecoration: 'underline', color: '#000' } }
                >
                  Oppdragsavtale
                </a>
              </li>
            ) : null }
          </ul>
        </td>
      </tr>
    );
  }
  return null;
};

const departmentLogo = (department) => department ?
  department.logo : 'https://involvestatic.s3-eu-west-1.amazonaws.com/befaring/email/partners-logo-white.png';

const SellerNameBlock = ({ seller }) => {
  if (seller) {
    return (
      <h4 style={ { margin: 0, fontSize: 14, padding: 0 } }>Kjære { seller.name }</h4>
    );
  }
  return null;
};

const EmailTextBlock = ({ emailText }) => {
  if (emailText) {
    const htmlString = emailText.replace(/\n/g, '<br>');
    return (
      <p style={ { margin: 0 } } dangerouslySetInnerHTML={ { __html: htmlString } }/>
    );
  }
  return null;
};

const ImagesBlock = ({ images }) => {
  if (images && images.length) {
    return (<tr>
      <td className="padding" style={ { paddingLeft: 30, paddingBottom: 30, paddingRight: 30 } }>
        <table className="table-images" width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">
          <tr>
            { images[0] ? (
              <>
                <td>
                  <img style={ { height: 100 } } src={ images[0].urlstorthumbnail } alt="1"/>
                </td>
                <td width="1%"/>
              </>) : null }

            { images[1] ? (
              <>
                <td>
                  <img style={ { height: 100 } } src={ images[1].urlstorthumbnail } alt="2"/>
                </td>
                <td width="1%"/>
              </>) : null }

            { images[2] ? (
              <>
                <td>
                  <img style={ { height: 100 } } src={ images[2].urlstorthumbnail } alt="3"/>
                </td>
                <td width="1%"/>
              </>) : null }

            { images[3] ? (
              <td>
                <img style={ { height: 100 } } src={ images[3].urlstorthumbnail } alt="4"/>
              </td>
            ) : null }

          </tr>
        </table>
      </td>
    </tr>);
  }
  return null;
};

const BrokerBlock = ({ broker }) => {
  if (broker) {
    return (
      <td className="footer-td" style={ { width: '50%' } }>
        <table>
          <tr>
            <td>
              <table width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">
                <tr>
                  <td>
                    <img style={ { width: 74 } }
                         width="74"
                         src={ broker.avatar }
                         alt="personal broker"/>
                  </td>
                  <td style={ { paddingLeft: 10 } }>
                    <p style={ { fontSize: 14, margin: 0, marginBottom: 5 } }>
                      { broker.navn }
                    </p>
                    <p style={ { fontSize: 12, margin: 0, marginBottom: 10 } }>
                      { broker.tittel }
                    </p>
                    <p style={ { fontSize: 12, margin: 0, marginBottom: 5 } }>
                      <a
                        href={ 'tel:' + broker.mobiltelefon.replace(/\s+/g, '') }
                        style={ { textDecoration: 'none', verticalAlign: 'middle', color: '#FFFFFF' } }>
                        { broker.mobiltelefon }
                      </a>
                    </p>
                    <p style={ { fontSize: 12, margin: 0 } }>
                      <a
                        href={ 'mailto:' + broker.email }
                        style={ { textDecoration: 'none', verticalAlign: 'middle', color: '#FFFFFF' } }
                      >
                        { broker.email }
                      </a>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    );
  }
  return null;
};

const CalendarBlock = ({ calendarItems }) => {
  if (calendarItems && calendarItems.length) {
    const couples = calendarItems.reduce((result, value, index, array) => {
      if (index % 2 === 0) {
        result.push(array.slice(index, index + 2));
      }
      return result;
    }, []);

    return (
      <>
        <tr>
          <td
            className="padding"
            style={ { paddingLeft: 30, fontSize: 12, fontWeight: 500, paddingBottom: 30, paddingRight: 30 } }
          >
            <h4 style={ { fontSize: 16, marginTop: 0, marginBottom: 0 } }>Planlagte aktiviteter:</h4>
          </td>
        </tr>

        <tr>
          <td style={ { paddingBottom: 30 } }>
            <table className="table-dates" bgcolor="#F4F4F4" width="100%" cellPadding="0" cellSpacing="0" align="center"
                   border="0">
              <tr>
                <td className="padding"
                    style={ { paddingLeft: 30, paddingBottom: 20, paddingTop: 20, paddingRight: 30 } }>
                  <table width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">
                    {
                      couples.map((couple, coupleIdx) => (
                        <tr key={ coupleIdx }>
                          { couple.map((item, itemIdx) => (
                            <td key={ itemIdx } valign="top" width="40%">
                              <p style={ { margin: 0, fontSize: 12, fontWeight: 600, textTransform: 'capitalize' } }>
                                { dayjs(item.date).format('D MMMM YYYY') }
                              </p>
                              { item.events && item.events.length ? (
                                <div
                                  className="list"
                                  style={ {
                                    fontSize: 12,
                                    padding: 0,
                                    marginRight: 15,
                                    marginTop: 10,
                                    marginBottom: 15
                                  } }
                                >
                                  { item.events.map((event, i) => (
                                    <p key={ i } style={ { margin: 0, marginBottom: 5 } }>
                                      { i + 1 }. { event.includes('Annet: ') ? event.slice(7) : event }
                                    </p>)) }
                                  { item.comment ? (
                                    <p style={ { marginTop: 10, marginBottom: 5 } }>
                                      <img style={ { verticalAlign: 'middle' } } width="12px"
                                           src="https://involvestatic.s3-eu-west-1.amazonaws.com/befaring/email/comment-icon.png"
                                           alt="phone number"/>
                                      <span>&nbsp;</span>
                                      <i>{ item.comment }</i>
                                    </p>) : null }
                                </div>) : null }
                            </td>)) }
                        </tr>
                      ))
                    }
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </>
    );
  }
  return null;
};

const TemplateBody = ({
                        assignmentAgreement,
                        calendarItems,
                        broker,
                        images,
                        emailText,
                        address,
                        seller,
                        department,
                        documents
                      }) => {
  return (
    <div className="parent" style={ { maxWidth: 768, margin: '0 auto' } }>
      <table width="100%"
             style={ { maxWidth: 768, marginLeft: 'auto', marginRight: 'auto' } }
             cellPadding="0"
             cellSpacing="0"
             align="center" border="0">

        <tr>
          <td valign="top" align="center">
            <table width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">

              <tr>
                <td valign="top" align="center">
                  <table width="100%" cellPadding="0" cellSpacing="0" align="center" border="0" bgcolor="#323232"
                         background="https://involvestatic.s3-eu-west-1.amazonaws.com/befaring/email/table-bg-sm.jpg"
                         style={ { color: '#FFFFFF', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' } }>

                    <tr>
                      <td valign="middle">
                        <table cellPadding="0" cellSpacing="0" align="left" border="0">
                          <tbody>
                          <tr>
                            <td className="padding" valign="top"
                                style={ { paddingTop: 30, paddingLeft: 30, paddingBottom: 30 } }>
                              { /* eslint-disable-next-line */ }
                              <a style={ { verticalAlign: 'middle' } } href="#" target="_blank" rel="noreferrer">
                                <img
                                  height="40"
                                  src={ departmentLogo(department) }
                                  style={ { height: 40 } }
                                  alt="logo"/>
                              </a>
                            </td>
                            <td className="padding"
                                style={ { paddingTop: 30, paddingBottom: 30, paddingLeft: 30, paddingRight: 30 } }>
                              <h4
                                style={ { margin: 0, padding: 0, fontWeight: 400, verticalAlign: 'middle' } }>
                                { department ? department.name : 'Partners' }
                              </h4>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                  </table>
                </td>
              </tr>
              <tr>
                <td valign="top" align="center">
                  <table width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">

                    <tr>
                      <td className="padding"
                          style={ {
                            paddingTop: 50,
                            paddingLeft: 30,
                            paddingBottom: 30,
                            paddingRight: 30,
                            fontSize: 12
                          } }>
                        <SellerNameBlock seller={ seller }/>
                        <EmailTextBlock emailText={ emailText }/>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td className="td-object padding"
                    style={ { paddingLeft: 30, paddingBottom: 30, paddingRight: 30, fontSize: 12 } }>
                  <span style={ { fontWeight: 600 } }>{ address }</span>
                </td>
              </tr>

              <ImagesBlock images={ images }/>

              <CalendarBlock calendarItems={ calendarItems }/>

              <DocumentsBlock documents={ documents } assignmentAgreement={ assignmentAgreement }/>

              <tr>
                <td valign="top" align="center">
                  <table background="https://involvestatic.s3-eu-west-1.amazonaws.com/befaring/email/table-bg-lg.jpg"
                         bgcolor="323232"
                         style={ { color: '#FFFFFF', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' } }
                         width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">
                    <tr>
                      <td>
                        <table width="100%" cellPadding="0" cellSpacing="0" align="center" border="0">
                          <tr>
                            <td className="padding"
                                style={ { paddingLeft: 30, paddingTop: 30, paddingBottom: 30, paddingRight: 30 } }>
                              <h4 style={ { margin: 0, padding: 0, fontWeight: 400 } }>
                                Vennlig hilsen din personlige megler:
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="padding"
                          style={ { paddingLeft: 30, paddingBottom: 30, paddingRight: 30 } }>
                        <table className="table-footer" width="100%" cellPadding="0" cellSpacing="0" border="0">
                          <tr>
                            <BrokerBlock broker={ broker }/>
                          </tr>
                        </table>
                      </td>
                    </tr>

                  </table>
                </td>
              </tr>

            </table>
          </td>
        </tr>

      </table>
    </div>
  );
};

const templateBuilder = (
  {
    assignmentAgreement,
    calendarItems,
    broker,
    images,
    emailText,
    address,
    seller,
    department,
    documents
  }
) => {
  return ReactDOMServer.renderToString(
    <TemplateBody
      calendarItems={ calendarItems }
      broker={ broker }
      images={ images }
      emailText={ emailText }
      address={ address }
      seller={ seller }
      department={ department }
      documents={ documents }
      assignmentAgreement={ assignmentAgreement }
    />
  );
};

const templateBodyStart =
  `<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
  <title>HTML Template</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
  <style>
      body {
          width: 100% !important;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          margin: 0;
          padding: 0;
      }

      [style*="Roboto"] {
          font-family: 'Roboto', arial, sans-serif !important;
      }

      img {
          outline: none;
          text-decoration: none;
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100% !important;
          margin: 0;
          padding: 0;
      }

      .list {
          margin-bottom: 15px;

      }

      table td {
          border-collapse: collapse;
      }

      table {
          border-collapse: collapse;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
      }

      .btn {
          width: 200px;
          text-align: center;
          background-color: #DBDBDB;
          font-size: 12px;
          font-weight: 500;
          height: 42px;
      }

      @media (max-width: 768px) {
          .header-td {
              width: 68% !important;
          }
          .table-images td {

          }

      }

      @media (max-width: 600px) {

          p {
              font-size: 9px !important;
          }

          .padding {
              padding-left: 15px !important;
              padding-right: 15px !important;
          }


          .table-footer > tbody > tr > td {
              display: block;
          }

          .footer-td {
              margin-top: 20px;
          }

          .table-btn  > tbody > tr > td {
              display: block;
          }


      }

      @media (max-width: 400px) {
        .td-object span {
            display: block;
            margin-top: 10px;
            padding-left: 0!important;
        }
      }

  </style>
</head>

<body
  style="margin: 0; margin-left: auto; margin-right: auto; width: 768px; font-family: 'Roboto', arial, sans-serif !important; padding: 0;">`;

const templateBodyEnd = '</body></html>';

export {
  templateBuilder,
  templateBodyStart,
  templateBodyEnd
};
