import React, { Fragment, useState } from 'react';

const CustomInput = ({ id, value, label, onChange, errors = null, type = 'text' }) => {
  const [focused, setFocused] = useState(false);

  return (
    <Fragment>
      <input type={ type }
             id={ id }
             value={ value }
             onChange={ onChange }
             onFocus={ () => setFocused(true) }
             onBlur={ () => setFocused(false) }
             className="block-visninglist__form-item"/>
      <label htmlFor={ id }
             className={ `block-visninglist__form-label ${ focused || value ? 'is-focus' : '' }` }>
        { label }
      </label>
      { errors ? errors.map((error, idx) => (<div key={ idx } className="form-error">{ error }</div>)) : null }
    </Fragment>
  );
};

export default CustomInput;
