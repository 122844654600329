import React from 'react';
import Modal from '../../../../components/Modal';

const ConfirmModal = ({ onConfirm, onCancel, centered }) => {
  return (
    <Modal centered={ centered } onClose={ onCancel } width={ 630 }>
      <div className="modal__wrapper">
        <h3>Meld av</h3>
        <p className="mt-3">Er du sikker på at du vil melde deg av?</p>
        <div className="d-flex jcsb mt-3">
          <button className="button button-block hover-dark" onClick={ onConfirm }>Ja</button>
          <button className="button button-block hover-dark ml-2" onClick={ onCancel }>Nei</button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
