import React from 'react';
import Modal from '../Modal';

const SetPinModal = ({ onConfirm, onSkip }) => {
  return (
    <Modal onClose={ onSkip } width={ 630 }>
      <div className="modal__wrapper">
        <h3>Legg til PIN-kode</h3>
        <p className="mt-3">Du kan bruke din personlige PIN-kode for å komme inn i arbeidsområdet lettere og
          raskere.</p>
        <p className="mt-2">Vil du legge til en nål?</p>
        <div className="d-flex jcsb mt-3">
          <button className="button button-lg button-block hover-dark" onClick={ onConfirm }>Ja</button>
          <button className="button button-lg button-block hover-dark ml-2" onClick={ onSkip }>Nei</button>
        </div>
      </div>
    </Modal>
  );
};

export default SetPinModal;
