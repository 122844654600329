import styled from 'styled-components';
import { slideDownAnimation, slideUpAnimation } from '../../animations';

const SlideUpWrapper = (Item) => {
  return styled(Item)`
    &.appear,
    &.enter {
      animation-name: ${ slideUpAnimation };
      animation-duration: ${ ({ duration }) => duration.enter }ms;
      animation-timing-function: ease-in-out;
      animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
    }

    animation-fill-mode: both;

    &.appear {
      animation-name: ${ slideUpAnimation };
      animation-delay: ${ ({ order, stagger }) => order * stagger }ms;
    }

    &.exit {
      animation-name: ${ slideDownAnimation };
      animation-duration: ${ ({ duration }) => duration.enter }ms;
      animation-timing-function: ease-in-out;
    }
  `;
};

export default SlideUpWrapper;
