import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.scss';

import * as dayjs from 'dayjs';
import Preloader from '../Preloader';
import Toast from '../Toast';
import { useClearCacheCtx } from 'react-clear-cache';
import Routes from './Routes';

const App = () => {
  const authData = JSON.parse(localStorage.getItem('befaring_auth'));
  const expireAt = authData ? authData['expire_at'] : null;
  const isAuthenticated = expireAt ? dayjs.unix(expireAt).diff(dayjs(), 'hour') > 0 : false;

  const [showToast, setShowToast] = useState(true);

  const isProd = process.env.NODE_ENV === 'production';
  const brand = process.env.REACT_APP_BRAND;

  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  if (isProd && !isLatestVersion) {
    emptyCacheStorage();
  }

  return (
    <div className="main">
      <Router>
        <Routes isAuthenticated={ isAuthenticated }/>
      </Router>
      { isProd && showToast ? <Toast onSkip={ () => setShowToast(false) }/> : null }
      { isProd ? <Preloader brand={ brand }/> : null }
    </div>
  );
};

export default App;
