import React from 'react';
import PartnersLogoBig from '../App/images/partners-logo-big.svg';
import DefaultLogoBig from '../App/images/default-logo-big.svg';

const brand = process.env.REACT_APP_BRAND;

const LogoBig = () => {
  return (
    <div className="logo-big">
      <img src={ brand === 'partners' ? PartnersLogoBig : DefaultLogoBig } alt="logo"/>
    </div>
  );
};

export default LogoBig;
