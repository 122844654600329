import React, { useState } from 'react';
import Modal from '../Modal';
import './EditModal.scss';
import { withApiService } from '../HOC';
import { compose } from '../../utils';
import { connect } from 'react-redux';
import { fetchPropertyDetails } from '../../actions';
import NumberFormat from 'react-number-format';

const EditModal = (
  {
    config,
    onClose,
    apiService,
    propertyDetails,
    fetchPropertyDetails
  }
) => {
  const [value, setValue] = useState(config.value ? +config.value : undefined);

  const resetAndSubmitValue = () => {
    apiService.updatePropertyParam(propertyDetails.id, config.param, '')
      .then(() => {
        onClose();
        setTimeout(() => {
          fetchPropertyDetails(propertyDetails.id);
        }, 300);
      });
  };

  const submitValue = () => {
    apiService.updatePropertyParam(propertyDetails.id, config.param, value)
      .then(() => {
        onClose();
        setTimeout(() => {
          fetchPropertyDetails(propertyDetails.id);
        }, 300);
      });
  };

  return (
    <Modal onClose={ onClose } width={ 420 }>
      <div className="modal__wrapper">
        <p className="modal__title">
          Rediger { config.label.toLowerCase() }
        </p>

        <div className="mt-3">
          <NumberFormat
            autoFocus
            className="edit-value-input"
            placeholder="Skriv inn verdi"
            thousandSeparator={ ' ' }
            decimalScale={ 0 }
            allowNegative={ false }
            value={ value }
            onValueChange={ ({ floatValue }) => {
              setValue(floatValue);
            } }
          />
        </div>
      </div>

      <div className="modal__inner">
        <div className="modal__box-btns">
          <button
            className="button hover-dark button-default"
            style={ { marginRight: 16 } }
            onClick={ resetAndSubmitValue }
          >
            Nullstill
          </button>

          <button
            className="button hover-dark button-default"
            onClick={ submitValue }
          >
            Lagre
          </button>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = ({ propertyDetails }) => {
  return { propertyDetails };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchPropertyDetails: fetchPropertyDetails(apiService, dispatch)
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditModal);
