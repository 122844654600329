import React from 'react';
import { connect } from 'react-redux';

import './Bilder.scss';
import Slider from '../../../components/Slider';

const Bilder = (props) => {
  const { propertyDetails } = props;

  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info" style={ { height: '100%' } }>
          <div className="l-grid__section l-grid__section_overflow l-grid__section_mr15pr15 mt-0"
               style={ { height: '100%' } }>
            <Slider images={ propertyDetails.images }/>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ propertyDetails }) => {
  return { propertyDetails };
};

export default connect(mapStateToProps)(Bilder);
