const auth = JSON.parse(localStorage.getItem('befaring_auth'));

const plusTwentyPct = (value, roundTo = 1) => {
  return Math.ceil((+value + (value / 5)) / roundTo) * roundTo;
};

const minusTwentyPct = (value, roundTo = 1) => {
  return Math.ceil((+value - (value / 5)) / roundTo) * roundTo;
};

const initialState = {
  properties: [],
  detailsMapCenter: null,
  detailsMapZoom: 13,
  statisticsMapCenter: null,
  statisticsMapZoom: 13,
  officeProperties: {
    query: '',
    totalCount: 0,
    pagesCount: 0,
    currentPage: 1,
    list: []
  },
  searchProperties: {
    query: '',
    totalCount: 0,
    pagesCount: 0,
    currentPage: 1,
    list: []
  },
  mapPlacesQuery: '',
  current: null,
  propertyDetails: null,
  calendar: null,
  customRecipientSelected: false,
  customRecipient: '',
  selectedProperty: null,
  clients: {
    loading: false,
    totalCount: 0,
    pagesCount: 0,
    currentPage: 1,
    list: []
  },
  clientsParams: {
    avstand: 10000,
    leadstype: [
      'Budvarsel',
      'Salgsoppgave',
      'Salgssum',
      'Salgssum_landing',
      'Salgssum_venter',
      'Visningliste',
      'Boligvarsling'
    ],
    eiendomstype: [],
    registrert: { from: 0, to: 12 },
    salgssum: { from: 0, to: 10000000 },
    primaerrom: { from: 0, to: 300 }
  },
  user: auth ? auth.user : null,
  users: JSON.parse(localStorage.getItem('befaring_users')) || [],
  emailText: 'Hei!\n\n' +
    'Takk for hyggelig befaring av din bolig!\n\n' +
    'Jeg og resten av mitt boligteam er motiverte og klare til å gå i gang med forberedelser som gjør at vi finner de kjøperne som er på jakt etter akkurat din bolig.\n\n' +
    'Som avtalt sender jeg deg et forslag til en fremdriftsplan med de ulike aktivitetene som må gjennomføres som en del av salgsprosessen.\n\n' +
    'Neste steg er så å signere avtale om salg av din bolig. Avtalen kan signeres på papir eller elektronisk ved bruk av Bank-ID.\n\n' +
    'Jeg minner også om at du i appen "Mitt hjem" kan følge salgsprosessen og det er også her bilder og andre relevante dokumenter vil bli gjort tilgjengelig fortløpende.\n\n' +
    'Dersom du har noen spørsmål hører jeg gjerne fra deg. Jeg ser frem til et godt samarbeid!',
  loading: true,
  error: null,
  selectType: 'broker',
  visningForm: null,
  assignmentAgreementSelected: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PROPERTIES_REQUEST':
      return {
        ...state,
        properties: [],
        propertyDetails: null,
        calendar: null,
        selectedProperty: null,
        current: null,
        loading: true,
        error: null
      };
    case 'FETCH_PROPERTIES_SUCCESS':
      return {
        ...state,
        properties: action.payload,
        propertyDetails: null,
        calendar: null,
        selectedProperty: null,
        current: null,
        loading: false,
        error: null
      };
    case 'FETCH_PROPERTIES_FAILURE':
      return {
        ...state,
        properties: [],
        propertyDetails: null,
        calendar: null,
        selectedProperty: null,
        current: null,
        loading: false,
        error: action.payload
      };
    case 'RESET_PROPERTIES':
      return {
        ...state,
        properties: []
      };
    case 'SET_CURRENT_PROPERTY':
      return {
        ...state,
        current: state.properties.find(property => property.id === action.payload)
      };
    case 'FETCH_PROPERTY_DETAILS_REQUEST':
      return {
        ...state,
        propertyDetails: null,
        selectedProperty: null,
        loading: true,
        error: null
      };
    case 'FETCH_PROPERTY_DETAILS_SUCCESS':
      return {
        ...state,
        propertyDetails: action.payload,
        detailsMapCenter: { lat: +action.payload.lat, lng: +action.payload.lng },
        detailsMapZoom: 13,
        statisticsMapCenter: { lat: +action.payload.lat, lng: +action.payload.lng },
        statisticsMapZoom: 13,
        mapPlacesQuery: action.payload.adresse + ', ' + action.payload.postnummer + ' ' + action.payload.poststed,
        selectedProperty: action.payload,
        clientsParams: {
          ...state.clientsParams,
          property_id: +action.payload.id,
          salgssum: action.payload.prisantydning ?
            {
              from: minusTwentyPct(action.payload.prisantydning, 100000),
              to: plusTwentyPct(action.payload.prisantydning, 100000)
            }
            :
            action.payload.forventet_salgspris ?
              {
                from: minusTwentyPct(action.payload.forventet_salgspris, 100000),
                to: plusTwentyPct(action.payload.forventet_salgspris, 100000)
              }
              :
              { from: 0, to: 10000000 },
          primaerrom: action.payload.prom ?
            {
              from: minusTwentyPct(action.payload.prom, 10),
              to: plusTwentyPct(action.payload.prom, 10)
            }
            :
            { from: 0, to: 300 },
          eiendomstype: action.payload.type_eiendomstyper ?
            (
              action.payload.type_eiendomstyper === 'Enebolig' ||
              action.payload.type_eiendomstyper === 'Tomannsbolig' ||
              action.payload.type_eiendomstyper === 'Rekkehus'
            ) ?
              ['Enebolig', 'Tomannsbolig', 'Rekkehus']
              :
              [action.payload.type_eiendomstyper]
            :
            []
        },
        loading: false,
        error: null,
        assignmentAgreementSelected: false
      };
    case 'FETCH_PROPERTY_DETAILS_FAILURE':
      return {
        ...state,
        propertyDetails: null,
        selectedProperty: null,
        loading: false,
        error: action.payload
      };
    case 'SET_PROPERTY_CATEGORIES':
      return {
        ...state,
        propertyDetails: {
          ...state.propertyDetails,
          categories: action.payload
        }
      };
    case 'SET_PROPERTY_MARKERS':
      return {
        ...state,
        propertyDetails: {
          ...state.propertyDetails,
          markers: action.payload
        }
      };
    case 'SET_DETAILS_MAP_CENTER':
      return {
        ...state,
        detailsMapCenter: action.payload
      };
    case 'SET_DETAILS_MAP_ZOOM':
      return {
        ...state,
        detailsMapZoom: action.payload
      };
    case 'SET_STATISTICS_MAP_CENTER':
      return {
        ...state,
        statisticsMapCenter: action.payload
      };
    case 'SET_STATISTICS_MAP_ZOOM':
      return {
        ...state,
        statisticsMapZoom: action.payload
      };
    case 'FETCH_CALENDAR_REQUEST':
      return {
        ...state,
        // calendar: null,
        loading: true,
        error: null
      };
    case 'FETCH_CALENDAR_SUCCESS':
      return {
        ...state,
        calendar: action.payload,
        loading: false,
        error: null
      };
    case 'FETCH_CALENDAR_FAILURE':
      return {
        ...state,
        calendar: null,
        loading: false,
        error: action.payload
      };
    case 'FETCH_SELECTED_PROPERTY_REQUEST':
      return {
        ...state,
        // loading: true,
        selectedProperty: null,
        error: null
      };
    case 'FETCH_SELECTED_PROPERTY_SUCCESS':
      return {
        ...state,
        selectedProperty: action.payload,
        loading: false,
        error: null
      };
    case 'FETCH_SELECTED_PROPERTY_FAILURE':
      return {
        ...state,
        selectedProperty: null,
        loading: false,
        error: action.payload
      };
    case 'SET_CLIENTS_PARAMS':
      return {
        ...state,
        clients: {
          ...state.clients,
          list: []
        },
        clientsParams: action.payload
      };
    case 'FETCH_CLIENTS_REQUEST':
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: true
        },
        error: null
      };
    case 'FETCH_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: {
          ...state.clients,
          ...action.payload,
          loading: false,
          list: [...state.clients.list.concat(action.payload.list)]
        },
        loading: false,
        error: null
      };
    case 'FETCH_CLIENTS_FAILURE':
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false
        },
        loading: false,
        error: action.payload
      };
    case 'SET_EMAIL_TEXT':
      return {
        ...state,
        emailText: action.payload
      };
    case 'FETCH_OFFICE_PROPERTIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_OFFICE_PROPERTIES_SUCCESS':
      return {
        ...state,
        officeProperties: {
          ...state.officeProperties,
          ...action.payload,
          list: [...state.officeProperties.list.concat(action.payload.list)]
        },
        loading: false,
        error: null
      };
    case 'FETCH_OFFICE_PROPERTIES_FAILURE':
      return {
        ...state,
        officeProperties: {
          ...state.officeProperties,
          totalCount: 0,
          pagesCount: 0,
          currentPage: 1,
          list: []
        },
        loading: false,
        error: action.payload
      };
    case 'RESET_OFFICE_PROPERTIES':
      return {
        ...state,
        officeProperties: {
          query: state.officeProperties.query,
          totalCount: 0,
          pagesCount: 0,
          currentPage: 1,
          list: []
        }
      };
    case 'SET_OFFICE_PROPERTIES_QUERY':
      return {
        ...state,
        officeProperties: {
          query: action.payload,
          totalCount: 0,
          pagesCount: 0,
          currentPage: 1,
          list: []
        }
      };
    case 'FETCH_SEARCH_PROPERTIES_REQUEST':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_SEARCH_PROPERTIES_SUCCESS':
      return {
        ...state,
        searchProperties: {
          ...state.searchProperties,
          ...action.payload,
          list: [...state.searchProperties.list.concat(action.payload.list)]
        },
        loading: false,
        error: null
      };
    case 'FETCH_SEARCH_PROPERTIES_FAILURE':
      return {
        ...state,
        searchProperties: {
          ...state.searchProperties,
          totalCount: 0,
          pagesCount: 0,
          currentPage: 1,
          list: []
        },
        loading: false,
        error: action.payload
      };
    case 'RESET_SEARCH_PROPERTIES':
      return {
        ...state,
        searchProperties: {
          query: state.searchProperties.query,
          totalCount: 0,
          pagesCount: 0,
          currentPage: 1,
          list: []
        }
      };
    case 'SET_SEARCH_PROPERTIES_QUERY':
      return {
        ...state,
        searchProperties: {
          query: action.payload,
          totalCount: 0,
          pagesCount: 0,
          currentPage: 1,
          list: []
        }
      };
    case 'SET_MAP_PLACES_QUERY':
      return {
        ...state,
        mapPlacesQuery: action.payload
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload
      };
    case 'RESET_USER':
      return {
        ...state,
        user: null
      };
    case 'RESET_STORE':
      return {
        ...initialState,
        loading: false
      };
    case 'SET_CALENDAR_RECIPIENTS':
      return {
        ...state,
        calendar: {
          ...state.calendar,
          recipients: action.payload
        }
      };
    case 'SET_CALENDAR_DOCUMENTS':
      return {
        ...state,
        calendar: {
          ...state.calendar,
          documents: action.payload
        }
      };
    case 'SET_CUSTOM_RECIPIENT':
      return {
        ...state,
        customRecipient: action.payload
      };
    case 'SET_CUSTOM_RECIPIENT_SELECTED':
      return {
        ...state,
        customRecipientSelected: action.payload
      };
    case 'SET_SELECT_TYPE':
      return {
        ...state,
        selectType: action.payload
      };
    case 'SET_VISNING_FORM':
      return {
        ...state,
        visningForm: action.payload
      };
    case 'RESET_VISNING_FORM':
      return {
        ...state,
        visningForm: null
      };
    case 'SET_ASSIGNMENT_AGREEMENT_SELECTED':
      return {
        ...state,
        assignmentAgreementSelected: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
