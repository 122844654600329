import React from 'react';
import Visningsliste from './Visningsliste';
import { Route, Switch } from 'react-router-dom';
import Leads from './Leads';

const VisningslisteLayout = () => {
  return (
    <Switch>
      <Route exact path="/visningsliste/:id" component={ Visningsliste } />
      <Route path="/visningsliste/:id/visningspamelding" component={ Leads } />
    </Switch>
  );
};

export default VisningslisteLayout;
