import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Slider from './Slider';

const pStyle = {
  fontSize: 14,
  lineHeight: '1.5em',
  marginBottom: 15
};

const hStyle = {
  marginBottom: 15,
  lineHeight: '1.5em'
};

const Annonsestrategi = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <div className="l-grid__inner">
      <CSSTransition
        in={ showContent }
        timeout={ 300 }
        classNames="list-0-transition"
        unmountOnExit
      >
        <div>
          <p style={ pStyle }>I vår markedsføring benytter vi forskjellige plattformer for å nå potensielle
            interessenter. Vi har avanserte annonseringsmetoder på tvers av sosiale medier og kjente nettsteder.</p>
        </div>
      </CSSTransition>

      <CSSTransition
        in={ showContent }
        timeout={ 400 }
        classNames="list-1-transition"
        unmountOnExit
      >
        <div>
          <h3 style={ hStyle }>Hvem ser annonsene?</h3>
          <p style={ { ...pStyle, marginBottom: 30 } }>Gjennom våre analysesystemer finner vi de potensielle kjøperne
            som har størst
            sannsynlighet til å være
            interessert i ditt objekt. Annonsene målrettes bl.a. etter alder, bosted og interesse for liknende
            boligannonser.</p>
        </div>
      </CSSTransition>

      <Slider/>
    </div>
  );
};

export default Annonsestrategi;
