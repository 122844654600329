import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './Tabbar.scss';
import { setSelectType } from '../../actions';
import { connect } from 'react-redux';

const Tabbar = ({ setSelectType, tabs, count = 4 }) => {
  let location = useLocation();
  let currentTabIndex = tabs.findIndex(tab => tab.path === location.pathname + location.search);

  const tabWidth = 100 / count;

  const handleClick = (label) => {
    if (label === 'Megler') {
      setSelectType('broker');
    } else if (label === 'Kontor') {
      setSelectType('kontor');
    } else if (label === 'Søk') {
      setSelectType('sok');
    }
  };

  const items = tabs.map((item, i) => {
    const { path, label } = item;
    return (
      <li className="c-tab-bar__item" style={ { width: `${ tabWidth }%` } } key={ i }>
        <NavLink to={ path } exact onClick={ () => handleClick(label) }>{ label }</NavLink>
      </li>
    );
  });

  return (
    <ul className="c-tab-bar">
      { items }
      <span className="c-tab-bar__active-indicator"
            style={ { left: `${ currentTabIndex * tabWidth }%`, width: `${ tabWidth }%` } }/>
    </ul>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectType: (selectType) => {
      dispatch(setSelectType(selectType));
    }
  };
};

export default connect(null, mapDispatchToProps)(Tabbar);
