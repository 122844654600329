import React from 'react';
import './ApiError.scss';

const ApiError = ({ errorData }) => {
  const message = errorData.error && errorData.error.message ? errorData.error.message : 'Api Error';

  return (
    <div className="api-error">
      <div>
        { errorData.code ? <h1>{ errorData.code }</h1> : null }
        <p>{ message === 'The property belongs to the other department.' ? 'Eiendommen tilhører et annet kontor.' : message }</p>
      </div>
    </div>
  );
};

export default ApiError;
