import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import ApiService from './services/ApiService';
import { ApiServiceProvider } from './components/ApiServiceContext';
import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { ClearCacheProvider } from 'react-clear-cache';

import store from './store';

const apiService = new ApiService();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ApiServiceProvider value={ apiService }>
        {
          process.env.NODE_ENV === 'production' ?
            <ClearCacheProvider duration={ 60000 }><App/></ClearCacheProvider>
            : <App/>
        }
      </ApiServiceProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
