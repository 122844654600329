import React, { useRef, createRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const AnimatedList = ({ items, itemKey, Item, editable, onItemEdit, page = 1, nextPageRequested = false }) => {
  const itemsRef = useRef({});

  const duration = { enter: 300, exit: 300 };
  const STAGGER = 100;

  return (
    <TransitionGroup component={ null } appear>
      { items.map((item, index) => {
        itemsRef.current[index] = createRef();

        const orderNumber = nextPageRequested ? index - ((page - 1) * 20) : index;
        const order = orderNumber < 0 ? 0 : orderNumber;

        return (
          <CSSTransition
            nodeRef={ itemsRef.current[index] }
            timeout={ { enter: duration.enter + order * STAGGER, exit: duration.exit } }
            key={ itemKey ? item[itemKey] : index }
          >
            { (state) => {
              // console.log('State', state);
              return (
                <Item
                  ref={ itemsRef.current[index] }
                  state={ state }
                  order={ order }
                  item={ item }
                  editable={ editable }
                  duration={ duration }
                  stagger={ STAGGER }
                  onEdit={ () => onItemEdit(item) }
                />
              );
            } }
          </CSSTransition>
        );
      }) }
    </TransitionGroup>
  );
};

export default AnimatedList;
