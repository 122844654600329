import React, { forwardRef } from 'react';

import './Tjenester.scss';

import Team from './images/team.svg';
import Computer from './images/computer.svg';
import Notebook from './images/notebook.svg';
import Book from './images/book.svg';
import Camera from './images/camera.svg';
import Decor from './images/decor.svg';
import Media from './images/media.svg';
import Documents from './images/documents.svg';
import Tv from './images/tv.svg';
import Security from './images/security.svg';
import AnimatedList from '../../../components/AnimatedList';
import SlideUpWrapper from '../../../components/AnimatedList/SlideUpWrapper';

const Item = forwardRef(({ className, item }, ref) => {
  return (
    <li ref={ ref } className={ `c-list__item ${ className } ${ item.long ? 'c-list__item_long' : '' }` }>
      <div className="c-list__icon-box">
        <div className="c-list__icon">
          <img src={ item.image } alt="img"/>
        </div>
      </div>
      <div className={ `c-list__content ${ item.long ? 'c-list__content_long' : '' }` }>
        <h3 className="c-list__title">
          { item.title }
        </h3>
        <ul className={ `c-list__sublist ${ item.long ? 'c-list__sublist_column' : '' }` }>
          { item.subItems.map((subItem, idx) => (
            <li className="c-list__subitem" key={ idx }>
              { subItem }
            </li>
          )) }
        </ul>
      </div>
    </li>
  );
});

const StyledItem = SlideUpWrapper(Item);

const listItems = [
  {
    long: true,
    title: 'Eiendomsmegler',
    image: Team,
    subItems: [
      '- Skreddersyr salgsgangen til ditt hjem;',
      '- Finner målgruppen til ditt hjem;',
      '- Rådgiver i prossessen, fra start til slutt;',
      '- Tilrettelegger for smidig salgsprossess;',
      '- Utarbeider annonser/salgsoppgave;',
      '- Finner målgruppen til ditt hjem;',
      '- Kontakter og følger opp alle interessenter;',
      '- Gjennomfører visninger;',
      '- Avholder budrunde;',
      '- Gjennomfører kontraktsmøte med kjøper og selger.'
    ]
  },
  {
    title: 'Back-end',
    image: Computer,
    subItems: [
      '- Bistår megler i det daglige med relevante problemstillinger og arbeidsoppgaver knyttet til ditt hjem.'
    ]
  },
  {
    title: 'Takstmann',
    image: Notebook,
    subItems: [
      '- Gjennomgang av ditt hjem;',
      '- Utarbeide boligsalgsrapport/planskisser.'
    ]
  },
  {
    title: 'Trykkeri',
    image: Book,
    subItems: [
      '- Leverer alt av salgsmateriell til ditt hjem (salgsoppgaver, plakater).'
    ]
  },
  {
    title: 'Styling',
    image: Decor,
    subItems: [
      '- Tar kvalitetsbilder av ditt hjem levert i webog trykk-kvalitet, dag og/ kveldsfoto.'
    ]
  },
  {
    title: 'Fotograf',
    image: Camera,
    subItems: [
      '- Tar kvalitetsbilder av ditt hjem levert i webog trykk-kvalitet, dag og/ kveldsfoto.'
    ]
  },
  {
    title: 'Mediebyra',
    image: Media,
    subItems: [
      '- Klargjør materiell for annonsering i digitale medier, som Facebook, Instagram, VG, DB, E24.'
    ]
  },
  {
    title: 'Oppgjørsavdeling',
    image: Documents,
    subItems: [
      '- Forestår oppgjøret av ditt hjem;',
      '- Sørger for innbetaling fra kjøper før overtakelse;',
      '- Innfrir lån på ditt hjem;',
      '- Tinglyser dokumenter;',
      '- Utbetaler restsummen til boligeier;',
      '- Sluttfører/arkiverer.'
    ]
  },
  {
    title: 'Annonsering',
    image: Tv,
    subItems: [
      '- Hjemmet ditt annonseres i relevante nettportaler som fnn.no og schalapartners.no, samt på Facebook, Instagram, VG, DB, E24 - og i vår app «Mitthjem».'
    ]
  },
  {
    title: 'Kommune / Forretningsfører M. M.',
    image: Security,
    subItems: [
      '- Levere all relevant dokumentasjon: reguleringskart, ferdigattester, vedtekter, husordensregler, servitutter- med mye mer.'
    ]
  }
];

const Tjenester = () => {
  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info">
          <div className="l-grid__section l-grid__section_overflow">
            <ul className="c-list c-list_width975 c-list_width-auto">
              <AnimatedList items={ listItems } Item={ StyledItem }/>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tjenester;
