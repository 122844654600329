const holidays = {
  events: [
    { start: '2022-01-01', title: 'Første nyttårsdag' },
    { start: '2022-05-01', title: 'Offentlig høytidsdag' },
    { start: '2022-05-17', title: 'Grunnlovsdag' },
    { start: '2022-12-25', title: 'Første juledag' },
    { start: '2022-12-26', title: 'Andre juledag' },
    { start: '2022-04-10', title: 'Palmesøndag' },
    { start: '2022-04-14', title: 'Skjærtorsdag' },
    { start: '2022-04-15', title: 'Langfredag' },
    { start: '2022-04-17', title: 'Første påskedag' },
    { start: '2022-04-18', title: 'Andre påskedag' },
    { start: '2022-05-26', title: 'Kristi himmelfartsdag' },
    { start: '2022-06-05', title: 'Første pinsedag' },
    { start: '2022-06-06', title: 'Andre pinsedag' },
    { start: '2023-01-01', title: 'Første nyttårsdag' },
    { start: '2023-05-01', title: 'Offentlig høytidsdag' },
    { start: '2023-05-17', title: 'Grunnlovsdag' },
    { start: '2023-12-25', title: 'Første juledag' },
    { start: '2023-12-26', title: 'Andre juledag' },
    { start: '2023-04-02', title: 'Palmesøndag' },
    { start: '2023-04-06', title: 'Skjærtorsdag' },
    { start: '2023-04-07', title: 'Langfredag' },
    { start: '2023-04-09', title: 'Første påskedag' },
    { start: '2023-04-10', title: 'Andre påskedag' },
    { start: '2023-05-18', title: 'Kristi himmelfartsdag' },
    { start: '2023-05-28', title: 'Første pinsedag' },
    { start: '2023-05-29', title: 'Andre pinsedag' },
    { start: '2024-01-01', title: 'Første nyttårsdag' },
    { start: '2024-05-01', title: 'Offentlig høytidsdag' },
    { start: '2024-05-17', title: 'Grunnlovsdag' },
    { start: '2024-12-25', title: 'Første juledag' },
    { start: '2024-12-26', title: 'Andre juledag' }, 
    { start: '2024-03-24', title: 'Palmesøndag' },
    { start: '2024-03-28', title: 'Skjærtorsdag' }, 
    { start: '2024-03-29', title: 'Langfredag' }, 
    { start: '2024-03-31', title: 'Første påskedag' }, 
    { start: '2024-04-01', title: 'Andre påskedag' }, 
    { start: '2024-05-09', title: 'Kristi himmelfartsdag' }, 
    { start: '2024-05-19', title: 'Første pinsedag' }, 
    { start: '2024-05-20', title: 'Andre pinsedag' },
    { start: '2025-01-01', title: 'Første nyttårsdag' }, 
    { start: '2025-05-01', title: 'Offentlig høytidsdag' }, 
    { start: '2025-05-17', title: 'Grunnlovsdag' }, 
    { start: '2025-12-25', title: 'Første juledag' }, 
    { start: '2025-12-26', title: 'Andre juledag' }, 
    { start: '2025-04-13', title: 'Palmesøndag' }, 
    { start: '2025-04-17', title: 'Skjærtorsdag' },
    { start: '2025-04-18', title: 'Langfredag' }, 
    { start: '2025-04-20', title: 'Første påskedag' }, 
    { start: '2025-04-21', title: 'Andre påskedag' }, 
    { start: '2025-05-29', title: 'Kristi himmelfartsdag' }, 
    { start: '2025-06-08', title: 'Første pinsedag' }, 
    { start: '2025-06-09', title: 'Andre pinsedag' },
    { start: '2026-01-01', title: 'Første nyttårsdag' },
    { start: '2026-05-01', title: 'Offentlig høytidsdag' }, 
    { start: '2026-05-17', title: 'Grunnlovsdag' }, 
    { start: '2026-12-25', title: 'Første juledag' }, 
    { start: '2026-12-26', title: 'Andre juledag' }, 
    { start: '2026-03-29', title: 'Palmesøndag' },
    { start: '2026-04-02', title: 'Skjærtorsdag' }, 
    { start: '2026-04-03', title: 'Langfredag' }, 
    { start: '2026-04-05', title: 'Første påskedag' }, 
    { start: '2026-04-06', title: 'Andre påskedag' }, 
    { start: '2026-05-14', title: 'Kristi himmelfartsdag' },
    { start: '2026-05-24', title: 'Første pinsedag' }, 
    { start: '2026-05-25', title: 'Andre pinsedag' },
    { start: '2027-01-01', title: 'Første nyttårsdag' }, 
    { start: '2027-05-01', title: 'Offentlig høytidsdag' }, 
    { start: '2027-05-17', title: 'Grunnlovsdag' }, 
    { start: '2027-12-25', title: 'Første juledag' }, 
    { start: '2027-12-26', title: 'Andre juledag' }, 
    { start: '2027-03-21', title: 'Palmesøndag' }, 
    { start: '2027-03-25', title: 'Skjærtorsdag' }, 
    { start: '2027-03-26', title: 'Langfredag' }, 
    { start: '2027-03-28', title: 'Første påskedag' },
    { start: '2027-03-29', title: 'Andre påskedag' }, 
    { start: '2027-05-06', title: 'Kristi himmelfartsdag' },
    { start: '2027-05-16', title: 'Første pinsedag' }, 
    { start: '2027-05-17', title: 'Andre pinsedag' },
    { start: '2028-01-01', title: 'Første nyttårsdag' },
    { start: '2028-05-01', title: 'Offentlig høytidsdag' }, 
    { start: '2028-05-17', title: 'Grunnlovsdag' },
    { start: '2028-12-25', title: 'Første juledag' },
    { start: '2028-12-26', title: 'Andre juledag' },
    { start: '2028-04-09', title: 'Palmesøndag' },
    { start: '2028-04-13', title: 'Skjærtorsdag' },
    { start: '2028-04-14', title: 'Langfredag' },
    { start: '2028-04-16', title: 'Første påskedag' },
    { start: '2028-04-17', title: 'Andre påskedag' },
    { start: '2028-05-25', title: 'Kristi himmelfartsdag' },
    { start: '2028-06-04', title: 'Første pinsedag' },
    { start: '2028-06-05', title: 'Andre pinsedag' }
  ],
  color: '#DBDBDB',
  textColor: '#323232'
};

export default holidays;
