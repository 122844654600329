import React from 'react';
import './Broker.scss';
import PropertyList from '../../../components/PropertyList';
import { useLocation } from 'react-router-dom';

function Broker() {
  let location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <div className="l-grid__section l-grid__section_overflow">
      <div className="c-property pt-0">
        <PropertyList visning={ query.get('type') === 'visning' } />
      </div>
    </div>
  )
}

export default Broker;
