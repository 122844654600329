import React from 'react';
import { useClearCacheCtx } from 'react-clear-cache';
import './Toast.scss';

const Toast = ({ onSkip }) => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  if (!isLatestVersion) {
    return (
      <div className="toast">
        <p>Ny versjon tilgjengelig</p>
        <button
          className="button button-sm ml-2"
          onClick={ (e) => {
            e.preventDefault();
            emptyCacheStorage();
          } }
        >
          Oppdater
        </button>
        <button className="button button-sm ml-2" onClick={ onSkip }>
          Seinere
        </button>
      </div>
    );
  }
  return null;
};

export default Toast;
