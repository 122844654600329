import React, { useEffect, useState } from 'react';
import './NumPad.scss';

const NumPad = ({ onClear, onSubmit }) => {
  const [pincode, setPincode] = useState('');

  const updatePincode = (num) => {
    if (pincode.length < 4) {
      setPincode(pincode + num);
      if (pincode.length === 3) {
        onSubmit(pincode + num);
      }
    }
  };

  const clearPincode = () => {
    setPincode('');
    onClear();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (pincode.length === 4) {
        setPincode('');
      }
    }, 200);
    return () => clearTimeout(timeout);
  }, [pincode]);

  return (
    <div className="num-pad">
      <div className="dots">
        <div className={ `dot ${ pincode.length > 0 ? 'active' : '' }` }/>
        <div className={ `dot ${ pincode.length > 1 ? 'active' : '' }` }/>
        <div className={ `dot ${ pincode.length > 2 ? 'active' : '' }` }/>
        <div className={ `dot ${ pincode.length > 3 ? 'active' : '' }` }/>
      </div>
      <div className="pads">
        <div className="pad" onClick={ () => updatePincode('1') }>1</div>
        <div className="pad" onClick={ () => updatePincode('2') }>2</div>
        <div className="pad" onClick={ () => updatePincode('3') }>3</div>
        <div className="pad" onClick={ () => updatePincode('4') }>4</div>
        <div className="pad" onClick={ () => updatePincode('5') }>5</div>
        <div className="pad" onClick={ () => updatePincode('6') }>6</div>
        <div className="pad" onClick={ () => updatePincode('7') }>7</div>
        <div className="pad" onClick={ () => updatePincode('8') }>8</div>
        <div className="pad" onClick={ () => updatePincode('9') }>9</div>
        <div className="spacer"/>
        <div className="pad" onClick={ () => updatePincode('0') }>0</div>
        <div className="pad" onClick={ () => clearPincode() }>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 5.00162L19.9984 4L12.5 11.4984L5.00162 4L4 5.00162L11.4984 12.5L4 19.9984L5.00162 21L12.5 13.5016L19.9984 21L21 19.9984L13.5016 12.5L21 5.00162Z"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NumPad;
