import React, { Component } from 'react';

import './Visningsliste.scss';
import { fetchPropertyDetails } from '../../actions';
import { compose } from '../../utils';
import { withApiService } from '../../components/HOC';
import { connect } from 'react-redux';
import ApiError from '../../components/ApiError';
import VisningslisteForm from './VisningslisteForm/VisningslisteForm';

class Visningsliste extends Component {
  componentDidMount() {
    const { propertyDetails } = this.props;
    const id = this.props.match.params.id;
    if (id) {
      if (!propertyDetails || propertyDetails.id !== id) {
        this.props.fetchPropertyDetails(id);
      }
    }
  }

  render() {
    const { selectType, propertyDetails, loading, error } = this.props;

    if (loading || !propertyDetails) {
      return (
        <div className="l-grid l-grid_bg">
          <div>Loading...</div>
        </div>
      );
    }

    if (error) {
      return (
        <ApiError errorData={ error }/>
      );
    }

    return (
      <div className="l-grid l-grid_bg">
        <VisningslisteForm selectType={ selectType } propertyDetails={ propertyDetails }/>
      </div>
    );
  }
}

const mapStateToProps = ({ selectType, propertyDetails, loading, error }) => {
  return { selectType, propertyDetails, loading, error };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchPropertyDetails: fetchPropertyDetails(apiService, dispatch)
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Visningsliste);
