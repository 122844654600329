import React, { useEffect, useState } from 'react';
import { withApiService } from '../../../components/HOC';
import { Link, useHistory, useParams } from 'react-router-dom';
import { compose, dayjs } from '../../../utils';
import Sprite from '../../../components/App/images/sprites/sprite.svg';
import { CSSTransition } from 'react-transition-group';
import { setVisningForm } from '../../../actions';
import { connect } from 'react-redux';
import ConfirmModal from './ConfirmModal';
import InfoModal from '../../../components/InfoModal';
import './Leads.scss';
import logo from '../../../components/App/images/partners-logo-white.png';
import he from 'he';

const transitionsTimeout = 300;
const listFastTransitionsTimeout = 200;

const Leads = ({ propertyDetails, apiService, setVisningForm }) => {
  let history = useHistory();
  let { id } = useParams();
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showList, setShowList] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalSuccess, setInfoModalSuccess] = useState(true);
  const [infoModalMessage, setInfoModalMessage] = useState('');

  const departmentLogo =
    propertyDetails &&
    propertyDetails.broker &&
    propertyDetails.broker.department &&
    propertyDetails.broker.department.logo ? propertyDetails.broker.department.logo : null;

  useEffect(() => {
    apiService.getLeads(id).then(data => {
      setLeads(data);
      setLoading(false);
      setShowList(true);
    });

    // eslint-disable-next-line
  }, []);

  const handlePrefillForm = (lead) => {
    setVisningForm({
      phone: lead.phone,
      post_number: lead.post_number,
      name: lead.name,
      email: lead.email,
      message: lead.message ? he.decode(lead.message.replace(/(?:\r\n|\r|\n)/g, ' ')) : '',
      subscribe_email: lead.subscribe_email ? 1 : 0,
      book_visning: 1,
      contact_me: lead.contact_me ? 1 : 0,
      send_sms: lead.send_sms ? 1 : 0,
      i_agree: 1
    });
    history.push(`/visningsliste/${ id }`);
  };

  const openConfirmModal = (leadId) => {
    setSelectedLeadId(leadId);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setSelectedLeadId(null);
    setShowConfirmModal(false);
  };

  const openInfoModal = (isSuccess, message) => {
    setInfoModalSuccess(isSuccess);
    setInfoModalMessage(message);
    setShowInfoModal(true);
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  const handleUnsubscribe = () => {
    apiService.unsubscribeLead(selectedLeadId)
      .then(res => {
        closeConfirmModal();
        openInfoModal(true, res.data.data.message);
        apiService.getLeads(id).then(data => {
          setLeads(data);
        });
      })
      .catch(err => {
        closeConfirmModal();
        openInfoModal(false, err.response.data.message || err.response.data.name);
      });
  };

  if (loading) {
    return (
      <div className="l-grid l-grid_bg">
        <div className="l-grid__content">
          <div className="l-grid__inner l-grid__inner_pb0">
            <div style={ {
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            } }>
              <div>Loading...</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="l-grid l-grid_bg">
      <div className="l-grid__content">
        <div className="l-grid__inner l-grid__inner_pb0 leads-list">

          <CSSTransition
            in={ showList }
            timeout={ 800 }
            classNames="top-button-transition"
            unmountOnExit
          >
            <Link to={ `/visningsliste/${ id }` } className="back-link">
              <div className="block-back block-back_single">
                <svg width="15" height="15">
                  <use href={ Sprite + '#back' }/>
                </svg>
              </div>
            </Link>
          </CSSTransition>

          <div className="leads-list-wrapper position-relative">
            <CSSTransition
              in={ showList }
              timeout={ 800 }
              classNames="top-button-transition"
              unmountOnExit
            >
              <Link to={ `/visningsliste/${ id }` } className="back-link-mobile">
                <div className="block-back block-back_single">
                  <svg width="15" height="15">
                    <use href={ Sprite + '#back' }/>
                  </svg>
                </div>
              </Link>
            </CSSTransition>

            {
              leads.length ? (
                <CSSTransition
                  in={ showList }
                  timeout={ transitionsTimeout }
                  classNames={ `list-0-transition` }
                  unmountOnExit
                >
                  <div className="leads-list-heading">
                    <div style={ { padding: '0 40px', flexGrow: 1 } }>Navn</div>
                    <div style={ { padding: '0 40px', width: 210, minWidth: 210 } }>Klokkeslett</div>
                    <div style={ { padding: '0 40px', width: 292, minWidth: 292 } }>Handlinger</div>
                  </div>
                </CSSTransition>
              ) : null
            }

            <CSSTransition
              in={ showList }
              timeout={ transitionsTimeout }
              classNames={ `list-0-transition` }
              unmountOnExit
            >
              {
                leads.length ? (
                  <div className="block-visninglist__logo">
                    <img src={ departmentLogo || logo } alt="logo"/>
                  </div>
                ) : (
                  <div style={ { width: '100%', textAlign: 'center', marginTop: 15 } }>
                    Visninger ikke funnet
                  </div>
                )
              }
            </CSSTransition>

            { leads.map((lead, index) => (
              <CSSTransition
                key={ lead.id }
                in={ showList }
                timeout={ (index + 1) * 100 + listFastTransitionsTimeout }
                classNames={ `list-fast-${ index + 1 }-transition` }
                unmountOnExit
              >
                <div className="leads-list-item">
                  <div className="leads-list-item-name">
                    { lead.name }
                    <br/>
                    <span className="leads-list-item-phone">{ lead.phone }</span>
                  </div>

                  <div className="leads-list-item-date">
                    { lead.booking_date ? dayjs.unix(lead.booking_date).format('D. MMM kl. HH:mm') : '' }
                  </div>

                  <div className={ `leads-list-item-buttons ${ lead.booking_date ? '' : 'p-0' }` }>
                    { lead.booking_date ? (
                      <>
                        <button
                          className="button button-default disabled-transparent d-inline-block"
                          disabled={ !!lead.deltatt }
                          onClick={ () => handlePrefillForm(lead) }
                        >
                          Deltatt
                        </button>
                        <button
                          className="button button-default disabled-transparent button-red d-inline-block ml-2"
                          onClick={ () => openConfirmModal(lead.id) }
                        >
                          Meld av
                        </button>
                      </>
                    ) : null }
                  </div>
                </div>
              </CSSTransition>
            )) }
          </div>

        </div>
      </div>

      <CSSTransition
        in={ showConfirmModal }
        timeout={ 300 }
        classNames="modal-transition"
        unmountOnExit
      >
        <ConfirmModal
          centered
          onConfirm={ handleUnsubscribe }
          onCancel={ closeConfirmModal }
        />
      </CSSTransition>

      <CSSTransition
        in={ showInfoModal }
        timeout={ 300 }
        classNames="modal-transition"
        unmountOnExit
      >
        <InfoModal
          centered
          onClose={ closeInfoModal }
          success={ infoModalSuccess }
          message={ infoModalMessage }
        />
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = ({ propertyDetails }) => {
  return { propertyDetails };
};

const mapDispatchToProps = { setVisningForm };

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Leads);
