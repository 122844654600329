import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../components/App/images/partners-logo-white.png';

import Sprite from '../../../components/App/images/sprites/sprite.svg';
import ListIcon from '../../../components/App/images/icons/list-white.svg';
import { compose, formatter } from '../../../utils';
import { withApiService } from '../../../components/HOC';
import CustomInput from '../../../components/CustomInput';
import InfoModal from '../../../components/InfoModal';
import { CSSTransition } from 'react-transition-group';
import InputLoader from '../../../components/InputLoader';
import { connect } from 'react-redux';
import SelectBankModal from '../SelectBankModal';

const transitionsTimeout = 500;

class VisningslisteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      post_number: '',
      name: '',
      email: '',
      message: '',
      subscribe_email: 0, // Jeg ønsker eiendomsrelatert nyhetsbrev på e-post
      book_visning: 1, // Jeg har lest og forstått informasjonen om Coronavirus
      is_want_financing: 0, // Jeg ønsker tilbud på finansiering
      bank_id: null,
      bank_broker_id: null,
      contact_me: 0, // Jeg skal selge bolig og vil bli kontaktet
      send_sms: 1, // Jeg ønsker budvarsel på sms
      i_agree: 1, // Jeg har lest og godkjent vilkårene,
      just_view: 0, // Registrering kun for smittesporing/covid-19. Oppføring slettes etter 30 dager.
      subscribe_to_related_properties: 0, // Jeg ønsker tips om liknende eiendommer på e-post
      errors: {},
      searching: false,
      showSuccessModal: false,
      showSelectBankModal: false,
      showForm: false,
      pending: false
    };
  }

  componentDidMount() {
    const { visningForm } = this.props;

    if (visningForm) {
      this.setState({
        ...visningForm,
        showForm: true
      });
    } else {
      this.setState({
        phone: '',
        post_number: '',
        name: '',
        email: '',
        message: '',
        subscribe_email: 0,
        book_visning: 1,
        is_want_financing: 0,
        bank_id: null,
        bank_broker_id: null,
        contact_me: 0,
        send_sms: 1,
        i_agree: 1,
        just_view: 0,
        subscribe_to_related_properties: 0,
        showForm: true,
        pending: false
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { propertyDetails, apiService } = this.props;
    const data = {
      ...this.state,
      type: 'visningliste',
      target_id: +propertyDetails.id,
      post_number: +this.state.post_number
    };
    delete data.errors;
    delete data.showSuccessModal;
    delete data.showSelectBankModal;
    delete data.showForm;
    delete data.pending;
    if (!this.state.post_number) {
      delete data.post_number;
    }
    if (!this.state.is_want_financing) {
      delete data.bank_id;
      delete data.bank_broker_id;
    }

    this.setState({ pending: true });
    apiService.sendContact(data)
      .then(() => {
        this.setState({ showSuccessModal: true, pending: false });
      })
      .catch(error => {
        this.setState({ pending: false });
        if (error && error.response && error.response.data) {
          const errors = error.response.data.data.errors;
          if (errors) {
            this.setState({ errors: errors });
          }
        }
      });
  };

  clearErrors = (paramName) => {
    if (this.state.errors[paramName]) {
      this.setState(state => {
        return {
          errors: {
            ...state.errors,
            [paramName]: []
          }
        };
      });
    }
  };

  toggleCheckbox = (paramName) => {
    this.clearErrors(paramName);
    this.setState(state => {
      return { [paramName]: state[paramName] === 0 ? 1 : 0 };
    });
  };

  handleChangeValue = (paramName, value) => {
    this.clearErrors(paramName);
    this.setState({ [paramName]: value });
  };

  handleChangePhone = (value) => {
    const { apiService } = this.props;
    this.setState({ phone: value });
    if ((value.includes('+') && value.length > 10) || (!value.includes('+') && value.length > 7)) {
      this.setState({ searching: true });
      apiService.searchClient(value)
        .then((res) => {
          this.setState({ searching: false });
          this.clearErrors('phone');
          if (res.data && res.data.client) {
            this.setState({
              post_number: res.data.client.post_number,
              name: res.data.client.name,
              email: res.data.client.email
            });
          }
        })
        .catch(error => {
          this.setState({ searching: false });
          if (error && error.data) {
            const errors = error.data.errors;
            if (errors) {
              this.setState({
                post_number: '',
                name: '',
                email: '',
                errors: errors
              });
            }
          }
        });
    }
  };

  render() {
    const { propertyDetails, selectType } = this.props;
    const departmentLogo =
      propertyDetails &&
      propertyDetails.broker &&
      propertyDetails.broker.department &&
      propertyDetails.broker.department.logo ? propertyDetails.broker.department.logo : null;
    const hasBanks =
      propertyDetails &&
      propertyDetails.broker &&
      propertyDetails.broker.department &&
      propertyDetails.broker.department.banks &&
      propertyDetails.broker.department.banks.length;
    const banks = hasBanks ? propertyDetails.broker.department.banks : [];
    const {
      phone,
      post_number,
      name,
      email,
      message,
      i_agree,
      send_sms,
      subscribe_email,
      is_want_financing,
      contact_me,
      subscribe_to_related_properties,
      errors,
      showForm,
      showSuccessModal,
      showSelectBankModal,
      searching,
      pending
    } = this.state;

    return (
      <>
        <div className="l-grid__content">
          <div className="l-grid__inner l-grid__inner_pb0">
            <div className="l-grid__block">
              <div className="l-grid__info">
                <div className="l-grid__section l-grid__section_overflow l-grid__section_mt0 position-relative">
                  <CSSTransition
                    in={ showForm }
                    timeout={ 800 }
                    classNames="top-button-transition"
                    unmountOnExit
                  >
                    <Link to={ `/select/${ selectType }?type=visning` } className="d-inline-block position-relative">
                      <div className="block-back block-back_single">
                        <svg width="15" height="15">
                          <use href={ Sprite + '#back' }/>
                        </svg>
                      </div>
                    </Link>
                  </CSSTransition>

                  <CSSTransition
                    in={ showForm }
                    timeout={ 800 }
                    classNames="top-button-transition"
                    unmountOnExit
                  >
                    <Link to={ `/visningsliste/${ propertyDetails.id }/visningspamelding` }
                          style={ { position: 'absolute', top: 0, right: 0 } }>
                      <div className="block-back block-back_single">
                        <img width={ 18 } src={ ListIcon } alt=""/>
                      </div>
                    </Link>
                  </CSSTransition>

                  <div className="block-visninglist">
                    <div className="block-visninglist__header">
                      <CSSTransition
                        in={ showForm }
                        timeout={ transitionsTimeout }
                        classNames="form-0-transition"
                        unmountOnExit
                      >
                        <div className="block-visninglist__adress">
                          { propertyDetails.adresse }, { propertyDetails.postnummer } { propertyDetails.poststed }
                          <p className="block-visninglist__price">
                            { formatter.format(propertyDetails.totalpris) }
                          </p>
                        </div>
                      </CSSTransition>
                      <CSSTransition
                        in={ showForm }
                        timeout={ transitionsTimeout }
                        classNames="form-0-transition"
                        unmountOnExit
                      >
                        <div className="block-visninglist__logo">
                          <img src={ departmentLogo || logo } alt="logo"/>
                        </div>
                      </CSSTransition>
                    </div>
                    <div className="block-visninglist__body">
                      <div className="block-visninglist__form">
                        <form onSubmit={ this.handleSubmit }>
                          <CSSTransition
                            in={ showForm }
                            timeout={ 100 + transitionsTimeout }
                            classNames="form-1-transition"
                            unmountOnExit
                          >
                            <div>
                              <div className="block-visninglist__form-row">
                                { searching ? (
                                  <div style={ { position: 'absolute', right: 36, top: -12 } }>
                                    <InputLoader/>
                                  </div>
                                ) : null }
                                <CustomInput
                                  type="tel"
                                  id="telefon"
                                  label="Telefon *"
                                  value={ phone }
                                  errors={ errors.phone }
                                  onChange={ (event) => this.handleChangePhone(event.target.value) }
                                />
                              </div>

                              <div className="block-visninglist__form-row" style={ { display: 'none' } }>
                                <CustomInput
                                  type="number"
                                  id="postnummer"
                                  label="Postnummer *"
                                  value={ post_number }
                                  errors={ errors.post_number }
                                  onChange={ (event) => this.handleChangeValue('post_number', event.target.value) }
                                />
                              </div>

                              <div className="block-visninglist__form-row">
                                <CustomInput id="navn" label="Navn *" value={ name } errors={ errors.name }
                                             onChange={ (event) => this.handleChangeValue('name', event.target.value) }/>
                              </div>

                              <div className="block-visninglist__form-row">
                                <CustomInput id="epost" label="E-post" value={ email } errors={ errors.email }
                                             onChange={ (event) => this.handleChangeValue('email', event.target.value) }/>
                              </div>

                              <div className="block-visninglist__form-row">
                                <CustomInput id="melding" label="Melding" value={ message } errors={ errors.message }
                                             onChange={ (event) => this.handleChangeValue('message', event.target.value) }/>
                              </div>
                            </div>
                          </CSSTransition>
                          <CSSTransition
                            in={ showForm }
                            timeout={ 200 + transitionsTimeout }
                            classNames="form-2-transition"
                            unmountOnExit
                          >
                            <div className="block-visninglist__form-checkboxes">
                              <div className="checkbox-row flex-wrap mb-1">
                                <input type="checkbox" id="e-post"
                                       checked={ subscribe_email }
                                       onChange={ () => this.toggleCheckbox('subscribe_email') }
                                       className="checkbox-row__item checkbox-row__item_hidden"
                                       name="e-post"/>
                                <label htmlFor="e-post"
                                       className="checkbox-row__label checkbox-row__label_whole checkbox-row__label_w100">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  Jeg ønsker eiendomsrelatert nyhetsbrev på e-post
                                </label>
                              </div>

                              <div className="checkbox-row flex-wrap mb-1">
                                <input type="checkbox" id="kontaktet"
                                       checked={ contact_me }
                                       onChange={ () => this.toggleCheckbox('contact_me') }
                                       className="checkbox-row__item checkbox-row__item_hidden"
                                       name="kontaktet"/>
                                <label htmlFor="kontaktet"
                                       className="checkbox-row__label checkbox-row__label_whole checkbox-row__label_w100">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  Jeg skal selge bolig og vil bli kontaktet
                                </label>
                              </div>

                              <div className="checkbox-row flex-wrap mb-1">
                                <input type="checkbox" id="related"
                                       checked={ subscribe_to_related_properties }
                                       onChange={ () => this.toggleCheckbox('subscribe_to_related_properties') }
                                       className="checkbox-row__item checkbox-row__item_hidden"
                                       name="related"/>
                                <label htmlFor="related"
                                       className="checkbox-row__label checkbox-row__label_whole checkbox-row__label_w100">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  Jeg ønsker tips om liknende eiendommer på e-post
                                </label>
                              </div>

                              <div className="checkbox-row flex-wrap checkbox-row_w48 mb-1">
                                <input type="checkbox"
                                       id="sms2"
                                       className="checkbox-row__item checkbox-row__item_hidden"
                                       name="sms2"
                                       checked={ send_sms }
                                       onChange={ () => this.toggleCheckbox('send_sms') }/>
                                <label htmlFor="sms2"
                                       className="checkbox-row__label checkbox-row__label_whole checkbox-row__label_w100">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  Jeg ønsker budvarsel på sms
                                </label>
                              </div>
                              <div className="checkbox-row flex-wrap checkbox-row_w48 mb-1">
                                <input type="checkbox" id="vilkårene" checked={ i_agree }
                                       onChange={ () => this.toggleCheckbox('i_agree') }
                                       className="checkbox-row__item checkbox-row__item_hidden" name="vilkårene"/>
                                <label htmlFor="vilkårene"
                                       className="checkbox-row__label checkbox-row__label_whole checkbox-row__label_w100">
                                  <svg width="14" height="12">
                                    <use href={ Sprite + '#check' }/>
                                  </svg>
                                  Jeg har lest og godkjent vilkårene *
                                </label>
                                {
                                  errors.i_agree ?
                                    errors.i_agree.map((error, idx) => (
                                      <div key={ idx } className="form-error form-error-small">{ error }</div>)
                                    ) : null
                                }
                              </div>

                              { hasBanks ? (
                                <div className="checkbox-row flex-wrap checkbox-row_w48">
                                  <input type="checkbox"
                                         id="financing"
                                         className="checkbox-row__item checkbox-row__item_hidden"
                                         name="financing"
                                         checked={ is_want_financing }
                                         onChange={ (e) => {
                                           this.toggleCheckbox('is_want_financing');
                                           if (e.target.checked) {
                                             this.setState({ showSelectBankModal: true });
                                           }
                                         } }/>
                                  <label htmlFor="financing"
                                         className="checkbox-row__label checkbox-row__label_whole checkbox-row__label_w100">
                                    <svg width="14" height="12">
                                      <use href={ Sprite + '#check' }/>
                                    </svg>
                                    Jeg ønsker tilbud på finansiering
                                  </label>
                                </div>
                              ) : null }
                            </div>
                          </CSSTransition>
                          <CSSTransition
                            in={ showForm }
                            timeout={ 300 + transitionsTimeout }
                            classNames="form-3-transition"
                            unmountOnExit
                          >
                            <div>
                              <button type="submit"
                                      className="button button-default button-default_w100 button-default_padding"
                                      disabled={ !phone || !name || pending }>
                                { pending ? (
                                  <div style={ { height: 24, position: 'relative', top: -8 } }>
                                    <InputLoader/>
                                  </div>
                                ) : 'Registrer' }
                              </button>
                            </div>
                          </CSSTransition>
                        </form>
                      </div>
                      { propertyDetails.broker ?
                        <div className="block-visninglist__manager">
                          <CSSTransition
                            in={ showForm }
                            timeout={ 100 + transitionsTimeout }
                            classNames="form-1-transition"
                            unmountOnExit
                          >
                            <div className="block-visninglist__manager-img">
                              <img src={ propertyDetails.broker.avatar }
                                   alt="manager"/>
                            </div>
                          </CSSTransition>
                          <div className="block-visninglist__manager-content">
                            <CSSTransition
                              in={ showForm }
                              timeout={ 200 + transitionsTimeout }
                              classNames="form-2-transition"
                              unmountOnExit
                            >
                              <p className="block-visninglist__manager-name">
                                { propertyDetails.broker.navn }
                              </p>
                            </CSSTransition>
                            <CSSTransition
                              in={ showForm }
                              timeout={ 200 + transitionsTimeout }
                              classNames="form-2-transition"
                              unmountOnExit
                            >
                              <p className="block-visninglist__manager-specialization">
                                { propertyDetails.broker.tittel }
                              </p>
                            </CSSTransition>
                            <CSSTransition
                              in={ showForm }
                              timeout={ 300 + transitionsTimeout }
                              classNames="form-3-transition"
                              unmountOnExit
                            >
                              <p className="block-visninglist__manager-phone">
                                { propertyDetails.broker.mobiltelefon }
                              </p>
                            </CSSTransition>
                          </div>
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <CSSTransition
            in={ showSelectBankModal }
            timeout={ 300 }
            classNames="modal-transition"
            unmountOnExit
          >
            <SelectBankModal
              banks={ banks }
              onClose={ () => {
                this.setState({
                  showSelectBankModal: false,
                  is_want_financing: 0,
                  bank_id: null,
                  bank_broker_id: null
                });
              } }
              onConfirm={ ({ bankId, brokerId }) => {
                this.setState({
                  showSelectBankModal: false,
                  bank_id: +bankId,
                  bank_broker_id: +brokerId
                });
              } }
            />
          </CSSTransition>
        }

        {
          <CSSTransition
            in={ showSuccessModal }
            timeout={ 300 }
            classNames="modal-transition"
            unmountOnExit
          >
            <InfoModal
              centered
              onClose={ () => this.setState({
                phone: '',
                post_number: '',
                name: '',
                email: '',
                message: '',
                subscribe_email: 0,
                book_visning: 1,
                is_want_financing: 0,
                bank_id: null,
                bank_broker_id: null,
                contact_me: 0,
                send_sms: 1,
                i_agree: 1,
                just_view: 0,
                subscribe_to_related_properties: 0,
                errors: {},
                searching: false,
                showSuccessModal: false,
                showSelectBankModal: false
              }) }
              success={ true }
              message={ 'Skjemaet er sendt!' }
            />
          </CSSTransition>
        }
      </>
    );
  }
}

const mapStateToProps = ({ visningForm }) => {
  return { visningForm };
};

export default compose(
  withApiService(),
  connect(mapStateToProps)
)(VisningslisteForm);
