import React, { forwardRef } from 'react';
import Finn from './images/img.png';
import Social from './images/img-1.png';
import Site from './images/img-2.png';
import Newspaper from './images/img-3.png';
import Design from './images/img-4.png';
import Video from './images/img-5.png';
import Hjem from './images/img-6.png';
import Intra from './images/img-7.png';

import './Reklame.scss';
import SlideUpWrapper from '../../../components/AnimatedList/SlideUpWrapper';
import AnimatedList from '../../../components/AnimatedList';

const Item = forwardRef(({ className, item }, ref) => {
  return (
    <li ref={ ref } className={ `c-list__item c-list__item_2column ${ className }` }>
      <div className="c-list__icon-box">
        <div className="c-list__icon">
          <img src={ item.image } alt="img"/>
        </div>
      </div>
      <div className="c-list__content c-list__content_mt30">
        <p className={ `c-list__desc ${ item.long ? 'c-list__desc_long' : '' }` }>
          { item.desc }
        </p>
      </div>
    </li>
  );
});

const StyledItem = SlideUpWrapper(Item);

const listItems = [
  {
    image: Finn,
    desc: 'Norges desidert største markedsplass for eiendom der vi dekker interessenter som søker i ditt område.'
  },
  {
    long: true,
    image: Social,
    desc: 'Med målrettet annonsering på Instagram og Facebook, når vi dine potensielle kjøpere. Sosiale medier skaper svært mye aktivitet rundt ditt boligsalg.'
  },
  {
    image: Site,
    desc: 'Et av markedets mest innovative digitale prospekt, i form av en egen nettside for din bolig. Dette gjør det enkelt for interessenter å få informasjon om din bolig uansett hvor de er.'
  },
  {
    long: true,
    image: Newspaper,
    desc: 'Dine annonser vises på store norske nettsider og lokalaviser. Ved hjelp av våre analysesystem fnner vi de potensielle kjøperne som har størst sannsynlighet til å være interessert i ditt objekt.',
  },
  {
    image: Design,
    desc: 'Vi tilbyr prospekter med eksklusivt design i et svært håndterbart format for boligkjøper.'
  },
  {
    long: true,
    image: Video,
    desc: 'Det produseres en elegant video med bilder av leiligheten, som enkelt kan deles på sosiale medier. Undersøkelser viser at video gir opptil 7 ganger større eksponering.'
  },
  {
    long: true,
    image: Hjem,
    desc: 'Gjennom våre smarte digitaliserte verktøy som forbinder meglernes kompetanse med nyvinnende teknologi har vi funnet gode veier til å fnne nettopp kjøperen som kan passe perfekt for deg. Via     oppfølgingssystemet har du full oversikt over alt som har skjedd på fnn, annonsering og hjemmesidene våre.'
  },
  {
    long: true,
    image: Intra,
    desc: 'Hjemmesidene har opptil ﬂere tusen besøk hver eneste dag med potensielle interessenter. Dette sammen med vår programmatiske annonsepakker både i de største mediene i Norge og på sosiale medier, bidrar til at vi når ut til så mange som mulig. Dette kommer i tillegg til standard annonsering og fnn.no.'
  }
];

const Reklame = () => {
  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info">
          <div className="l-grid__section l-grid__section_overflow">
            <ul className="c-list c-list_width760 c-list_width-auto jcsb">
              <AnimatedList items={ listItems } Item={ StyledItem }/>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reklame;
