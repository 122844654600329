import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './Statistikk.scss';
import Map from '../../../components/Map';
import TileItem from '../../../components/TileItem';
import {
  fetchSelectedProperty,
  setPropertyMarkers,
  setDetailsMapCenter,
  setDetailsMapZoom
} from '../../../actions';
import { compose } from '../../../utils';
import { withApiService } from '../../../components/HOC';
import AnimatedList from '../../../components/AnimatedList';
import { CSSTransition } from 'react-transition-group';

const Statistikk = (
  {
    selectedProperty,
    propertyDetails,
    fetchSelectedProperty,
    setPropertyMarkers,
    detailsMapCenter,
    detailsMapZoom,
    setDetailsMapCenter,
    setDetailsMapZoom
  }
) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    setShowMap(true);
  }, []);

  const handleMarkerSelected = (id) => {
    if (id && selectedProperty && id !== selectedProperty.id) {
      const newMarkers = propertyDetails.markers.map(marker => {
        marker.selected = marker.id === id;
        return marker;
      });
      setPropertyMarkers(newMarkers);
      fetchSelectedProperty(id);
    }
  };

  const fields = [
    {
      label: 'Salgssum',
      key: 'salgssum'
    },
    {
      label: 'Prisantydning',
      key: 'prisantydning'
    },
    {
      label: 'Over prisantydning',
      key: 'over_prisantydning'
    },
    {
      label: 'Kvadratmeterpris',
      key: 'kvadratmeterpris'
    },
    {
      label: 'Salgstid',
      key: 'salgstid'
    },
    {
      label: 'Befaringsdato',
      key: 'befaringsdato'
    },
    {
      label: 'Oppdragsdato',
      key: 'oppdragsdato'
    },
    {
      label: 'Markedsføringsdato',
      key: 'markedsforingsdato'
    },
    {
      label: 'Akseptdato',
      key: 'akseptdato'
    }
  ];

  const items = selectedProperty ?
    fields.filter(field => selectedProperty[field.key] && selectedProperty[field.key] !== '0')
      .map(field => {
        return {
          label: field.label,
          param: field.key,
          value: selectedProperty[field.key]
        };
      }) : [];

  const handleResetMap = () => {
    setDetailsMapCenter({ lat: +propertyDetails.lat, lng: +propertyDetails.lng });
    setDetailsMapZoom(13);
  };

  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info">
          <p className="block-adress">
            { selectedProperty ?
              <span>{ selectedProperty.adresse }, { selectedProperty.postnummer } { selectedProperty.poststed }</span>
              : <span>&nbsp;</span> }
          </p>
          <div className="l-grid__section l-grid__section_overflow l-grid__section_indents">
            <ul className="c-tile">
              <AnimatedList items={ items } itemKey="param" Item={ TileItem }/>
            </ul>
          </div>
        </div>
        <div className="l-grid__map">
          <CSSTransition
            in={ showMap }
            timeout={ 800 }
            classNames="map-transition"
            unmountOnExit
          >
            <Map
              center={ detailsMapCenter }
              zoom={ detailsMapZoom }
              onReset={ handleResetMap }
              places={ propertyDetails.markers }
              onMarkerSelected={ handleMarkerSelected }
              onChangeCenter={ (center) => setDetailsMapCenter(center) }
              onChangeZoom={ (zoom) => setDetailsMapZoom(zoom) }
            />
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ selectedProperty, propertyDetails, detailsMapCenter, detailsMapZoom }) => {
  return { selectedProperty, propertyDetails, detailsMapCenter, detailsMapZoom };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchSelectedProperty: fetchSelectedProperty(apiService, dispatch),
    setPropertyMarkers: (newMarkers) => {
      dispatch(setPropertyMarkers(newMarkers));
    },
    setDetailsMapCenter: (center) => {
      dispatch(setDetailsMapCenter(center));
    },
    setDetailsMapZoom: (zoom) => {
      dispatch(setDetailsMapZoom(zoom));
    }
  };
};

export default compose(
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Statistikk);
