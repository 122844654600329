import React, { useEffect, useState } from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CSSTransition } from 'react-transition-group';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

// Import component styles
import './Slider.scss';

// Import slides
import Slide1 from './slides/1.png';
import Slide2 from './slides/2.png';
import Slide3 from './slides/3.png';
import Slide4 from './slides/4.png';
import Slide5 from './slides/5.png';

// install Swiper's Thumbs component
SwiperCore.use([Pagination, Navigation]);

const Slider = () => {
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    setShowGallery(true);
  }, []);

  return (
    <CSSTransition
      in={ showGallery }
      timeout={ 500 }
      classNames="block-transition"
      unmountOnExit
    >
      <div style={ { height: '74%' } }>
        <Swiper
          id="main"
          pagination={ { type: 'fraction' } }
          navigation
          className="swiper-container-main"
          spaceBetween={ 16 }
        >
          <SwiperSlide>
            <div className="swiper-slide-content">
              <img src={ Slide1 } alt={ `Slide 1` }/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide-content">
              <img src={ Slide2 } alt={ `Slide 2` }/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide-content">
              <img src={ Slide3 } alt={ `Slide 3` }/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide-content">
              <img src={ Slide4 } alt={ `Slide 4` }/>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide-content">
              <img src={ Slide5 } alt={ `Slide 5` }/>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </CSSTransition>
  );
};

export default Slider;
