import React, { forwardRef } from 'react';

import './Salgsprosessen.scss';

import Contract from './images/contract.svg';
import Eye from './images/eyeglasses.svg';
import Hand from './images/handshake.svg';
import Key from './images/key.svg';
import Map from './images/map.svg';
import Paper from './images/paper.svg';
import AnimatedList from '../../../components/AnimatedList';
import SlideUpWrapper from '../../../components/AnimatedList/SlideUpWrapper';

const Item = forwardRef(({ className, item }, ref) => {
  return (
    <li ref={ ref } className={ `c-list__item c-list__item_1column c-list__item_indent d-flex ${ className }` }>
      <div className="c-list__icon-box c-list__icon-line">
        <div className="c-list__icon c-list__icon_width">
          <img src={ item.image } alt="img"/>
        </div>
      </div>
      <div className="c-list__content c-list__content_ml80 c-list__content_mt0">
        <h3 className="c-list__title">
          { item.title }
        </h3>
        <ul className="c-list__sublist">
          { item.subItems.map((subItem, idx) => (
            <li className="c-list__subitem c-list__subitem_long" key={ idx }>
              { subItem }
            </li>
          )) }
        </ul>
      </div>
    </li>
  );
});

const StyledItem = SlideUpWrapper(Item);

const listItems = [
  {
    title: 'Befaring',
    image: Map,
    subItems: [
      '- Vi skreddersyr best mulig salgsstrategi for ditt hjem;',
      '- Befaring av ditt hjem og kartlegging av muligheter;',
      '- Megler gir råd/tips om eventuell maling, oppussing etc;',
      '- Befaringen er også grunnlaget for verdivurdering;'
    ]
  },
  {
    title: 'Oppdragsinngåelse & Markedsføring',
    image: Hand,
    subItems: [
      '- Trygg og rettferdig avtale satt opp i tråd med relevant lovverk, samt retningslinjer fra Finanstilsynet og NEF;',
      '- Takstmann og fotograf bestilles;',
      '- Annonserer i ﬂere kanaler og fronter ditt hjem på best mulig måte.'
    ]
  },
  {
    title: 'Visning & Budrunde',
    image: Eye,
    subItems: [
      '- Megler gir deg råd og tips, og følger opp interessenter;',
      '- Megler stiller godt forberedt på selve visningen;',
      '- Megler gjør sitt ypperste for å få med ﬂest mulig interessenter i budrunden;',
      '- For hvert bud varsles du pr. SMS;',
      '- Du kan følge med på budrunden i vår gratis app «MittHjem».'
    ]
  },
  {
    title: 'Kontraktsmøte',
    image: Contract,
    subItems: [
      '- Før møte får du oversendt kontrakt, budlogg etc til gjennomlesning;',
      '- Kjøper og selger blir innkalt til kontraktsmøte;',
      '- Signering/formalisering av avtalen.'
    ]
  },
  {
    title: 'Overtakelse',
    image: Key,
    subItems: [
      '- Kjøper og selger møtes i ditt hjem;',
      '- Kjøper og selger går igjennom hjemmet ditt sammen, leser av strøm, godkjenner utvask m.m.;',
      '- Nøkler overleveres, og overtakelsesprotokollen signeres.'
    ]
  },
  {
    title: 'Oppgjør',
    image: Paper,
    subItems: [
      '- Dyktig og profesjonelt oppgjørskontor;',
      '- Sørger for at alle dokumenter er korrekt utfylt;',
      '- Tinglyser dokumenter og innfrir lån;',
      '- Oppgjør utbetales.'
    ]
  }
];

const Salgsprosessen = () => {
  return (
    <div className="l-grid__inner">
      <div className="l-grid__block">
        <div className="l-grid__info">
          <div className="l-grid__section l-grid__section_overflow">
            <ul className="c-list c-list_width580 c-list_width-auto">
              <AnimatedList items={ listItems } Item={ StyledItem }/>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Salgsprosessen;
