import React, { useEffect } from 'react';
import { Switch, Route, Redirect, Link, useLocation } from 'react-router-dom';

import './Select.scss';
import Sprite from '../../components/App/images/sprites/sprite.svg';
import Tabbar from '../../components/Tabbar';
import Broker from './Broker';
import Kontor from './Kontor';
import Sok from './Sok';
import { connect } from 'react-redux';
import { resetProperties, resetOfficeProperties, resetSearchProperties, resetVisningForm } from '../../actions';

const Select = ({ resetProperties, resetOfficeProperties, resetSearchProperties, resetVisningForm }) => {
  let location = useLocation();

  useEffect(() => {
    return () => {
      resetProperties();
      resetOfficeProperties();
      resetSearchProperties();
      resetVisningForm();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="l-grid">
      <div className="l-grid__content">
        <div className="l-grid__inner l-grid__inner_pb0">
          <div className="l-grid__block">
            <div className="l-grid__info l-grid__info_mwidth">

              <Link to="/">
                <div className="block-back">
                  <svg width="15" height="15">
                    <use href={ Sprite + '#back' }/>
                  </svg>
                  Tilbake
                </div>
              </Link>
              <Tabbar count={ 3 } tabs={
                [
                  { label: 'Megler', path: '/select/broker' + location.search },
                  { label: 'Kontor', path: '/select/kontor' + location.search },
                  { label: 'Søk', path: '/select/sok' + location.search }
                ]
              }/>

              <Switch>
                <Route path="/select/broker" component={ Broker }/>
                <Route path="/select/kontor" component={ Kontor }/>
                <Route path="/select/sok" component={ Sok }/>

                <Redirect from="/select" to="/select/broker"/>
              </Switch>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  resetProperties, resetOfficeProperties, resetSearchProperties, resetVisningForm
};

export default connect(null, mapDispatchToProps)(Select);
