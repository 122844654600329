import React, { Component } from 'react';
import { withApiService } from '../../components/HOC';
import { Link, withRouter } from 'react-router-dom';
import { compose } from '../../utils';
import { connect } from 'react-redux';
import './Login.scss';
import { resetStore, setUser, setUsers } from '../../actions';
import NumPad from '../../components/NumPad';
import SetPinModal from '../../components/SetPinModal';
import Select from 'react-select';
import LogoBig from '../../components/LogoBig';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      username: '',
      password: '',
      error: null,
      showSetPinModal: false
    };
  }

  handleChangeSelect = (selectedOption) => {
    this.setState(
      { selectedOption }
    );
  };

  componentDidMount() {
    const { users } = this.props;
    const usersWithPin = users.filter(user => user.hasPincode);
    if (usersWithPin.length) {
      const selectedUser = usersWithPin[0];
      this.handleChangeSelect({
        value: selectedUser.id,
        label: selectedUser.navn,
        username: selectedUser.username
      });
    }
  }

  handleChangeUsername = (event) => {
    this.setState({ username: event.target.value, error: null });
  };

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value, error: null });
  };

  handleLogin = (data) => {
    const { apiService, history, users, setUser, setUsers, resetStore } = this.props;

    apiService.login(data)
      .then((res) => {
        resetStore();
        setUser(res.user);
        const newUsers = [...users];
        const userIdx = users.findIndex(user => user.id === res.user.id);
        if (userIdx > -1) {
          newUsers.splice(userIdx, 1);
        }
        newUsers.unshift(res.user);
        localStorage.setItem('befaring_users', JSON.stringify(newUsers));
        setUsers(newUsers);
        if (res.user.hasPincode) {
          const redirect = localStorage.getItem('befaring_redirect');
          history.push(redirect ? redirect : '/');
        } else {
          this.setState({ showSetPinModal: true });
        }
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };

  handleSkipModal = () => {
    const redirect = localStorage.getItem('befaring_redirect');
    this.props.history.push(redirect ? redirect : '/');
  };

  handleConfirmModal = () => {
    this.props.history.push('/set-pin');
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.handleLogin({
      username: this.state.username,
      password: this.state.password
    });
  };

  handleNumPadSubmit = (pincode) => {
    const { selectedOption } = this.state;
    if (selectedOption) {
      this.handleLogin({
        username: selectedOption.username,
        pincode: pincode
      });
    }
  };

  handleClearError = () => {
    this.setState({ error: null });
  };

  render() {
    const { users, location } = this.props;
    const { selectedOption } = this.state;
    const query = new URLSearchParams(location.search);
    const usersWithPin = users.filter(user => user.hasPincode);

    if (usersWithPin.length && query.get('type') !== 'email') {
      const selectOptions = usersWithPin.map(user => {
        return {
          value: user.id,
          label: user.navn,
          username: user.username
        };
      });

      return (
        <div className="login-page bg-solid">
          <div style={ { width: '100%' } }>
            <LogoBig/>
            <div style={ { minHeight: '20px', marginBottom: '16px' } }>
              { this.state.error ?
                <p className="text-danger text-center">
                  { this.state.error.message ? this.state.error.message : 'Unknown error' }
                </p>
                : null }
            </div>
            <Select
              className="react-select-container mb-2"
              classNamePrefix="react-select"
              value={ selectedOption }
              onChange={ this.handleChangeSelect }
              options={ selectOptions }
              isSearchable={ false }
              isDisabled={ usersWithPin.length === 1 }
            />
            <NumPad onSubmit={ this.handleNumPadSubmit } onClear={ this.handleClearError }/>
          </div>
          <div className="login-page__footer">
            <Link to="/login?type=email" onClick={ () => this.handleClearError() }>
              Logg inn med brukernavn og passord
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div className="login-page bg-solid">
        { this.state.showSetPinModal ?
          <SetPinModal onSkip={ this.handleSkipModal } onConfirm={ this.handleConfirmModal }/>
          : null }
        <div style={ { width: '100%' } }>
          <LogoBig/>
          <div style={ { minHeight: '20px', marginBottom: '16px' } }>
            { this.state.error ?
              <p className="text-danger text-center">
                { this.state.error.message ? this.state.error.message : 'Unknown error' }
              </p>
              : null }
          </div>
          <form className="login-form" onSubmit={ this.handleSubmit }>
            <input className="form-input" type="text" placeholder="Login" value={ this.state.username }
                   onChange={ this.handleChangeUsername }/>
            <input className="form-input" type="password" placeholder="Passord" value={ this.state.password }
                   onChange={ this.handleChangePassword }/>
            <button className="button button-default" type="submit"
                    disabled={ !this.state.username || !this.state.password }>
              Logg inn
            </button>
            <a
              className="text-center mt-3"
              style={ {
                color: '#909090',
                textDecoration: 'underline'
              } }
              href="https://intra.partners.no/admin/site/forgot-password"
              target="_blank"
              rel="noreferrer"
            >
              Glemt passord?
            </a>
          </form>
        </div>
        <div className="login-page__footer">
          { users.length && users.some(user => user.hasPincode) ?
            <Link to="/login" onClick={ () => this.handleClearError() }>
              Logg inn med PIN-kode
            </Link>
            : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ users }) => {
  return { users };
};

const mapDispatchToProps = {
  setUser, setUsers, resetStore
};

export default compose(
  withRouter,
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
