import React from 'react';
import Sprite from '../App/images/sprites/sprite.svg';
import { Link } from 'react-router-dom';
import './BackLink.scss';

const BackLink = ({ path = '/' }) => {
  return (
    <Link to={ path } className="back-link-absolute">
      <svg width="15" height="15">
        <use href={ Sprite + '#back' }/>
      </svg>
      Tilbake
    </Link>
  );
};

export default BackLink;
