import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withApiService } from '../HOC';
import { fetchProperties, fetchOfficeProperties } from '../../actions';
import { compose } from '../../utils';
import PropertyListTransitioned from '../PropertyListTransitioned';
import ApiError from '../ApiError';

class PropertyList extends Component {
  componentDidMount() {
    const { properties, officeProperties, fetchOfficeProperties, fetchProperties, visning, office } = this.props;
    if (office) {
      if (!officeProperties.list.length) {
        fetchOfficeProperties(visning ? 'visning' : '');
      }
    } else {
      if (!properties.length) {
        fetchProperties(visning ? 'visning' : '');
      }
    }
  }

  render() {
    const { properties, officeProperties, loading, error, visning, office } = this.props;

    if (loading) {
      return (
        <div>Loading...</div>
      );
    }

    if (error) {
      return (
        <ApiError errorData={ error }/>
      );
    }

    return (
      <PropertyListTransitioned
        visning={ visning }
        properties={ office ? officeProperties.list : properties }
        page={ 1 }
        query={ null }
      />
    );
  }
}

const mapStateToProps = ({ properties, officeProperties, loading, error }) => {
  return { properties, officeProperties, loading, error };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { apiService } = ownProps;

  return {
    fetchProperties: fetchProperties(apiService, dispatch),
    fetchOfficeProperties: fetchOfficeProperties(apiService, dispatch)
  };
};

export default compose(
  withRouter,
  withApiService(),
  connect(mapStateToProps, mapDispatchToProps)
)(PropertyList);
