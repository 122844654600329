import React, { useState, useEffect, useRef } from 'react';
import './Preloader.scss';
import { CSSTransition } from 'react-transition-group';
import PartnersPreloader from './PartnersPreloader';
import DefaultPreloader from './DefaultPreloader';

const Preloader = ({ brand }) => {
  const preloaderRef = useRef(null);
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPreloader(false);
    }, 4500);
  });

  return (
    <CSSTransition
      nodeRef={ preloaderRef }
      in={ showPreloader }
      timeout={ 750 }
      classNames="preloader"
      unmountOnExit>
      <div ref={ preloaderRef } className="preloader-backdrop">
        { brand === 'partners' ? <PartnersPreloader/> : <DefaultPreloader/> }
      </div>
    </CSSTransition>
  );
};

export default Preloader;
